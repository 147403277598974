import Home from "../components/HomePage/home";
import ReportsDashboard from "../components/ReportAssignments/reports-dashboard";
import TemplateNote from "../components/NoteWizard/template-note";
import QuestionsCarousel from "../components/NoteWizard/questions-carousel";
import ReviewQuestions from "../components/NoteWizard/review-questions";
import Narrative from "../components/NoteWizard/narrative";
import TemplateManager from "../components/TemplateManager/template-manager-home";
import Error from "../components/Security/error";
import Callback from "../components/Security/callback";
import PageNotFound from "../components/Shared/page-not-found";
import store from "../store/index";

export const routes = [
  {
    name: "Home",
    path: "/",
    redirect: "/reports-dashboard",
    icon: "home",
    title: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "ReportsDashboard",
    path: "/reports-dashboard",
    icon: "edit",
    title: "Reports Dashboard",
    component: ReportsDashboard,
    onHomePage: true,
    onNavBar: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "",
    title: "Template Note",
    component: TemplateNote,
    onNavBar: false,
    props: true,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (!store.state.notesModule.selectedTemplateNote?.templateId)
        next({ name: "ReportsDashboard" });
      else next();
    },
    children: [
      {
        name: "TemplateNote",
        path: "/report-builder/template",
        title: "Template Note",
        component: QuestionsCarousel,
        onNavBar: false,
        meta: {
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.notesModule.selectedTemplateNote?.templateId)
            next({ name: "ReportsDashboard" });
          else next();
        },
      },
      {
        name: "ReviewQuestions",
        path: "/report-builder/review",
        title: "Review Questions",
        component: ReviewQuestions,
        onNavBar: false,
        meta: {
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.notesModule.selectedTemplateNote?.templateId)
            next({ name: "ReportsDashboard" });
          else next();
        },
      },
      {
        name: "Narrative",
        path: "/report-builder/narrative",
        title: "Narratives",
        component: Narrative,
        onNavBar: false,
        meta: {
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.notesModule.selectedTemplateNote?.templateId)
            next({ name: "ReportsDashboard" });
          else next();
        },
      },
    ],
  },
  {
    name: "TemplateManager",
    path: "/template-manager",
    icon: "cog",
    title: "Template Manager",
    component: TemplateManager,
    onHomePage: true,
    onNavBar: true,
    meta: {
      requiresAuth: true,
      admin: true,
    },
  },
  {
    name: "Error",
    path: "/error",
    title: "Error",
    component: Error,
    onNavBar: false,
  },
  {
    name: "Callback",
    path: "/callback",
    title: "Callback",
    component: Callback,
    onNavBar: false,
  },
  {
    name: "PageNotFound",
    path: "*",
    title: "Page Not Found",
    component: PageNotFound,
    onNavBar: false,
  },
];
