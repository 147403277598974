<template>
  <form-field
    :field="formField"
    :readonly="false"
    :validations="validations"
    @fieldChange="fieldChange"
  ></form-field>
</template>

<script>
import Question from "../../objs/Question";
import FormField from "../../objs/Shared/FormField";
import {
  required,
  requiredTrue,
  minLength,
  maxLength,
  minValue,
  maxValue,
  alphaExtra,
  alphaNumExtra,
  numeric,
  email,
  phoneNumber,
  DistinctResponse,
} from "../../plugins/vee-validate/validation";

export default {
  props: ["question"],
  computed: {
    validations: function () {
      if (this.question) {
        // map question validation properties to vee-validate plugin properties
        return {
          ...(this.question.validationRequired && { required: true }),
          ...(this.question.validationRequiredTrue && { requiredTrue: true }),
          ...(this.question.validationMinLength && {
            minLength: this.question.validationMin,
          }),
          ...(this.question.validationMaxLength && {
            maxLength: this.question.validationMax,
          }),
          ...(this.question.validationMinValue && {
            minValue: this.question.validationMin,
          }),
          ...(this.question.validationMaxValue && {
            maxValue: this.question.validationMax,
          }),
          ...(this.question.validationAlpha && { alphaExtra: true }),
          ...(this.question.validationAlphaNumeric && { alphaNumExtra: true }),
          ...(this.question.validationNumeric && { double: true }),
          ...(this.question.validationEmail && { email: true }),
          ...(this.question.validationPhoneNumber && { phoneNumber: true }),
          ...(this.question.validationDistinct && {
            DistinctResponse: true,
          }),
        };
      }

      return {};
    },
    value: function () {
      return this.$store.getters["notesModule/getQuestionResponseValue"](
        this.question.questionId || ""
      );
    },
    formField: function () {
      return new FormField({
        label: this.question.label,
        value: this.value,
        componentName: this.question.componentName,
        options: this.question.options,
        itemText: "option",
        comparatorValue: "questionOptionId",
        outlined: true,
        column: this.question.columnLayout,
      });
    },
    templateNoteId() {
      return this.$store.state.notesModule.selectedTemplateNote?.templateNoteId;
    },
    questionResponse() {
      return this.$store.getters["notesModule/getQuestionResponse"](
        this.question.questionId || ""
      );
    },
  },
  methods: {
    async fieldChange(event) {
      await this.$store.dispatch("notesModule/setQuestionResponse", {
        questionResponseId: this.questionResponse?.questionResponseId,
        templateNoteId: this.templateNoteId,
        questionId: this.question.questionId,
        response: !event.isImage && !Array.isArray(event) ? event : null,
        selections: !event.isImage && Array.isArray(event) ? event : [],
        imageResponse: event.isImage ? event.imageURI : null
      });
    },
  },
};
</script>
