import ReportType from "./ReportType";
import Provider from "./Provider";
import Mandate from "./Mandate";
import Plan from "./Plan";
import Service from "./Service";
import ReportAssignmentNote from "./ReportAssignmentNote";
import File from "./File";
import { formatShortDate } from "../utils/format-utils";
import TemplateNote from "./TemplateNote";

export default class ReportAssignment {
  reportAssignmentId?: number;
  legacyReportAssignmentId?: number | null;
  reportTypeId?: number | null;
  reportType?: ReportType | null;
  mandateId?: number | null;
  mandate?: Mandate | null;
  planId?: number | null;
  plan?: Plan | null;
  serviceId?: number | null;
  service?: Service | null;
  providerId?: number | null;
  provider?: Provider | null;
  dueDate?: Date | null;
  assignedAt?: Date | null;
  submittedAt?: Date | null;
  reviewedAt?: Date | null;
  reviewerId?: number | null;
  reviewer?: Provider | null;
  isApproved?: boolean | null;
  isRejected?: boolean | null;
  reviewComments?: string | null;
  submittedToDOEAt?: Date | null;
  cancelledAt?: Date | null;
  files?: File[] | null;
  notes?: ReportAssignmentNote[] | null;
  status?: string | null;
  flag?: string | null;
  templateNote?: TemplateNote | null;
  lastNotificationSentAt?: Date | null;
  notificationType?: string | null;

  public constructor(params: ReportAssignment = {} as ReportAssignment) {
    let {
      reportAssignmentId = 0,
      legacyReportAssignmentId = null,
      reportTypeId = null,
      reportType = null,
      mandateId = null,
      mandate = null,
      planId = null,
      plan = null,
      serviceId = null,
      service = null,
      providerId = null,
      provider = null,
      dueDate = null,
      assignedAt = null,
      submittedAt = null,
      reviewedAt = null,
      reviewerId = null,
      reviewer = null,
      isApproved = null,
      isRejected = null,
      reviewComments = "",
      submittedToDOEAt = null,
      cancelledAt = null,
      files = [],
      notes = [],
      status = "",
      flag = null,
      templateNote = null,
      lastNotificationSentAt = null,
      notificationType = null,
    } = params;

    this.reportAssignmentId = reportAssignmentId;
    this.legacyReportAssignmentId = legacyReportAssignmentId;
    this.reportTypeId = reportTypeId;
    this.reportType = reportType && new ReportType(reportType);
    this.mandateId = mandateId;
    this.mandate = mandate && new Mandate(mandate);
    this.planId = planId;
    this.plan = plan && new Plan(plan);
    this.serviceId = serviceId;
    this.service = service && new Service(service);
    this.providerId = providerId;
    this.provider = provider && new Provider(provider);
    this.dueDate = dueDate;
    this.assignedAt = assignedAt || new Date();
    this.submittedAt = submittedAt;
    this.reviewedAt = reviewedAt;
    this.reviewerId = reviewerId;
    this.reviewer = reviewer && new Provider(reviewer);
    this.isApproved = isApproved;
    this.isRejected = isRejected;
    this.reviewComments = reviewComments;
    this.submittedToDOEAt = submittedToDOEAt;
    this.cancelledAt = cancelledAt;
    this.files = files && files.map((item) => new File(item));
    this.notes = notes && notes.map((item) => new ReportAssignmentNote(item));
    this.status = status;
    this.flag = flag;
    this.templateNote = templateNote;
    this.lastNotificationSentAt = lastNotificationSentAt;
    this.notificationType = notificationType;
  }

  get formattedDueDate() {
    return formatShortDate(this.dueDate);
  }

  get formattedAssignedAt() {
    return formatShortDate(this.assignedAt);
  }

  get formattedSubmittedAt() {
    return formatShortDate(this.submittedAt);
  }

  get formattedReviewedAt() {
    return formatShortDate(this.reviewedAt);
  }

  get formattedSubmittedToDOEAt() {
    return formatShortDate(this.submittedToDOEAt);
  }

  get reviewText() {
    if (this.isApproved) return "Approved";
    else if (this.isRejected) return "Rejected";
  }

  get formattedCancelledAt() {
    return formatShortDate(this.cancelledAt);
  }

  get formattedLastNotificationSentAt() {
    return formatShortDate(this.lastNotificationSentAt);
  }
}
