<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-on="on" v-bind="attrs" @click="activateDialog">
        <v-icon class="mr-2">mdi-folder-multiple-outline</v-icon>
        Manage Reports
      </v-list-item>
    </template>

    <v-card v-if="dialog">
      <v-card-title>
        Manage Reports
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-toolbar flat color="white">
          <download-zip-button
            :selectedFiles="selected"
            :zipFileName="zipFileName"
          ></download-zip-button>
          <v-btn
            color="primary"
            outlined
            small
            rounded
            :loading="loading"
            :disabled="!isSelected || loading"
            :dark="isSelected && !loading"
            class="mx-3"
            @click="submitReports"
          >
            Submit Quarterly Reports To BOE</v-btn
          >
          <confirm-modal ref="confirm"></confirm-modal>
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="reports"
          sort-by="dueDate"
          sort-desc
          dense
          :key="reports.length"
          item-key="reportAssignmentFileId"
          show-select
          v-model="selected"
          group-by="districtAdminName"
        >
          <template
            v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }"
          >
            <th :colspan="headers.length">
              <v-btn @click="toggle" small icon :ref="group">
                <v-icon v-if="isOpen">mdi-minus</v-icon>
                <v-icon v-else>mdi-plus</v-icon>
              </v-btn>
              <span class="mx-2 text-subtitle-1 font-weight-bold">
                {{ group }}
              </span>
              <span
                v-if="!Boolean(items[0].districtAdminEmailAddress)"
                class="text-subtitle-1 error--text"
                >*</span
              >
            </th>
          </template>

          <template v-slot:[`item.clientDisplay`]="{ item }">
            {{ item.clientDisplay }}
            <span
              v-if="!Boolean(item.clientEmailAddress)"
              class="text-subtitle-1 error--text"
              >*</span
            >
          </template>
          <template v-slot:[`item.reportType`]="{ item }">
            <v-chip color="primary" outlined small label>{{
              item.reportTypeName
            }}</v-chip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-text
        ><span class="font-weight-bold error--text"
          >* Email not on file</span
        ></v-card-text
      >
    </v-card>
  </v-dialog>
</template>
<script>
import DownloadZipButton from "../Shared/download-zip-button.vue";
import ConfirmModal from "../Shared/confirm-modal.vue";
import { formatDateForFilename } from "../../utils/format-utils";
export default {
  components: {
    DownloadZipButton,
    ConfirmModal,
  },
  data() {
    return {
      dialog: false,
      headers: [
        { text: "Client", value: "clientDisplay", align: "left" },
        { text: "District Admin", value: "districtAdminName" },
        { text: "Report Type", value: "reportType" },
        { text: "Due Date", value: "formattedDueDate" },
        { text: "Report Name", value: "fileName" },
      ],
      selected: [],
      loading: false,
      reports: [],
    };
  },
  computed: {
    isSelected() {
      return this.selected.length > 0;
    },
    numSelectedDistrictAdmins() {
      return Object.keys(
        this.selected.reduce((acc, val) => {
          acc[val.districtAdminId] = acc[val.districtAdminId] || [];
          acc[val.districtAdminId].push(val);
          return acc;
        }, {})
      ).length;
    },
    numSelectedClients() {
      return Object.keys(
        this.selected.reduce((acc, val) => {
          acc[val.clientId] = acc[val.clientId] || [];
          acc[val.clientId].push(val);
          return acc;
        }, {})
      ).length;
    },
    zipFileName() {
      return `Reports_${formatDateForFilename(Date.now())}.zip`;
    },
  },
  methods: {
    async activateDialog() {
      this.$store.commit("uxModule/setShowLoader", true);
      this.reports = await this.$store.dispatch(
        "reportsModule/getReportAssignmentFiles"
      );
      this.$store.commit("uxModule/setShowLoader", false);
      this.dialog = true;
    },
    async submitReports() {
      if (
        await this.$refs.confirm.open(
          "Are you sure you want to submit " +
            this.selected.length +
            " reports?",
          "This will send emails to " +
            this.numSelectedDistrictAdmins +
            " district administrators and " +
            this.numSelectedClients +
            " parents.",
          "Submit"
        )
      ) {
        this.loading = true;

        let message = await this.$store.dispatch(
          "reportsModule/submitQuarterlyReports",
          {
            reportAssignmentFiles: this.selected,
          }
        );
        this.loading = false;
        if (message) {
          this.$store.commit("uxModule/setSnackbarMsg", message);
          this.$store.commit("uxModule/setShowSnackbar", true);
        }
      }
      this.dialog = false;
      this.selected = [];
    },
  },
};
</script>
