import { RenderingMethods } from "../objs/RenderingMethods";
import { Comparators } from "../objs/Comparators";
import { FieldTypes } from "../objs/Shared/FieldTypes";
import store from "../store";

export const renderItem = (renderingItem, templateNote) => {
  if (!renderingItem) {
    return true;
  }

  switch (renderingItem.renderingMethod) {
    case RenderingMethods.UNCONDITIONAL:
      return true;
    case RenderingMethods.CONDITIONAL_PREVIOUS_QUESTION:
      // OR = .some
      if (renderingItem.conditionalOperator === "OR")
        return renderingItem.renderingConfigs.some((config) => {
          return renderItemConditionalPreviousQuestion(config);
        });
      // AND = .every
      else if (renderingItem.conditionalOperator === "AND")
        return renderingItem.renderingConfigs.every((config) => {
          return renderItemConditionalPreviousQuestion(config);
        });
      // null = first config
      else {
        return renderItemConditionalPreviousQuestion(
          renderingItem.renderingConfigs[0]
        );
      }
    case RenderingMethods.CONDITIONAL_PREDEFINED_PROPERTY:
      // OR = .some
      if (renderingItem.conditionalOperator === "OR")
        return renderingItem.renderingConfigs.some((config) => {
          return renderItemConditionalPredefinedProperty(config, templateNote);
        });
      // AND = .every
      else if (renderingItem.conditionalOperator === "AND") {
        return renderingItem.renderingConfigs.every((config) => {
          return renderItemConditionalPredefinedProperty(config, templateNote);
        });
      }
      // null = first config
      else {
        return renderItemConditionalPredefinedProperty(
          renderingItem.renderingConfigs[0],
          templateNote
        );
      }
    default:
      return true;
  }
};

export const renderItemConditionalPreviousQuestion = (renderingItem) => {
  let parentQuestion = store.getters["notesModule/getQuestion"](
    renderingItem.parentQuestionId
  );
  let parentResponse =
    parentQuestion?.isRendered &&
    store.getters["notesModule/getQuestionResponseValue"](
      renderingItem.parentQuestionId
    );

  let comparator = renderingItem.comparator;
  let componentName = parentQuestion.componentName;

  if (!parentResponse) {
    return Comparators.IS_EMPTY == comparator;
  }

  let response = parentResponse;
  let renderVal = renderingItem.renderValue;
  let renderValMin = renderingItem.renderValueMin;
  let renderValMax = renderingItem.renderValueMax;

  // change format for date/boolean/string comparisons
  if (componentName === FieldTypes.DATE_PICKER) {
    response = response && new Date(response).toISOString().substring(0, 10);
    renderVal = renderVal && new Date(renderVal).toISOString().substring(0, 10);
    renderValMin =
      renderValMin && new Date(renderValMin).toISOString().substring(0, 10);
    renderValMax =
      renderValMax && new Date(renderValMax).toISOString().substring(0, 10);
  } else if (componentName === FieldTypes.CHECKBOX) {
    response = Boolean(response);
    renderVal = Boolean(renderVal);
  } else if (
    componentName === FieldTypes.TEXT_FIELD ||
    componentName === FieldTypes.TEXT_AREA
  ) {
    response = response.toUpperCase();
    renderVal = renderVal && renderVal.toUpperCase();
  }

  // function to handle object comparison for mandate options vs. question options
  const compareOptions = (res, option) => {
    return (
      res.questionOptionId === option.questionOptionId ||
      res.option.toUpperCase() == option.option.toUpperCase()
    );
  };

  switch (comparator) {
    case Comparators.EQUALS:
      return response == renderVal;
    case Comparators.GREATER:
      return response > renderVal;
    case Comparators.GREATER_OR_EQUAL:
      return response >= renderVal;
    case Comparators.LESS:
      return response < renderVal;
    case Comparators.LESS_OR_EQUAL:
      return response <= renderVal;
    case Comparators.BETWEEN:
      return response >= renderValMin && response <= renderValMax;
    case Comparators.CONTAINS_ANY:
      return (
        response.filter((res) =>
          renderingItem.renderValueOptions.some((option) =>
            compareOptions(res, option)
          )
        ).length > 0
      );
    case Comparators.CONTAINS_ALL:
      return renderingItem.renderValueOptions.every((option) =>
        response.some((res) => compareOptions(res, option))
      );
    case Comparators.MATCHES_ALL_ONLY:
      return (
        renderingItem.renderValueOptions.every((option) =>
          response.some((res) => compareOptions(res, option))
        ) &&
        response.every((res) =>
          renderingItem.renderValueOptions.some((option) =>
            compareOptions(res, option)
          )
        )
      );
    case Comparators.IS_EMPTY:
      return response == null || response == "" || response.length == 0;
    case Comparators.IS_NOT_EMPTY:
      return response != null || response != "" || response.length > 0;
    default:
      return true;
  }
};

export const renderItemConditionalPredefinedProperty = (
  renderingItem,
  templateNote
) => {
  // TODO: fix predefined property to integrate with backend (predefined properties are now stored in db not frontend code)
  let predefinedProperty = null;

  if (renderingItem.predefinedProperty.name.toUpperCase() === "AGE") {
    predefinedProperty = templateNote?.client.age;
  } else if (renderingItem.predefinedProperty.name.toUpperCase() === "GENDER") {
    predefinedProperty = templateNote?.client.gender.toUpperCase();
  } else if (
    renderingItem.predefinedProperty.name.toUpperCase() === "REPORTTYPE"
  ) {
    predefinedProperty = templateNote?.reportAssignment?.reportType?.name;
  } else {
    return false;
  }

  let comparator = renderingItem.comparator;

  // handle comparison for predefined property options
  const compareOptions = (prop, option) =>
    prop.toUpperCase() == option.option.toUpperCase();

  switch (comparator) {
    case Comparators.EQUALS:
      return predefinedProperty == renderingItem.renderValue;
    case Comparators.GREATER:
      return predefinedProperty > renderingItem.renderValue;
    case Comparators.GREATER_OR_EQUAL:
      return predefinedProperty >= renderingItem.renderValue;
    case Comparators.LESS:
      return predefinedProperty < renderingItem.renderValue;
    case Comparators.LESS_OR_EQUAL:
      return predefinedProperty <= renderingItem.renderValue;
    case Comparators.BETWEEN:
      return (
        predefinedProperty > renderingItem.renderValueMin &&
        predefinedProperty < renderingItem.renderValueMax
      );
    case Comparators.CONTAINS_ANY:
      return renderingItem.renderValueOptions.some((option) =>
        compareOptions(predefinedProperty, option)
      );
    case Comparators.CONTAINS_ALL:
      return renderingItem.renderValueOptions.every((option) =>
        compareOptions(predefinedProperty, option)
      );
    case Comparators.MATCHES_ALL_ONLY:
      return (
        renderingItem.renderValueOptions.every((option) =>
          compareOptions(predefinedProperty, option)
        ) &&
        renderingItem.renderValueOptions.some(
          (option) => !compareOptions(predefinedProperty, option)
        )
      );
    case Comparators.IS_EMPTY:
      return response == null || response == "" || response.length == 0;
    case Comparators.IS_NOT_EMPTY:
      return response != null || response != "" || response.length > 0;
    default:
      return true;
  }
};
