<template>
  <validation-provider :rules="validations" v-slot="{ errors }">
    <v-checkbox
      :value="val"
      :disabled="readonly"
      @change="onChange"
      :error-messages="errors"
      color="#005c7b"
    >
      <template v-slot:label> <label v-sanitize-html="field.label"></label> </template
    ></v-checkbox>
  </validation-provider>
</template>

<script>
export default {
  props: ["field", "value", "readonly", "validations"],
  computed: {
    val: {
      get: function () {
        return Boolean(this.value);
      },
      set: function (newValue) {},
    },
  },
  methods: {
    onChange(event) {
      this.$emit("fieldChange", event);
    },
  },
};
</script>
