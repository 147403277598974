<template>
  <v-data-table
    :headers="headers"
    :items="reportAssignments"
    sort-by="dueDate"
    dense
    hide-default-footer
    :key="reportAssignments.length"
  >
    <template v-slot:[`item.reportType`]="{ item }">
      <v-chip color="primary" outlined small label>{{
        item.reportTypeName
      }}</v-chip>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <status-chip :reportAssignment="item"></status-chip>
    </template>
  </v-data-table>
</template>
<script>
import StatusChip from "./status-chip.vue";
export default {
  props: ["reportAssignments"],
  components: {
    StatusChip,
  },
  data() {
    return {
      headers: [
        { text: "Provider", value: "providerName" },
        { text: "Report Type", value: "reportType" },
        { text: "Due Date", value: "formattedDueDate" },
        { text: "Status", value: "status" },
      ],
    };
  },
};
</script>
