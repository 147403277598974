<template>
  <v-tooltip bottom v-if="Boolean(title)">
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <v-btn
          text
          x-small
          v-on="on"
          @click="goToNoteWizard"
          :disabled="reportAssignment.isStartReportDisabled"
        >
          <v-icon small>mdi-play</v-icon>
        </v-btn>
      </span>
    </template>
    <span> {{ title }} </span>
  </v-tooltip>
</template>
<script>
import { ReportAssignmentStatus } from "../../objs/ReportAssignmentStatus";
export default {
  props: ["reportAssignment", "isAdmin"],
  computed: {
    title() {
      if (this.reportAssignment.isStartReportDisabled) {
        return "Report is not yet available to start";
      } else if (
        !this.reportAssignment?.isTemplateNoteStarted &&
        !this.reportAssignment?.isCompleted
      ) {
        return "Start Report";
      } else if (this.reportAssignment?.isTemplateNoteInProgress) {
        return "Continue Report";
      } else if (
        this.isAdmin &&
        this.reportAssignment?.isTemplateNoteReviewable
      ) {
        return "Review Report";
      } else {
        return false;
      }
    },
    templateNote() {
      return this.$store.state.notesModule.selectedTemplateNote;
    },
  },
  methods: {
    async goToNoteWizard() {
      if (!this.reportAssignment?.isTemplateNoteStarted) {
        await this.createNote();
      } else {
        await this.loadNote();
      }
    },
    async createNote() {
      this.$store.commit("uxModule/setShowLoader", true);
      let message = await this.$store.dispatch(
        "notesModule/createTemplateNote",
        {
          reportAssignmentId: this.reportAssignment?.reportAssignmentId,
        }
      );
      this.$store.commit("uxModule/setShowLoader", false);

      if (message) {
        this.$store.commit("uxModule/setSnackbarMsg", message);
        this.$store.commit("uxModule/setShowSnackbar", true);
      } else {
        this.$router.push({ name: "TemplateNote" });
      }
    },
    async loadNote() {
      this.$store.commit("uxModule/setShowLoader", true);
      await this.$store.dispatch("notesModule/loadTemplateNote", {
        templateNoteId: this.reportAssignment?.templateNoteId,
      });
      this.$store.commit("uxModule/setShowLoader", false);

      if (this.templateNote.status === ReportAssignmentStatus.IN_PROGRESS) {
        this.$router.push({ name: "TemplateNote" });
      } else {
        this.$router.push({ name: "Narrative" });
      }
    },
  },
};
</script>
