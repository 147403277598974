var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = true}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-signature")]),_vm._v(" Sign ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{attrs:{"color":"primary"}},[_vm._v(" Please Sign Here "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"text-subtitle-1 pb-0"},[_c('VueSignaturePad',{ref:"signaturePad",attrs:{"options":{
          onBegin: () => {
            _vm.$refs.signaturePad.resizeCanvas();
          },
          onEnd: () => {
            _vm.disabled = _vm.$refs.signaturePad.isEmpty();
          }
        },"id":"signature","width":"100%","height":"150px"}})],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.undo}},[_c('v-icon',[_vm._v("mdi-undo")]),_vm._v("Undo")],1),_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.clear}},[_c('v-icon',[_vm._v("mdi-eraser")]),_vm._v("Clear")],1),_c('v-btn',{attrs:{"outlined":"","color":"primary","disabled":_vm.disabled},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-signature")]),_vm._v("Sign")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }