import { formatShortDate } from "@/utils/format-utils";
import Client from "./Client";
import Provider from "./Provider";

export default class Assignment {
  assignmentId?: number;
  clientId?: number | null;
  client?: Client | null;
  programId?: number | null;
  program?: string | null;
  serviceId?: number | null;
  service?: string | null;
  locationId?: number | null;
  location?: string | null;
  groupSize?: number | null;
  frequency?: number | null;
  durationMinutes?: number | null;
  startDate?: Date | null;
  endDate?: Date | null;
  providerId?: number | null;
  provider?: Provider | null;

  public constructor(params: Assignment = {} as Assignment) {
    let {
      assignmentId = 0,
      clientId = null,
      client = null,
      programId = null,
      program = "",
      serviceId = null,
      service = "",
      locationId = null,
      location = "",
      groupSize = 1,
      frequency = 0,
      durationMinutes = 0,
      startDate = null,
      endDate = null,
      providerId = null,
      provider = null,
    } = params;
    this.assignmentId = assignmentId;
    this.clientId = clientId;
    this.client = client && new Client(client);
    this.programId = programId;
    this.program = program;
    this.serviceId = serviceId;
    this.service = service;
    this.locationId = locationId;
    this.location = location;
    this.groupSize = groupSize;
    this.frequency = frequency;
    this.durationMinutes = durationMinutes;
    this.startDate = startDate;
    this.endDate = endDate;
    this.providerId = providerId;
    this.provider = provider && new Provider(provider);
  }

  get providerName() {
    return this.provider?.name;
  }

  get mandate() {
    return `${this.service} ${this.frequency}x${this.durationMinutes}:${this.groupSize}`;
  }

  get formattedStartDate() {
    return formatShortDate(this.startDate);
  }

  get formattedEndDate() {
    return formatShortDate(this.endDate);
  }
}
