<template>
  <v-dialog v-model="dialog" persistent width="70vw">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="newQuestion"
        color="primary"
        outlined
        rounded
        small
        v-on="on"
        @click="dialog = true"
        ><v-icon>mdi-plus</v-icon>New Question</v-btn
      >
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            v-if="!newQuestion"
            small
            class="mr-2"
            v-on="on"
            @click="dialog = true"
            >mdi-pencil</v-icon
          >
        </template>
        <span>Edit Question Details</span>
      </v-tooltip>
    </template>

    <v-card v-if="dialog">
      <validation-observer ref="form" v-slot="{ invalid }">
        <v-card-title> Question Details </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-6">
          <v-text-field
            v-model="family.label"
            label="Family Title"
            dense
            outlined
            readonly
          ></v-text-field>
          <span class="subheading">Question Text</span>
          <div class="pb-4">
            <form-field
              :field="simpleTextEditor"
              :validations="validations.questionText"
              @fieldChange="questionText = $event"
              class="pa-3"
            ></form-field>
          </div>

          <span class="subheading">Component Type</span>
          <component-name-field
            :question="question"
            @componentChange="onComponentChange"
          >
          </component-name-field>

          <child-questions-field
            v-if="isWordBank"
            :groupQuestion="question"
            @childQuestionsChange="onChildQuestionsChange"
          ></child-questions-field>

          <question-options-field
            v-if="showOptionsField"
            :question="question"
            @optionsChange="onOptionsChange"
          ></question-options-field>
          <v-divider role="presentation" class="mb-4"></v-divider>

          <span class="subheading">Validation Rules</span>
          <question-validations-field
            :question="question"
            @validationChange="onValidationChange"
          ></question-validations-field>
          <v-divider role="presentation" class="mb-4"></v-divider>

          <span class="subheading">Rendering Configuration</span>
          <conditional-rendering-field
            :renderingItem="rendering"
            @renderingChange="onRenderingChange"
          ></conditional-rendering-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="clear"> Cancel </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="invalid || noOptions"
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from "../../plugins/vee-validate/validation";
import ComponentNameField from "./component-name-field.vue";
import QuestionValidationsField from "./question-validations-field.vue";
import ConditionalRenderingField from "./conditional-rendering-field.vue";
import ChildQuestionsField from "./child-questions-field.vue";
import QuestionOptionsField from "./question-options-field.vue";
import Question from "../../objs/Question";
import Rendering from "../../objs/Rendering";
import { FieldTypes } from "../../objs/Shared/FieldTypes.js";
import FormField from "../../objs/Shared/FormField";
export default {
  components: {
    ComponentNameField,
    QuestionValidationsField,
    ConditionalRenderingField,
    ChildQuestionsField,
    QuestionOptionsField,
  },
  props: ["newQuestion", "question", "familyId"],
  data() {
    return {
      dialog: false,
      questionText: this.question?.label || "",
      childQuestions: this.question?.childQuestions || [],
      component: this.question?.component || {},
      columnLayout: this.question?.columnLayout,
      options: this.question?.options || [],
      selectedValidations: this.question
        ? Question.mapValidations(
            this.question.validations,
            this.question.validationMin,
            this.question.validationMax
          )
        : {},
      rendering: this.question
        ? {
            ...this.question?.rendering,
            renderingConfigs: [...this.question?.rendering.renderingConfigs],
          }
        : new Rendering(),
    };
  },
  computed: {
    sequence: function () {
      return this.question?.sequence || this.questions.length + 1;
    },
    questions: function () {
      return this.$store.getters["templatesModule/getQuestionsByFamily"](
        this.familyId
      ).filter((x) => !Boolean(x.groupQuestionId));
    },
    family: function () {
      return this.$store.getters["templatesModule/getFamily"](this.familyId);
    },
    isWordBank: function () {
      return this.component?.jsName == FieldTypes.WORD_BANK;
    },
    showOptionsField: function () {
      return [
        FieldTypes.RADIO_GROUP,
        FieldTypes.STANDARD_SELECT,
        FieldTypes.MULTIPLE_SELECT,
        FieldTypes.CHIP_SELECT,
        FieldTypes.MULTIPLE_CHIP_SELECT,
        FieldTypes.AUTOCOMPLETE,
        FieldTypes.WORD_BANK,
      ].includes(this.component?.jsName);
    },
    simpleTextEditor: function () {
      return new FormField({
        value: this.questionText,
        componentName: "simple-text-editor",
      });
    },
    validations: function () {
      return {
        questionText: { required: true },
      };
    },
    noOptions: function () {
      return this.showOptionsField && this.options.length === 0;
    },
  },
  methods: {
    childSimpleTextEditor: function (questionId) {
      return new FormField({
        value: this.childQuestions[questionId],
        componentName: "simple-text-editor",
      });
    },
    onChildQuestionsChange: function (value) {
      this.childQuestions = value;
    },
    onComponentChange: function (value) {
      this.component = value.component;
      this.columnLayout = value.columnLayout;
    },
    onOptionsChange: function (value) {
      this.options = value;
    },
    onValidationChange: function (selection) {
      this.selectedValidations = selection;
    },
    onRenderingChange: function (selection) {
      this.rendering = selection;
    },
    save: async function () {
      this.$store.commit("uxModule/setShowLoader", true);
      let success = false;
      if (this.newQuestion) {
        success = await this.$store.dispatch("templatesModule/addQuestion", {
          familyId: this.familyId,
          sequence: this.sequence,
          label: this.questionText,
          childQuestions: this.childQuestions,
          componentId: this.component?.componentId,
          component: this.component,
          columnLayout: this.columnLayout,
          ...(this.showOptionsField && { options: this.options }),
          ...this.selectedValidations,
          rendering: this.rendering,
        });
      } else {
        success = await this.$store.dispatch("templatesModule/updateQuestion", {
          questionId: this.question.questionId,
          sequence: this.sequence,
          familyId: this.familyId,
          label: this.questionText,
          childQuestions: this.childQuestions,
          componentId: this.component?.componentId,
          component: this.component,
          columnLayout: this.columnLayout,
          ...(this.showOptionsField && { options: this.options }),
          ...this.selectedValidations,
          rendering: this.rendering,
        });
      }
      this.$store.commit("uxModule/setShowLoader", false);

      if (!success) {
        this.$store.commit("uxModule/setSnackbarMsg", "Error saving question");
        this.$store.commit("uxModule/setShowSnackbar", true);
      }

      this.clear();
    },
    clear: function () {
      this.$refs.form.reset();
      this.dialog = false;
      this.questionText = this.question?.label || "";
      this.childQuestions = this.question?.childQuestions || [];
      this.component = this.question?.component || {};
      this.columnLayout = this.question?.columnLayout || false;
      this.selectedValidations = this.question
        ? Question.mapValidations(
            this.question.validations,
            this.question.validationMin,
            this.question.validationMax
          )
        : {};
      this.options = this.question?.options || [];
      this.rendering = this.question
        ? {
            ...this.question?.rendering,
            renderingConfigs: [...this.question?.rendering.renderingConfigs],
          }
        : new Rendering();
    },
  },
};
</script>
