<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn color="primary" icon v-on="on" @click="goToDashboard"
        ><v-icon>mdi-home-outline</v-icon></v-btn
      ></template
    >
    <span>Dashboard</span>
  </v-tooltip>
</template>
<script>
export default {
  methods: {
    goToDashboard: function () {
      this.$router.push({ name: "ReportsDashboard" });
    },
  },
};
</script>
