<template>
  <v-data-table
    :headers="headers"
    :items="sortedFamilies"
    show-expand
    single-expand
    :expanded.sync="expanded"
    v-sortable-data-table
    @sorted="saveOrder"
    item-key="familyId"
    :item-class="highlightExpandedRow"
    :items-per-page="-1"
    hide-default-footer
    class="elevation-1 primary--text"
    @click:row="rowClicked"
    :key="families.length"
  >
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="expandedRow">
        <questions-table :familyId="item.familyId"></questions-table>
        <v-card-actions class="justify-center white mb-3 py-3">
          <question-modal
            :newQuestion="true"
            :familyId="item.familyId"
          ></question-modal>
        </v-card-actions>
      </td>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <family-modal :family="item"></family-modal>
      <v-icon small @click="deleteFamily(item)">mdi-delete</v-icon>
      <confirm-modal ref="confirm"></confirm-modal>
      <clone-family-action :family="item"></clone-family-action>
    </template>
  </v-data-table>
</template>
<script>
import QuestionsTable from "./questions-table.vue";
import FamilyModal from "./family-modal.vue";
import QuestionModal from "./question-modal.vue";
import ConfirmModal from "../Shared/confirm-modal.vue";
import CloneFamilyAction from "./clone-family-action.vue";
import Sortable from "sortablejs";
export default {
  components: {
    QuestionsTable,
    FamilyModal,
    QuestionModal,
    ConfirmModal,
    CloneFamilyAction,
  },
  data() {
    return {
      headers: [
        { text: "Sequence", value: "sequence" },
        { text: "Family Title", value: "label" },
        { text: "Actions", value: "actions", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
      expanded: [],
    };
  },
  computed: {
    families: function () {
      return this.$store.state.templatesModule.families;
    },
    sortedFamilies: function () {
      return this.families.sort((a, b) => a.sequence - b.sequence);
    },
  },
  methods: {
    rowClicked: function (row, { isExpanded }) {
      this.expanded = isExpanded ? [] : [row];
    },
    highlightExpandedRow: function (row) {
      return this.expanded[0]?.familyId === row.familyId && "expandedRow";
    },
    deleteFamily: async function (item) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete this family?"
        )
      ) {
        this.$store.commit("uxModule/setShowLoader", true);
        await this.$store.dispatch("templatesModule/deleteFamily", {
          familyId: item.familyId,
        });
        this.$store.commit("uxModule/setShowLoader", false);
      }
    },
    async saveOrder(event) {
      this.$store.commit("uxModule/setShowLoader", true);
      const movedItem = this.families.splice(event.oldIndex, 1)[0];
      this.families.splice(event.newIndex, 0, movedItem);
      await this.$store.dispatch("templatesModule/saveFamilies", {
        families: this.families.map((item, index) => {
          return { ...item, sequence: index + 1 };
        }),
      });
      this.$store.commit("uxModule/setShowLoader", false);
    },
  },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit("sorted", event);
          },
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      },
    },
  },
};
</script>
<style>
.expandedRow {
  background: #ccc;
}
</style>
