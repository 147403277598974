<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <v-btn text x-small v-on="on" target="_blank" :href="messagingUrl">
          <v-icon small>mdi-email</v-icon>
        </v-btn>
      </span>
    </template>
    <span> Go to Secure Messaging </span>
  </v-tooltip>
</template>
<script>
export default {
  messagingUrl: function () {
    return process.env.VUE_APP_MESSAGING;
  },
};
</script>
