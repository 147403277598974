import store from "../store";
import PredefinedProperty from "./PredefinedProperty";
import QuestionOption from "./QuestionOption";

export default class RenderingConfig {
  renderingConfigId: number;
  renderingId: number;
  comparator?: string | null;
  renderValue?: string | null;
  renderValueMin?: string | null;
  renderValueMax?: string | null;
  parentQuestionId?: number | null;
  predefinedPropertyId?: number | null;
  predefinedProperty?: PredefinedProperty | null;
  renderValueOptions?: any[];

  public constructor(params: RenderingConfig = {} as RenderingConfig) {
    let {
      renderingConfigId = 0,
      renderingId = 0,
      comparator = null,
      renderValue = null,
      renderValueMin = null,
      renderValueMax = null,
      parentQuestionId = null,
      predefinedPropertyId = null,
      predefinedProperty = null,
      renderValueOptions = [],
    } = params;

    this.renderingConfigId = renderingConfigId;
    this.renderingId = renderingId;
    this.comparator = comparator;
    this.renderValue = renderValue;
    this.renderValueMin = renderValueMin;
    this.renderValueMax = renderValueMax;
    this.parentQuestionId = parentQuestionId;
    this.predefinedPropertyId = predefinedPropertyId;
    this.predefinedProperty =
      predefinedProperty && new PredefinedProperty(predefinedProperty);
    this.renderValueOptions =
      renderValueOptions &&
      renderValueOptions.map((o) => new QuestionOption(o));
  }

  get parentQuestion() {
    return store.getters["templatesModule/getQuestion"](this.parentQuestionId);
  }

  get parentFamily() {
    let parentFamilyId = this.parentQuestion?.familyId;
    return store.getters["templatesModule/getFamily"](parentFamilyId);
  }
}
