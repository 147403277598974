<template>
  <v-data-table
    :headers="headers"
    :items="sortedNarrativeSections"
    show-expand
    single-expand
    :expanded.sync="expanded"
    v-sortable-data-table
    @sorted="saveOrder"
    item-key="narrativeSectionId"
    :item-class="highlightExpandedRow"
    :items-per-page="-1"
    hide-default-footer
    class="elevation-1 primary--text"
    @click:row="rowClicked"
    :key="narrativeSections.length"
  >
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="expandedRow">
        <narrative-statements-table
          :narrativeSectionId="item.narrativeSectionId"
        ></narrative-statements-table>
        <v-card-actions class="justify-center white mb-3 py-3">
          <narrative-statement-modal
            :isNewNarrativeStatement="true"
            :narrativeSectionId="item.narrativeSectionId"
          ></narrative-statement-modal>
        </v-card-actions>
      </td>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <narrative-section-modal
        :narrativeSection="item"
        :aiNarrativeId="item.aiNarrativeId"
      ></narrative-section-modal>
      <v-icon small @click="deleteNarrativeSection(item)">mdi-delete</v-icon>
      <confirm-modal ref="confirm"></confirm-modal>
    </template>
  </v-data-table>
</template>
<script>
import NarrativeStatementsTable from "./narrative-statements-table.vue";
import NarrativeSectionModal from "./narrative-section-modal.vue";
import NarrativeStatementModal from "./narrative-statement-modal.vue";
import ConfirmModal from "../Shared/confirm-modal.vue";
import Sortable from "sortablejs";
export default {
  components: {
    NarrativeStatementsTable,
    NarrativeSectionModal,
    NarrativeStatementModal,
    ConfirmModal,
  },
  data() {
    return {
      headers: [
        { text: "Sequence", value: "sequence" },
        { text: "Title", value: "name" },
        { text: "Actions", value: "actions", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
      expanded: [],
    };
  },
  computed: {
    narrativeSections: function () {
      return this.$store.state.templatesModule.narrativeSections;
    },
    sortedNarrativeSections: function () {
      return this.narrativeSections.sort((a, b) => a.sequence - b.sequence);
    },
  },
  methods: {
    rowClicked: function (row, { isExpanded }) {
      this.expanded = isExpanded ? [] : [row];
    },
    highlightExpandedRow: function (row) {
      return (
        this.expanded[0]?.narrativeSectionId === row.narrativeSectionId &&
        "expandedRow"
      );
    },
    deleteNarrativeSection: async function (item) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete this narrative section?"
        )
      ) {
        this.$store.commit("uxModule/setShowLoader", true);
        await this.$store.dispatch("templatesModule/deleteNarrativeSection", {
          narrativeSectionId: item.narrativeSectionId,
        });
        this.$store.commit("uxModule/setShowLoader", false);
      }
    },
    async saveOrder(event) {
      this.$store.commit("uxModule/setShowLoader", true);
      const movedItem = this.narrativeSections.splice(event.oldIndex, 1)[0];
      this.narrativeSections.splice(event.newIndex, 0, movedItem);
      await this.$store.dispatch("templatesModule/saveNarrativeSections", {
        narrativeSections: this.narrativeSections.map((item, index) => {
          return { ...item, sequence: index + 1 };
        }),
      });
      this.$store.commit("uxModule/setShowLoader", false);
    },
  },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit("sorted", event);
          },
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      },
    },
  },
};
</script>
<style>
.expandedRow {
  background: #ccc;
}
</style>
