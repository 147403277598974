<template>
  <v-data-table
    :headers="headers"
    :items="sortedNotes"
    no-data-text=""
    hide-default-footer
    disable-pagination
  >
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn v-if="canDelete(item)" x-small text @click="deleteNote(item)">
        <v-icon small>mdi-delete</v-icon></v-btn
      >
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ["notes"],
  mounted() {
    // update the current time every 60 seconds for the delete button timer
    this.interval = window.setInterval(
      (() => {
        return () => {
          this.currentTime = new Date();
        };
      })(),
      60000
    );
  },
  destroyed() {
    window.clearInterval(this.interval);
  },
  data() {
    return {
      headers: [
        { text: "Note", value: "noteText" },
        { text: "Created On", value: "formattedDate", width: "5%" },
        { text: "Created By", value: "name", width: "5%" },
        { text: "Actions", value: "actions", width: "1%" },
      ],
      interval: null,
      currentTime: new Date(),
    };
  },
  computed: {
    sortedNotes() {
      return this.notes.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    },
  },
  methods: {
    canDelete(note) {
      // only show delete button if within 60 minutes of createdAt
      return (
        (this.currentTime - new Date(note.createdAt).getTime()) / 60000 <= 60
      );
    },
    async deleteNote(item) {
      this.$store.commit("uxModule/setShowLoader", true);
      let message = await this.$store.dispatch("reportsModule/deleteNote", {
        noteId: item.reportAssignmentNoteId,
      });
      this.$store.commit("uxModule/setShowLoader", false);
      if (message) {
        this.$store.commit("uxModule/setSnackbarMsg", message);
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
    },
  },
};
</script>
<style>
table {
  border-collapse: collapse;
}
</style>
