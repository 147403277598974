<template>
  <v-data-table
    :headers="headers"
    :items="mandates"
    dense
    hide-default-footer
    multi-sort
    :sort-by="['serviceName', 'iepPlanDate']"
    :sort-desc="[false, true]"
  >
  </v-data-table>
</template>
<script>
export default {
  props: ["mandates"],
  data() {
    return {
      headers: [
        { text: "Mandate", value: "display" },
        { text: "IEP Plan Date", value: "formattedIEPPlanDate" },
        { text: "Auth End Date", value: "formattedAuthEndDate" },
      ],
    };
  },
};
</script>
