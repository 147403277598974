<template>
  <validation-provider :rules="validations" v-slot="{ errors }">
    <label v-sanitize-html="field.label"></label>
    <v-select
      :value="value"
      :disabled="readonly"
      :items="field.options"
      :item-text="field.itemText"
      return-object
      multiple
      chips
      deletable-chips
      @change="onChange"
      :error-messages="errors"
      color="#005c7b"
      background-color="#FFFFFF"
      class="mt-0"
      :outlined="outlined"
    >
      <template v-slot:item="{ item, on, attrs }">
        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
          <v-list-item-action>
            <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-sanitize-html="item[field.itemText]">
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:selection="{ item, attrs, select, selected }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          close
          @click="select"
          @click:close="remove(item)"
        >
          <span v-sanitize-html="item[field.itemText]"></span>
        </v-chip>
      </template>
    </v-select>
  </validation-provider>
</template>

<script>
export default {
  props: ["field", "value", "readonly", "outlined", "validations"],
  data() {
    return {
      debounce: "",
    };
  },
  methods: {
    remove(item) {
      this.value.splice(this.value.indexOf(item), 1);
      this.onChange(this.value);
    },
    onChange: function (event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        this.$emit("fieldChange", event);
      }, 300);
    },
  },
};
</script>
