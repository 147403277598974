<template>
  <v-data-table
    :headers="headers"
    :items="sortedNarrativeStatements"
    v-sortable-data-table
    @sorted="saveOrder"
    item-key="narrativeStatementId"
    :items-per-page="-1"
    hide-default-footer
    class="elevation-1 primary--text"
    :key="narrativeStatements.length"
  >
    <template v-slot:[`item.value`]="{ item }">
      <span v-if="item.type == 'Content'"> {{ item.content }}</span>
      <span
        v-if="item.type == 'Question'"
        v-sanitize-html="item.question.label"
      ></span>
      <span v-if="item.type == 'Stub'">{{
        item.predefinedNarrativeStub.representation
      }}</span>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <narrative-statement-modal
        :narrativeStatement="item"
        :narrativeSectionId="item.narrativeSectionId"
      ></narrative-statement-modal>
      <v-icon small @click="deleteNarrativeStatement(item)">mdi-delete</v-icon>
      <confirm-modal ref="confirm"></confirm-modal>
    </template>
  </v-data-table>
</template>
<script>
import NarrativeStatementModal from "./narrative-statement-modal.vue";
import ConfirmModal from "../Shared/confirm-modal.vue";
import Sortable from "sortablejs";
export default {
  props: ["narrativeSectionId"],
  components: {
    NarrativeStatementModal,
    ConfirmModal,
  },
  data() {
    return {
      headers: [
        { text: "Sequence", value: "sequence" },
        { text: "Type", value: "type" },
        { text: "Value", value: "value" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    narrativeStatements: function () {
      if (this.narrativeSectionId) {
        return this.$store.getters[
          "templatesModule/getNarrativeStatementsBySection"
        ](this.narrativeSectionId);
      }
      return [];
    },
    sortedNarrativeStatements: function () {
      return this.narrativeStatements.sort((a, b) => a.sequence - b.sequence);
    },
  },
  methods: {
    deleteNarrativeStatement: async function (item) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete this narrative statement?"
        )
      ) {
        this.$store.commit("uxModule/setShowLoader", true);
        await this.$store.dispatch("templatesModule/deleteNarrativeStatement", {
          narrativeStatementId: item.narrativeStatementId,
        });
        this.$store.commit("uxModule/setShowLoader", false);
      }
    },
    async saveOrder(event) {
      this.$store.commit("uxModule/setShowLoader", true);
      const movedItem = this.narrativeStatements.splice(event.oldIndex, 1)[0];
      this.narrativeStatements.splice(event.newIndex, 0, movedItem);
      await this.$store.dispatch("templatesModule/saveNarrativeStatements", {
        narrativeStatements: this.narrativeStatements.map((item, index) => {
          return { ...item, sequence: index + 1 };
        }),
      });
      this.$store.commit("uxModule/setShowLoader", false);
    },
  },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit("sorted", event);
          },
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      },
    },
  },
};
</script>
