import { mergeAttributes, Node } from "@tiptap/core";
// Checkbox node emulates tiptap's TaskItem node, without the list item elements
// https://github.com/ueberdosis/tiptap/blob/main/packages/extension-task-item/src/task-item.ts
const Checkbox = Node.create({
  name: "checkbox",
  group: "inline",
  content: "text*",
  inline: true,
  defining: true,
  parseHTML() {
    return [{ tag: `input[type="checkbox"]` }];
  },
  renderHTML({ node, HTMLAttributes }) {
    return [
      "input",
      mergeAttributes(HTMLAttributes, {
        type: "checkbox",
        checked: node.attrs.checked ? "checked" : null,
      }),
      0,
    ];
  },
  addAttributes() {
    return {
      checked: {
        default: false,
        parseHTML: (element) => element.getAttribute("checked"),
        renderHTML: (attributes) => ({
          checked: attributes.checked ? "checked" : null,
        }),
      },
    };
  },
  addCommands() {
    return {
      insertCheckbox:
        () =>
        ({ commands }) => {
          return commands.insertContent(
            "<span><input type=checkbox></input></span>"
          );
        },
    };
  },
  addNodeView() {
    return ({ node, getPos, editor }) => {
      const checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.addEventListener("change", (event) => {
        const { checked } = event.target;

        if (editor.isEditable && typeof getPos === "function") {
          editor
            .chain()
            .focus()
            .command(({ tr }) => {
              const position = getPos();
              const currentNode = tr.doc.nodeAt(position);

              tr.setNodeMarkup(position, undefined, {
                ...currentNode?.attrs,
                checked,
              });

              return true;
            })
            .run();
        }
      });

      if (node.attrs.checked) {
        checkbox.setAttribute("checked", "checked");
      }

      return {
        dom: checkbox,
        update: (updatedNode) => {
          if (updatedNode.type !== this.type) {
            return false;
          }

          if (updatedNode.attrs.checked) {
            checkbox.setAttribute("checked", "checked");
          } else {
            checkbox.removeAttribute("checked");
          }

          return true;
        },
      };
    };
  },
});

export default Checkbox;
