<template>
  <v-dialog v-model="dialog" persistent width="70vw">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="isNewSection"
        color="primary"
        outlined
        rounded
        small
        v-on="on"
        @click="dialog = true"
        ><v-icon>mdi-plus</v-icon>New Section</v-btn
      >

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            v-if="!isNewSection"
            small
            class="mr-2"
            v-on="on"
            @click="dialog = true"
            >mdi-pencil</v-icon
          >
        </template>
        <span>Edit Section</span>
      </v-tooltip>
    </template>

    <v-card v-if="dialog">
      <validation-observer ref="form" v-slot="{ invalid }">
        <v-card-title> Narrative Section </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="px-6">
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field
              v-model="name"
              label="Title"
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>

          <v-text-field
            v-model="description"
            label="Description"
          ></v-text-field>

          <validation-provider rules="required" v-slot="{ errors }">
            <v-textarea
              v-model="instructions"
              label="Instructions"
              outlined
              :error-messages="errors"
            ></v-textarea>
          </validation-provider>

          <v-row class="justify-start">
            <v-col cols="4">
              <validation-provider rules="required|numeric" v-slot="{ errors }">
                <v-text-field
                  v-model="minWords"
                  label="Min Words"
                  type="number"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="4">
              <validation-provider rules="required|numeric" v-slot="{ errors }">
                <v-text-field
                  v-model="maxWords"
                  label="Max Words"
                  type="number"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="clear"> Cancel </v-btn>
          <v-btn color="primary" text :disabled="invalid" @click="save">
            Save
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["isNewSection", "narrativeSection", "aiNarrativeId"],
  data() {
    return {
      dialog: false,
      name: this.narrativeSection?.name || "",
      description: this.narrativeSection?.description || "",
      instructions: this.narrativeSection?.instructions || "",
      minWords: this.narrativeSection?.minWords || "",
      maxWords: this.narrativeSection?.maxWords || "",
    };
  },
  computed: {
    sequence: function () {
      return (
        this.narrativeSection?.sequence || this.narrativeSections.length + 1
      );
    },
    narrativeSections: function () {
      return this.$store.state.templatesModule.narrativeSections;
    },
  },
  methods: {
    save: async function () {
      this.dialog = false;
      this.$store.commit("uxModule/setShowLoader", true);
      let success = false;
      if (this.isNewSection) {
        success = await this.$store.dispatch(
          "templatesModule/addNarrativeSection",
          {
            aiNarrativeId: this.aiNarrativeId,
            sequence: this.sequence,
            name: this.name,
            description: this.description,
            instructions: this.instructions,
            minWords: this.minWords,
            maxWords: this.maxWords,
          }
        );
      } else {
        success = await this.$store.dispatch(
          "templatesModule/updateNarrativeSection",
          {
            narrativeSectionId: this.narrativeSection.narrativeSectionId,
            aiNarrativeId: this.aiNarrativeId,
            sequence: this.sequence,
            name: this.name,
            description: this.description,
            instructions: this.instructions,
            minWords: this.minWords,
            maxWords: this.maxWords,
          }
        );
      }
      this.$store.commit("uxModule/setShowLoader", false);

      if (!success) {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Error saving narrative section"
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }

      this.clear();
    },
    clear: function () {
      this.$refs.form.reset();
      this.dialog = false;
      this.name = this.narrativeSection?.name || "";
      this.description = this.narrativeSection?.description || "";
      this.instructions = this.narrativeSection?.instructions || "";
      this.minWords = this.narrativeSection?.minWords || "";
      this.maxWords = this.narrativeSection?.maxWords || "";
    },
  },
};
</script>
