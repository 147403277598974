<template>
  <validation-provider :rules="validations" v-slot="{ errors }">
    <label v-sanitize-html="field.label"></label>
    <v-text-field
      :value="value"
      :disabled="readonly"
      @change="onChange"
      v-mask="mask"
      :error-messages="errors"
      background-color="#FFFFFF"
      color="#005c7b"
      class="mt-0"
    ></v-text-field>
  </validation-provider>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  props: ["field", "value", "readonly", "validations"],
  directives: { mask },
  data() {
    return {
      mask: "#####",
    };
  },
  methods: {
    onChange: function (event) {
      this.$emit("fieldChange", event);
    },
  },
};
</script>
