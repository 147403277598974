<template>
  <v-row style="height: 85vh">
    <v-col cols="3" class="mr-3">
      <note-details></note-details>
    </v-col>
    <v-col cols="8">
      <router-view></router-view>
    </v-col>
    <v-spacer></v-spacer>
  </v-row>
</template>

<script>
import NoteDetails from "./note-details.vue";
export default {
  components: {
    NoteDetails,
  },
};
</script>
