<template>
  <v-data-table
    :headers="_headers"
    :items="reports"
    sort-by="dueDate"
    dense
    group-by="clientName"
    :key="reports.length"
  >
    <template
      v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }"
    >
      <td :colspan="headers.length">
        <v-btn @click="toggle" x-small icon :ref="group">
          <v-icon v-if="isOpen">mdi-minus</v-icon>
          <v-icon v-else>mdi-plus</v-icon>
        </v-btn>
        <span class="mx-5 font-weight-bold text-subtitle-2">
          {{ items[0].clientName }}
          {{ items[0].clientOsis || items[0].clientNyeisId }} ({{
            items[0].formattedClientDob
          }})
          <client-details
            v-if="programView != 'EIO'"
            :reportAssignment="items[0]"
          ></client-details>
        </span>
      </td>
    </template>
    <template v-slot:[`item.reportType`]="{ item }">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-chip v-on="on" color="primary" outlined small label>{{
            item.reportTypeName
          }}</v-chip>
        </template>
        <span> {{ getTemplateMessage(item) }} </span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.dueDate`]="{ item }">
      <span :class="highlightOverdueAndUpcoming(item)">{{
        item.formattedDueDate
      }}</span>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <status-chip :reportAssignment="item"></status-chip>
      <review-details-modal
        v-if="item.isReviewed"
        :item="item"
      ></review-details-modal>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <!-- if admin: EDIT ASSIGNMENT -->
      <report-assignment-form
        v-if="isAdmin"
        :reportAssignmentId="item.reportAssignmentId"
      ></report-assignment-form>
      <!-- if admin: CANCEL/RESTORE ASSIGNMENT -->
      <cancel-assignment-modal
        v-if="isAdmin && !item.isCancelled"
        :reportAssignmentId="item.reportAssignmentId"
      ></cancel-assignment-modal>
      <restore-assignment-modal
        v-if="isAdmin && item.isCancelled"
        :reportAssignmentId="item.reportAssignmentId"
      ></restore-assignment-modal>
      <!-- VIEW REPORTS -->
      <view-reports-modal
        :reportAssignmentId="item.reportAssignmentId"
        :hasFile="item.hasFile"
        :isAdmin="isAdmin"
      ></view-reports-modal>
      <!-- if template available: GO TO NOTE WIZARD -->
      <template-note-action
        v-if="item.hasTemplateAvailable"
        :reportAssignment="item"
        :isAdmin="isAdmin"
      ></template-note-action>
      <!-- if template available and in progress: START OVER -->
      <clear-note-modal
        v-if="item.hasTemplateAvailable && item.isTemplateNoteInProgress"
        :reportAssignment="item"
      ></clear-note-modal>
      <!-- if no template available: GO TO SECURE MESSAGING -->
      <messaging-action v-if="!item.hasTemplateAvailable"></messaging-action>
    </template>
  </v-data-table>
</template>
<script>
import StatusChip from "./status-chip.vue";
import ReviewDetailsModal from "./review-details-modal.vue";
import ReportAssignmentForm from "./report-assignment-form.vue";
import CancelAssignmentModal from "./cancel-assignment-modal.vue";
import RestoreAssignmentModal from "./restore-assignment-modal.vue";
import TemplateNoteAction from "./template-note-action.vue";
import ClearNoteModal from "../NoteWizard/clear-note-modal.vue";
import MessagingAction from "./messaging-action.vue";
import ViewReportsModal from "./view-reports-modal.vue";
import ClientDetails from "./client-details.vue";
export default {
  props: ["reports", "isAdmin"],
  components: {
    StatusChip,
    ReviewDetailsModal,
    ReportAssignmentForm,
    CancelAssignmentModal,
    RestoreAssignmentModal,
    TemplateNoteAction,
    ClearNoteModal,
    MessagingAction,
    ViewReportsModal,
    ClientDetails,
  },
  computed: {
    headers() {
      return [
        { text: "ID", value: "reportAssignmentId", align: "left" },
        { text: "Plan Date", value: "formattedPlanDate" },
        {
          text: "10/12",
          value: "tenOrTwelveMonth",
          hide: this.programView != "SEIT",
        },
        { text: "Service", value: "service", hide: this.programView != "EIO" },
        { text: "Provider", value: "providerName", hide: !this.isAdmin },
        { text: "Report Type", value: "reportType" },
        { text: "Due Date", value: "dueDate" },
        {
          text: "NYEIS Due Date",
          value: "formattedNyeisDueDate",
          hide: !this.isAdmin || this.programView != "EIO",
        },
        { text: "Status", value: "status" },
        { text: "Flag", value: "flag", hide: !this.isAdmin },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
    _headers() {
      return this.headers.filter((x) => !x.hide);
    },
    programView() {
      return this.$store.state.reportsModule.programView;
    },
  },
  methods: {
    getTemplateMessage(item) {
      if (item.templateName) {
        return item.isStartReportDisabled
          ? "Report not yet available to start."
          : `"${item.templateName}" Template Available! Use wizard to submit.`;
      } else {
        return `Wizard not available. Please email report to your supervisor.`;
      }
    },
    highlightOverdueAndUpcoming: function (item) {
      var dueDate = new Date(item.dueDate);
      var threeWeeksAgo = dueDate.setDate(dueDate.getDate() - 21);
      return (
        (new Date() >= dueDate || new Date() >= threeWeeksAgo) &&
        item.isPending &&
        "yellow accent-2"
      );
    },
  },
};
</script>
