<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            color="primary"
            small
            text
            v-bind="attrs"
            v-on="{ ...tooltip, ...menu }"
          >
            <v-icon small>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <span>More Actions</span>
      </v-tooltip>
    </template>
    <v-list>
      <manage-reports-modal v-if="programView == 'SEIT'"></manage-reports-modal>
      <report-assignment-roster
        v-if="programView == 'SEIT'"
      ></report-assignment-roster>
      <export-goals-report :type="programView"></export-goals-report>
    </v-list>
  </v-menu>
</template>

<script>
import ManageReportsModal from "./manage-reports-modal.vue";
import ReportAssignmentRoster from "./report-assignment-roster.vue";
import ExportGoalsReport from "./export-goals-report.vue";
export default {
  components: {
    ManageReportsModal,
    ReportAssignmentRoster,
    ExportGoalsReport,
  },
  computed: {
    programView() {
      return this.$store.state.reportsModule.programView;
    },
  },
};
</script>
