import ReportAssignment from "./ReportAssignment";
import Provider from "./Provider";
import Template from "./Template";
import Client from "./Client";
import QuestionResponse from "./QuestionResponse";

export default class TemplateNote {
  templateNoteId: number;
  reportAssignmentId?: number | null;
  reportAssignment?: ReportAssignment | null;
  providerId?: number | null;
  provider?: Provider | null;
  templateId?: number | null;
  template?: Template | null;
  clientId?: number | null;
  client?: Client | null;
  startedAt?: Date | null;
  noteText?: string | null;
  headerText?: string | null;
  footerText?: string | null;
  savedAt?: Date | null;
  questionResponses?: QuestionResponse[];
  processedAt?: Date | null;

  public constructor(params: TemplateNote = {} as TemplateNote) {
    let {
      templateNoteId = 0,
      reportAssignmentId = null,
      reportAssignment = null,
      providerId = 0,
      provider = null,
      templateId = 0,
      template = null,
      clientId = 0,
      client = null,
      startedAt = null,
      noteText = "",
      headerText = null,
      footerText = null,
      savedAt = null,
      questionResponses = [],
      processedAt = null,
    } = params;

    this.templateNoteId = templateNoteId;
    this.reportAssignmentId = reportAssignmentId;
    this.reportAssignment =
      reportAssignment && new ReportAssignment(reportAssignment);
    this.providerId = providerId;
    this.provider = provider && new Provider(provider);
    this.templateId = templateId;
    this.template = template && new Template(template);
    this.clientId = clientId;
    this.client = client && new Client(client);
    this.startedAt = startedAt;
    this.noteText = noteText;
    this.headerText = headerText;
    this.footerText = footerText;
    this.savedAt = savedAt;
    this.questionResponses =
      questionResponses &&
      questionResponses.map((q) => new QuestionResponse(q));
    this.processedAt = processedAt;
  }

  get providerName() {
    return this.provider?.firstName + " " + this.provider?.lastName;
  }

  get templateName() {
    return this.template?.name;
  }

  get status() {
    return this.reportAssignment?.status;
  }
}
