<template>
  <validation-provider :rules="rules" v-slot="{ errors }">
    <label v-sanitize-html="field.label"></label>
    <v-select
      :value="value"
      :disabled="readonly"
      :items="longTermGoals"
      :loading="loading"
      @change="onChange"
      :error-messages="errors"
      no-data-text="There are no goals available to select. Please contact your supervisor."
      color="#005c7b"
      class="mt-0"
      clearable
    >
    </v-select>
  </validation-provider>
</template>

<script>
import { FieldTypes } from "../../objs/Shared/FieldTypes";
export default {
  props: ["field", "value", "readonly", "validations"],
  async mounted() {
    this.loading = true;
    this.goals = await this.$store.dispatch("notesModule/getClientGoals");
    this.loading = false;
  },
  data() {
    return {
      loading: false,
      goals: [],
    };
  },
  computed: {
    longTermGoals() {
      // map to default properties of v-select's "items" array
      return this.goals.map((val) => {
        return {
          text: val.longTermGoalText,
          value: val.longTermGoalText,
          disabled:
            this.validations.DistinctResponse &&
            this.otherGoalsRenderedResponses.includes(val.longTermGoalText),
        };
      });
    },
    rules() {
      return {
        ...this.validations,
        ...(this.validations.DistinctResponse && {
          DistinctResponse: [this.otherGoalsRenderedResponses],
        }),
      };
    },
    otherGoalsRenderedResponses() {
      return this.$store.getters["notesModule/getOtherGoalsRenderedResponses"](
        this.field.label,
        FieldTypes.LONG_TERM_GOALS_SELECT
      );
    },
  },
  methods: {
    onChange(event) {
      if (event) this.$emit("fieldChange", event);
      else this.$emit("fieldChange", "");
    },
  },
};
</script>
