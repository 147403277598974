<template>
  <div>
    <label v-sanitize-html="field.label"></label>
    <v-menu
      v-model="showMenu"
      :disabled="readonly"
      :close-on-content-click="false"
      max-height="70vh"
      tile
    >
      <template v-slot:activator="{ on, attrs }">
        <validation-provider :rules="rules" v-slot="{ errors }">
          <v-text-field
            v-model="value"
            v-on="on"
            v-bind="attrs"
            class="mt-0"
            :loading="loading"
            color="#005c7b"
            :error-messages="errors"
            @click="showMenu = true"
            append-icon="mdi-close-circle"
            @click:append="onChange('')"
          ></v-text-field>
        </validation-provider>
      </template>

      <v-list>
        <v-list-group
          sub-group
          v-for="item in cognitiveGoals"
          :key="item.longTermGoalId"
        >
          <template v-slot:activator>
            <v-subheader disabled>{{ item.longTermGoalText }}</v-subheader>
          </template>
          <v-list-item
            v-for="(goal, i) in item.shortTermGoals"
            :key="i"
            @click="onChange(goal.value)"
            :disabled="goal.disabled"
          >
            <v-list-item-content>
              <v-list-item-title>{{ goal.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-subheader v-if="noGoals"
          >There are no goals available to select. Please contact your
          supervisor.</v-subheader
        >
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { FieldTypes } from "../../objs/Shared/FieldTypes";
export default {
  props: ["field", "value", "readonly", "validations"],
  async mounted() {
    this.loading = true;
    this.goals = await this.$store.dispatch("notesModule/getClientGoals");
    this.loading = false;
  },
  data() {
    return {
      showMenu: false,
      loading: false,
      goals: [],
    };
  },
  computed: {
    rules() {
      return {
        ...this.validations,
        ...(this.validations.DistinctResponse && {
          DistinctResponse: [this.otherGoalsRenderedResponses],
        }),
      };
    },
    mappedGoals() {
      // map to default properties of v-select's "items" array
      return this.goals.map((ltg) => {
        return {
          ...ltg,
          shortTermGoals: ltg.shortTermGoals.map((val) => {
            return {
              text: val,
              value: val,
              disabled:
                this.validations.DistinctResponse &&
                this.otherGoalsRenderedResponses.includes(val),
            };
          }),
        };
      });
    },
    otherGoalsRenderedResponses() {
      return this.$store.getters["notesModule/getOtherGoalsRenderedResponses"](
        this.field.label,
        FieldTypes.COGNITIVE_GOALS_SELECT
      );
    },
    cognitiveGoals() {
      return this.mappedGoals.filter(
        (x) => x.domainGroup == "Language and Cognition"
      );
    },
    noGoals() {
      return this.goals.length === 0;
    },
  },
  methods: {
    onChange(event) {
      if (event) this.$emit("fieldChange", event);
      else this.$emit("fieldChange", "");
      this.showMenu = false;
    },
  },
};
</script>
