import axios from "axios";
var omniApiUrlPrefix = process.env.VUE_APP_OMNI_BASE_URL + "/api";
var securityApiUrlPrefix = process.env.VUE_APP_OMNI_SECURITY_BASE_URL + "/api";
import securityModule from "../Modules/securityModule";

let instance = axios.create({ baseURL: omniApiUrlPrefix });
instance.interceptors.request.use(function (config) {
  var user = securityModule.state.userProfile;
  config.headers["UserId"] = user?.id;
  config.headers["FirstName"] = user?.firstName;
  config.headers["LastName"] = user?.lastName;
  return config;
});

export async function sendFeedbackEmail(data) {
  return axios
    .post(omniApiUrlPrefix + "/shared/sendFeedbackEmail", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getUser(data) {
  return axios
    .get(securityApiUrlPrefix + "/identity/getUser", {
      params: {
        userId: data,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function getUserAccess(data) {
  return axios
    .get(securityApiUrlPrefix + "/identity/getUserAccess", {
      params: {
        clientId: data.clientId,
        userId: data.userId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export async function get(path) {
  return instance
    .get(omniApiUrlPrefix + path)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function getQuery(path, data) {
  return instance
    .get(omniApiUrlPrefix + path, {
      params: data,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function post(path, data) {
  return instance
    .post(omniApiUrlPrefix + path, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function postQuery(path, data) {
  return instance
    .post(omniApiUrlPrefix + path, null, {
      params: data,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function uploadFile(path, params, data) {
  let formData = new FormData();
  formData.append("asset", data.file);

  return instance
    .put(omniApiUrlPrefix + path, formData, {
      params,
      "Content-Type": "multipart/form-data",
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}
