import Program from "./Program";

export default class ReportType {
  reportTypeId?: number;
  legacyReportTypeId?: string | null;
  name?: string | null;
  programId?: number | null;
  program?: Program | null;
  templateId?: number | null;

  public constructor(params: ReportType = {} as ReportType) {
    let {
      reportTypeId = 0,
      legacyReportTypeId = "",
      name = "",
      programId = null,
      program = null,
      templateId = null,
    } = params;
    this.reportTypeId = reportTypeId;
    this.legacyReportTypeId = legacyReportTypeId;
    this.name = name;
    this.programId = programId;
    this.program = program && new Program(program);
    this.templateId = templateId;
  }
}
