<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn text x-small v-on="on" @click="clearNote">
        <v-icon small>mdi-restart</v-icon>
      </v-btn>
    </template>
    <span> Start Over </span>
    <confirm-modal ref="confirm"></confirm-modal>
  </v-tooltip>
</template>
<script>
import ConfirmModal from "../Shared/confirm-modal.vue";
export default {
  components: {
    ConfirmModal,
  },
  props: ["reportAssignment"],
  methods: {
    clearNote: async function () {
      if (
        await this.$refs.confirm.open(
          "Are you sure you want to clear all your responses and start this report over?",
          "Note: This action will cannot be undone.",
          "Start Over"
        )
      ) {
        this.$store.commit("uxModule/setShowLoader", true);
        await this.$store.dispatch("notesModule/deleteTemplateNote", {
          templateNoteId: this.reportAssignment.templateNoteId,
        });
        this.$store.commit("uxModule/setShowLoader", false);
      }
    },
  },
};
</script>
