export default class PredefinedNarrativeStub {
  predefinedNarrativeStubId?: number;
  representation?: string | null;

  public constructor(params: PredefinedNarrativeStub = {} as PredefinedNarrativeStub) {
    let {
      predefinedNarrativeStubId = 0,
      representation = "",
    } = params;

    this.predefinedNarrativeStubId = predefinedNarrativeStubId;
    this.representation = representation;
  }
}
