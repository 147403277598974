<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn text x-small v-on="on" @click="openDialog">
              <v-icon small>{{
                hasFile ? "mdi-folder-open" : "mdi-folder-open-outline"
              }}</v-icon>
            </v-btn>
          </span>
        </template>
        <span> View Reports </span>
      </v-tooltip>
    </template>

    <v-card v-if="dialog">
      <v-card-title>
        Reports
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="reportAssignment.files"
          hide-default-header
          hide-default-footer
          no-data-text="No reports available to download"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <download-file-action
              :fileId="item.fileId"
              :filename="item.fileName"
            ></download-file-action>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions v-if="isAdmin">
        <v-spacer> </v-spacer>
        <upload-file-button
          title="Upload Report"
          @uploadFile="uploadFile"
        ></upload-file-button>
        <v-spacer> </v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import DownloadFileAction from "../Shared/download-file-action.vue";
import UploadFileButton from "../Shared/upload-file-button.vue";
export default {
  components: {
    DownloadFileAction,
    UploadFileButton,
  },
  props: ["reportAssignmentId", "isAdmin", "hasFile"],
  data() {
    return {
      dialog: false,
      headers: [
        { text: "File", value: "fileName" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  computed: {
    reportAssignment() {
      return this.$store.state.reportsModule.selectedReportAssignment;
    },
  },
  methods: {
    async openDialog() {
      this.$store.commit("uxModule/setShowLoader", true);
      await this.$store.dispatch("reportsModule/loadReportAssignment", {
        reportAssignmentId: this.reportAssignmentId,
      });

      this.$store.commit("uxModule/setShowLoader", false);

      this.dialog = true;
    },
    // this calls a callback when done
    uploadFile: async function (value, done) {
      this.$store.commit("uxModule/setShowLoader", true);
      await this.$store.dispatch("reportsModule/uploadReport", {
        reportAssignmentId: this.reportAssignment.reportAssignmentId,
        file: value,
      });
      this.$store.commit("uxModule/setShowLoader", false);

      done();
    },
  },
};
</script>
