<template>
  <v-card outlined height="84vh">
    <v-card-title>{{ selectedClient.fullName }}</v-card-title>
    <v-card-subtitle class="pb-2"
      >DOB: {{ selectedClient.formattedDobLong }}
    </v-card-subtitle>
    <v-card-text>
      <v-divider role="presentation" class="my-3"></v-divider>
      <div class="subtitle-1 font-weight-bold">Details</div>
      <div class="my-1">
        Report Type: <span class="font-weight-bold">{{ reportTypeName }}</span>
      </div>
      <div class="my-1">
        Template: <span class="font-weight-bold">{{ templateName }}</span>
      </div>
      <div v-if="isAdmin" class="my-1">
        Provider: <span class="font-weight-bold">{{ providerName }}</span>
      </div>
      <div class="my-1">
        Status:
        <status-chip
          :reportAssignment="templateNote.reportAssignment"
        ></status-chip>
      </div>
      <v-divider role="presentation" class="my-3"></v-divider>
      <div class="subtitle-1 font-weight-bold">Progress</div>
      <v-row class="my-2 ma-auto" justify="center">
        <v-progress-circular :value="progress" color="primary" size="90">
          <strong>{{ progress }}%</strong>
        </v-progress-circular>
      </v-row>
      <template v-if="showMissingResponsesMessage">
        <v-divider role="presentation" class="my-3"></v-divider>

        <div class="text-caption error--text">
          * Some questions are missing responses in:

          <v-btn
            v-for="f in familiesMissingResponses"
            :key="f.familyId"
            x-small
            text
            class="text-caption error--text text-decoration-underline"
            style="cursor: pointer"
            @click="goToFamily(f)"
          >
            {{ f.label }}
          </v-btn>
        </div>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
import { ReportAssignmentStatus } from "../../objs/ReportAssignmentStatus";
import StatusChip from "../ReportAssignments/status-chip.vue";
export default {
  components: {
    StatusChip,
  },
  computed: {
    isAdmin() {
      return this.$store.getters["securityModule/isAdmin"];
    },
    templateNote() {
      return this.$store.state.notesModule.selectedTemplateNote;
    },
    selectedClient() {
      return this.templateNote?.client;
    },
    selectedTemplate() {
      return this.templateNote?.template;
    },
    reportTypeName() {
      return this.templateNote?.reportAssignment?.reportType?.name;
    },
    templateName() {
      return this.selectedTemplate?.name;
    },
    providerName() {
      return this.templateNote?.providerName;
    },
    progress() {
      return this.$store.getters["notesModule/getProgress"];
    },
    showMissingResponsesMessage() {
      return (
        this.templateNote?.status !== ReportAssignmentStatus.IN_PROGRESS &&
        this.$store.getters["notesModule/isMissingResponses"] === true
      );
    },
    familiesMissingResponses() {
      return [
        ...new Set(
          this.$store.getters["notesModule/getQuestionsMissingResponses"].map(
            (item) =>
              this.$store.getters["notesModule/getFamily"](item.familyId)
          )
        ),
      ];
    },
  },
  methods: {
    goToFamily(family) {
      return this.$router.push({
        name: "TemplateNote",
        query: {
          pos: family.sequence - 1,
        },
      });
    },
  },
};
</script>
