import Rendering from "./Rendering";

export default class NarrativeBlock {
  narrativeBlockId?: number;
  templateId?: number | null;
  isHeader?: Boolean | null;
  isFooter?: Boolean | null;
  sequence?: number | null;
  text?: string | null;
  renderingId: number;
  rendering?: Rendering | null;

  public constructor(params: NarrativeBlock = {} as NarrativeBlock) {
    let {
      narrativeBlockId = 0,
      templateId = 0,
      isHeader = false,
      isFooter = false,
      sequence = 0,
      text = "",
      renderingId = 0,
      rendering = new Rendering(),
    } = params;

    this.narrativeBlockId = narrativeBlockId;
    this.templateId = templateId;
    this.isHeader = isHeader;
    this.isFooter = isFooter;
    this.sequence = sequence;
    this.text = text;
    this.renderingId = renderingId
    this.rendering = rendering && new Rendering(rendering);
  }
}
