<template>
  <v-dialog v-model="dialog" width="900" max-height="700">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip top v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn
              fab
              icon
              @click="dialog = true"
              color="primary"
              :disabled="!Boolean(reportAssignment)"
              ><v-icon>mdi-comment-text-multiple-outline</v-icon></v-btn
            >
          </span>
        </template>
        <span> Notes</span>
      </v-tooltip>
    </template>

    <v-card v-if="dialog">
      <v-card-title
        >Notes
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <validation-observer ref="form" v-slot="{ invalid }">
          <v-row dense class="mt-0">
            <v-col cols="11" sm="9">
              <form-field
                :field="textEditor"
                @fieldChange="newNoteText = $event"
                :validations="{ required: true }"
              ></form-field>
            </v-col>
            <v-col cols="12" sm="2" class="d-flex align-center justify-start">
              <v-btn
                text
                color="primary"
                :dark="!invalid && !loading"
                :disabled="invalid || loading"
                :loading="loading"
                @click="saveNote"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </validation-observer>

        <notes-table :notes="reportAssignment.notes"></notes-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import NotesTable from "./notes-table.vue";
import FormField from "../../objs/Shared/FormField";
export default {
  components: {
    NotesTable,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      newNoteText: "",
    };
  },
  computed: {
    reportAssignment() {
      return this.$store.state.reportsModule.selectedReportAssignment;
    },
    textEditor() {
      return new FormField({
        value: this.newNoteText,
        label: "Enter new note",
        icon: "mdi-comment",
        componentName: "text-area",
      });
    },
  },
  methods: {
    async saveNote() {
      this.loading = true;
      let success = await this.$store.dispatch("reportsModule/saveNote", {
        reportAssignmentId: this.reportAssignment?.reportAssignmentId,
        noteText: this.newNoteText,
      });
      this.loading = false;

      if (!success) {
        this.$store.commit("uxModule/setSnackbarMsg", "Error saving note");
        this.$store.commit("uxModule/setShowSnackbar", true);
      }

      this.clear();
    },
    close() {
      this.clear();
      this.dialog = false;
    },
    clear() {
      this.$refs.form.reset();
      this.newNoteText = "";
    },
  },
};
</script>
