import * as api from "../Services/api.js";

export const state = {};

export const mutations = {};

export const getters = {};

export const actions = {
  async uploadFile({ rootState }, data) {
    let userId = rootState.securityModule.userProfile?.id;

    return await api
      .uploadFile("/files", { userId: userId }, data)
      .then((response) => {
        if (response.success) {
          return response.data;
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async getFileUrl({}, data) {
    return await api
      .get("/files/" + data.fileId + "/url")
      .then((response) => {
        if (response.success) {
          return response.data;
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async generateFileDownloads({}, data) {
    return await api
      .post("/files/download", data.fileIds)
      .then((response) => {
        if (response.success) {
          return response.data;
        }

        return false;
      })
      .catch((error) => {
        return false;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
