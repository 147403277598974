import Component from "./Component";
import QuestionOption from "./QuestionOption";
import store from "../store";

export default class PredefinedProperty {
  predefinedPropertyId?: number;
  name?: string | null;
  componentId?: number | null;
  component?: Component | null;
  options?: QuestionOption[] | null;

  public constructor(params: PredefinedProperty = {} as PredefinedProperty) {
    let {
      predefinedPropertyId = 0,
      name = "",
      componentId = 0,
      component = null,
      options = [],
    } = params;

    this.predefinedPropertyId = predefinedPropertyId;
    this.name = name;
    this.componentId = componentId;
    this.component = component && new Component(component);
    this.options = options && options.map((o) => new QuestionOption(o));
  }

  get componentName() {
    let component =
      this.component ||
      store.getters["optionsModule/component"](this.componentId);

    return component?.jsName;
  }
}
