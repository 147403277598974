import * as api from "../Services/api";
import Template from "../../objs/Template";
import Family from "../../objs/Family";
import Question from "../../objs/Question";
import NarrativeStub from "../../objs/NarrativeStub";
import PredefinedNarrativeStub from "../../objs/PredefinedStub";
import Narrative from "../../objs/NarrativeBlock";
import AINarrative from "../../objs/AINarrative";
import NarrativeSection from "../../objs/NarrativeSection";
import NarrativeStatement from "../../objs/NarrativeStatement";

export const state = {
  selectedTemplate: {},
  templates: [],
  families: [],
  questions: [],
  stubs: [],
  predefinedStubs: [],
  narratives: [],
  aiNarrative: {},
  narrativeSections: [],
  narrativeStatements: [],
};

export const mutations = {
  SET_SELECTED_TEMPLATE(state, payload) {
    state.selectedTemplate = new Template(payload);
  },
  SET_TEMPLATES(state, payload) {
    state.templates = payload ? payload.map((item) => new Template(item)) : [];
  },
  ADD_TEMPLATE(state, payload) {
    state.templates.push(new Template(payload));
  },
  UPDATE_TEMPLATE(state, payload) {
    state.templates = state.templates.map((item) => {
      return item.templateId === payload.templateId
        ? new Template(payload)
        : item;
    });
  },
  SET_FAMILIES(state, payload) {
    state.families = payload ? payload.map((item) => new Family(item)) : [];
  },
  ADD_FAMILY(state, payload) {
    state.families.push(new Family(payload));
  },
  UPDATE_FAMILY(state, payload) {
    state.families = state.families.map((item) => {
      return item.familyId === payload.familyId ? new Family(payload) : item;
    });
  },
  DELETE_FAMILY(state, familyId) {
    state.families = state.families.filter(
      (item) => item.familyId !== familyId
    );
  },
  SET_QUESTIONS(state, payload) {
    state.questions = payload ? payload.map((item) => new Question(item)) : [];
  },
  ADD_QUESTION(state, payload) {
    state.questions.push(new Question(payload));
  },
  UPDATE_QUESTION(state, payload) {
    state.questions = state.questions.map((item) => {
      return item.questionId === payload.questionId
        ? new Question(payload)
        : item;
    });
  },
  DELETE_QUESTION(state, questionId) {
    state.questions = state.questions.filter(
      (item) => item.questionId !== questionId
    );
  },
  SET_STUBS(state, payload) {
    state.stubs = payload ? payload.map((item) => new NarrativeStub(item)) : [];
  },
  ADD_STUB(state, payload) {
    state.stubs.push(new NarrativeStub(payload));
  },
  UPDATE_STUB(state, payload) {
    state.stubs = state.stubs.map((item) => {
      return item.narrativeStubId === payload.narrativeStubId
        ? new NarrativeStub(payload)
        : item;
    });
  },
  DELETE_STUB(state, narrativeStubId) {
    state.stubs = state.stubs.filter(
      (item) => item.narrativeStubId !== narrativeStubId
    );
  },
  SET_PREDEFINED_STUBS(state, payload) {
    state.predefinedStubs = payload
      ? payload.map((item) => new PredefinedNarrativeStub(item))
      : [];
  },
  SET_NARRATIVES(state, payload) {
    state.narratives = payload
      ? payload.map((item) => new Narrative(item))
      : [];
  },
  ADD_NARRATIVE(state, payload) {
    state.narratives.push(new Narrative(payload));
  },
  UPDATE_NARRATIVE(state, payload) {
    state.narratives = state.narratives.map((item) => {
      return item.narrativeBlockId === payload.narrativeBlockId
        ? new Narrative(payload)
        : item;
    });
  },
  DELETE_NARRATIVE(state, narrativeBlockId) {
    state.narratives = state.narratives.filter(
      (item) => item.narrativeBlockId !== narrativeBlockId
    );
  },
  SET_AI_NARRATIVE(state, payload) {
    state.aiNarrative = payload && new AINarrative(payload);
  },
  SET_NARRATIVE_SECTIONS(state, payload) {
    state.narrativeSections = payload
      ? payload.map((item) => new NarrativeSection(item))
      : [];
  },
  ADD_NARRATIVE_SECTION(state, payload) {
    state.narrativeSections.push(new NarrativeSection(payload));
  },
  UPDATE_NARRATIVE_SECTION(state, payload) {
    state.narrativeSections = state.narrativeSections.map((item) => {
      return item.narrativeSectionId === payload.narrativeSectionId
        ? new NarrativeSection(payload)
        : item;
    });
  },
  DELETE_NARRATIVE_SECTION(state, narrativeSectionId) {
    state.narrativeSections = state.narrativeSections.filter(
      (item) => item.narrativeSectionId !== narrativeSectionId
    );
  },
  SET_NARRATIVE_STATEMENTS(state, payload) {
    state.narrativeStatements = payload
      ? payload.map((item) => new NarrativeStatement(item))
      : [];
  },
  ADD_NARRATIVE_STATEMENT(state, payload) {
    state.narrativeStatements.push(new NarrativeStatement(payload));
  },
  UPDATE_NARRATIVE_STATEMENT(state, payload) {
    state.narrativeStatements = state.narrativeStatements.map((item) => {
      return item.narrativeStatementId === payload.narrativeStatementId
        ? new NarrativeStatement(payload)
        : item;
    });
  },
  DELETE_NARRATIVE_STATEMENT(state, narrativeStatementId) {
    state.narrativeStatements = state.narrativeStatements.filter(
      (item) => item.narrativeStatementId !== narrativeStatementId
    );
  },
};

export const getters = {
  getSelectedTemplateId(state) {
    return state.selectedTemplate?.templateId;
  },
  getTemplatesByProgram: (state) => (programId) => {
    return state.templates.filter((item) => item.programId === programId);
  },
  getQuestionsByFamily: (state) => (familyId) => {
    return state.questions
      .filter((item) => item.familyId === familyId)
      .sort((a, b) => a.sequence - b.sequence);
  },
  getFamilyByQuestion: (state, getters) => (questionId) => {
    return state.families.find(
      (item) => item.familyId === getters.getQuestion(questionId)?.familyId
    );
  },
  getFamily: (state) => (familyId) => {
    return state.families.find((item) => item.familyId === familyId);
  },
  getQuestion: (state) => (questionId) => {
    return state.questions.find((item) => item.questionId === questionId);
  },
  getStubsList(state) {
    let list = [];
    list.push({ header: "Predefined Stubs" });
    list.push(state.predefinedStubs);

    var narStubs = state.stubs.reduce((group, stub) => {
      if (!group[stub.familyId]) {
        group[stub.familyId] = [];
        group[stub.familyId].push({ header: stub.family?.label });
      }
      group[stub.familyId].push(stub);

      return group;
    }, {});
    list.push(Object.values(narStubs).flat());
    return list.flat();
  },
  getNarrativeStatementsBySection: (state) => (narrativeSectionId) => {
    return state.narrativeStatements
      .filter((item) => item.narrativeSectionId === narrativeSectionId)
      .sort((a, b) => a.sequence - b.sequence);
  },
};

export const actions = {
  async getAllTemplates({ commit }) {
    return await api.get("/templates/getAllTemplates").then((response) => {
      if (response.success) {
        commit("SET_TEMPLATES", response.data);
      }
    });
  },
  async getActiveTemplates({ rootState, dispatch }) {
    let providerId = rootState.sharedModule.providerId;
    if (!providerId) {
      providerId = await dispatch("sharedModule/getProviderId", null, {
        root: true,
      });
    }

    return await api
      .getQuery("/templates/getActiveTemplatesForProvider", { providerId })
      .then((response) => {
        if (response.success) {
          return response.data
            ? response.data.map((item) => new Template(item))
            : [];
        }
      });
  },
  async getPredefinedStubs({ commit }) {
    return await api
      .get("/templates/getPredefinedNarrativeStubs")
      .then((response) => {
        if (response.success) {
          commit("SET_PREDEFINED_STUBS", response.data);
        }
      });
  },
  async loadTemplate({ commit }, data) {
    return await api
      .getQuery("/templates/loadFullTemplate", { templateId: data.templateId })
      .then((response) => {
        if (response.success) {
          commit("SET_SELECTED_TEMPLATE", response.data);
          commit("SET_FAMILIES", response.data?.families);
          commit(
            "SET_QUESTIONS",
            response.data?.families.map((family) => family.questions).flat()
          );
          commit("SET_STUBS", response.data?.stubs);
          commit("SET_NARRATIVES", response.data?.narrativeBlocks);
          commit("SET_AI_NARRATIVE", response.data?.aiNarrative);
          commit(
            "SET_NARRATIVE_SECTIONS",
            response.data?.aiNarrative?.narrativeSections
          );
          commit(
            "SET_NARRATIVE_STATEMENTS",
            response.data?.aiNarrative?.narrativeSections
              .map((section) => section.narrativeStatements)
              .flat()
          );
        }
      });
  },
  async addTemplate({ commit }, data) {
    return await api
      .post("/templates/createTemplate", data)
      .then((response) => {
        if (response.success) {
          commit("ADD_TEMPLATE", response.data);
          commit("SET_SELECTED_TEMPLATE", response.data);
          return "";
        }

        return response.message;
      })
      .catch((error) => {
        return false;
      });
  },
  async updateTemplate({ commit }, data) {
    return await api
      .post("/templates/updateTemplate", data)
      .then((response) => {
        if (response.success) {
          commit("UPDATE_TEMPLATE", response.data);
          commit("SET_SELECTED_TEMPLATE", response.data);
          return "";
        }

        return response.message;
      })
      .catch((error) => {
        return false;
      });
  },
  async addFamily({ commit }, data) {
    return await api
      .post("/templates/createFamily", data)
      .then((response) => {
        if (response.success) {
          commit("ADD_FAMILY", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async updateFamily({ commit }, data) {
    return await api
      .post("/templates/updateFamily", data)
      .then((response) => {
        if (response.success) {
          commit("UPDATE_FAMILY", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async deleteFamily({ commit, dispatch, state }, data) {
    return await api
      .postQuery("/templates/deleteFamily", { familyId: data.familyId })
      .then((response) => {
        if (response.success) {
          commit("DELETE_FAMILY", response.data);
          // reload template details because sequence may have changed
          dispatch("loadTemplate", {
            templateId: state.selectedTemplate?.templateId,
          });
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async saveFamilies({ commit }, data) {
    commit("SET_FAMILIES", data.families);
    return await api
      .post("/templates/saveFamilies", data.families)
      .then((response) => {
        if (response.success) {
          commit("SET_FAMILIES", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async addQuestion({ commit }, data) {
    return await api
      .post("/templates/createQuestion", data)
      .then((response) => {
        if (response.success) {
          commit("ADD_QUESTION", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async updateQuestion({ commit }, data) {
    return await api
      .post("/templates/updateQuestion", data)
      .then((response) => {
        if (response.success) {
          commit("UPDATE_QUESTION", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async deleteQuestion({ commit, dispatch, state }, data) {
    return await api
      .postQuery("/templates/deleteQuestion", { questionId: data.questionId })
      .then((response) => {
        if (response.success) {
          commit("DELETE_QUESTION", response.data);
          // reload template details because sequence may have changed
          dispatch("loadTemplate", {
            templateId: state.selectedTemplate?.templateId,
          });
          return "";
        }

        return response.message;
      })
      .catch((error) => {
        return false;
      });
  },
  async saveQuestions({ commit }, data) {
    if (data.questions && data.questions.length > 0) {
      data.questions.map((question) => {
        commit("UPDATE_QUESTION", question);
      });

      return await api
        .post("/templates/saveQuestionsSequences", data.questions)
        .then((response) => {
          if (response.success) {
            if (response.data && response.data.length > 0) {
              response.data.map((question) => {
                commit("UPDATE_QUESTION", question);
              });
            }
          }

          return response.success;
        })
        .catch((error) => {
          return false;
        });
    }
  },
  async addStub({ commit }, data) {
    return await api
      .post("/templates/createStub", data)
      .then((response) => {
        if (response.success) {
          commit("ADD_STUB", response.data);
          return "";
        }

        return response.message;
      })
      .catch((error) => {
        return "An error occurred while creating this stub";
      });
  },
  async updateStub({ commit }, data) {
    return await api
      .post("/templates/updateStub", data)
      .then((response) => {
        if (response.success) {
          commit("UPDATE_STUB", response.data);
          return "";
        }

        return response.message;
      })
      .catch((error) => {
        return "An error occurred while updating this stub";
      });
  },
  async deleteStub({ commit }, data) {
    return await api
      .postQuery("/templates/deleteStub", {
        narrativeStubId: data.narrativeStubId,
      })
      .then((response) => {
        if (response.success) {
          commit("DELETE_STUB", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async addNarrative({ commit }, data) {
    return await api
      .post("/templates/createNarrativeBlock", data)
      .then((response) => {
        if (response.success) {
          commit("ADD_NARRATIVE", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async updateNarrative({ commit }, data) {
    return await api
      .post("/templates/updateNarrativeBlock", data)
      .then((response) => {
        if (response.success) {
          commit("UPDATE_NARRATIVE", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async deleteNarrative({ commit, dispatch, state }, data) {
    return await api
      .postQuery("/templates/deleteNarrativeBlock", {
        narrativeBlockId: data.narrativeBlockId,
      })
      .then((response) => {
        if (response.success) {
          commit("DELETE_NARRATIVE", response.data);
          // reload template details because sequence may have changed
          dispatch("loadTemplate", {
            templateId: state.selectedTemplate?.templateId,
          });
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async saveNarratives({ commit }, data) {
    commit("SET_NARRATIVES", data.narratives);
    return await api
      .post("/templates/saveNarrativeSequences", data.narratives)
      .then((response) => {
        if (response.success) {
          commit("SET_NARRATIVES", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async addAINarrative({ commit }, data) {
    return await api
      .post("/templates/createAINarrative", data)
      .then((response) => {
        if (response.success) {
          commit("SET_AI_NARRATIVE", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async updateAINarrative({ commit }, data) {
    return await api
      .post("/templates/updateAINarrative", data)
      .then((response) => {
        if (response.success) {
          commit("SET_AI_NARRATIVE", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async addNarrativeSection({ commit }, data) {
    return await api
      .post("/templates/createNarrativeSection", data)
      .then((response) => {
        if (response.success) {
          commit("ADD_NARRATIVE_SECTION", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async updateNarrativeSection({ commit }, data) {
    return await api
      .post("/templates/updateNarrativeSection", data)
      .then((response) => {
        if (response.success) {
          commit("UPDATE_NARRATIVE_SECTION", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async deleteNarrativeSection({ commit, dispatch, state }, data) {
    return await api
      .postQuery("/templates/deleteNarrativeSection", {
        narrativeSectionId: data.narrativeSectionId,
      })
      .then((response) => {
        if (response.success) {
          commit("DELETE_NARRATIVE_SECTION", response.data);
          // reload template details because sequence may have changed
          dispatch("loadTemplate", {
            templateId: state.selectedTemplate?.templateId,
          });
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async saveNarrativeSections({ commit }, data) {
    commit("SET_NARRATIVE_SECTIONS", data.narrativeSections);
    return await api
      .post("/templates/saveNarrativeSectionSequences", data.narrativeSections)
      .then((response) => {
        if (response.success) {
          commit("SET_NARRATIVE_SECTIONS", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async addNarrativeStatement({ commit }, data) {
    return await api
      .post("/templates/createNarrativeStatement", data)
      .then((response) => {
        if (response.success) {
          commit("ADD_NARRATIVE_STATEMENT", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async updateNarrativeStatement({ commit }, data) {
    return await api
      .post("/templates/updateNarrativeStatement", data)
      .then((response) => {
        if (response.success) {
          commit("UPDATE_NARRATIVE_STATEMENT", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async deleteNarrativeStatement({ commit, dispatch, state }, data) {
    return await api
      .postQuery("/templates/deleteNarrativeStatement", {
        narrativeStatementId: data.narrativeStatementId,
      })
      .then((response) => {
        if (response.success) {
          commit("DELETE_NARRATIVE", response.data);
          // reload template details because sequence may have changed
          dispatch("loadTemplate", {
            templateId: state.selectedTemplate?.templateId,
          });
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async saveNarrativeStatements({ commit }, data) {
    commit("SET_NARRATIVE_STATEMENTS", data.narrativeStatements);
    return await api
      .post("/templates/saveNarrativeStatementSequences", data.narrativeStatements)
      .then((response) => {
        if (response.success) {
          commit("SET_NARRATIVE_STATEMENTS", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async cloneFamilyAndStubsToTemplate({ commit, dispatch, state }, data) {
    return await api
      .postQuery("/templates/cloneFullFamilyToTemplate", data)
      .then(async (response) => {
        if (response.success) {
          commit("ADD_FAMILY", response.data);

          return await api
            .postQuery("/templates/cloneFamilyStubsToTemplate", data)
            .then((response) => {
              if (response.success) {
                commit("SET_STUBS", response.data);
              }

              dispatch("loadTemplate", {
                templateId: state.selectedTemplate?.templateId,
              });

              return response.success
                ? "Clone completed successfully. Please review details carefully for accuracy and sequence."
                : "Clone failed!";
            });
        }

        return "Clone failed!";
      });
  },
  async cloneFamilyToTemplate({ commit, dispatch, state }, data) {
    return await api
      .postQuery("/templates/cloneFullFamilyToTemplate", data)
      .then(async (response) => {
        if (response.success) {
          commit("ADD_FAMILY", response.data);
        }

        dispatch("loadTemplate", {
          templateId: state.selectedTemplate?.templateId,
        });

        return response.success;
      });
  },
  async cloneNarrativesToTemplate({ commit }, data) {
    let failures = [];

    for await (const narrativeId of data.narrativeIds) {
      let response = await api.postQuery(
        "/templates/cloneNarrativeBlockToTemplate",
        { narrativeBlockId: narrativeId, templateId: data.templateId }
      );

      if (response.success) {
        commit("ADD_NARRATIVE", response.data);
      } else {
        failures.push(narrativeId);
      }
    }

    return failures.length == 0
      ? "Clone completed successfully. Please review details carefully for accuracy and sequence."
      : `Failed to clone ${failures.length} narratives`;
  },
  async cloneQuestionToFamily({ commit, state, dispatch }, data) {
    return await api
      .postQuery("/templates/cloneQuestionToFamily", {
        questionId: data.questionId,
        familyId: data.familyId,
      })
      .then((response) => {
        if (response.success) {
          commit("UPDATE_FAMILY", response.data);

          dispatch("loadTemplate", {
            templateId: state.selectedTemplate?.templateId,
          });
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
