<template>
  <span>
    <v-btn-toggle
      v-if="isSEITAdmin && isEIAdmin"
      v-model="programView"
      mandatory
      dense
      color="primary"
      @change="toggleView"
    >
      <v-btn value="SEIT">SEIT</v-btn>
      <v-btn value="EIO">EI</v-btn>
    </v-btn-toggle>
    <report-assignments></report-assignments>
  </span>
</template>
<script>
import ReportAssignments from "./report-assignments-home";
export default {
  components: { ReportAssignments },
  async mounted() {
    if (this.isSEITAdmin || this.isEIAdmin) {
      // set default view based on role
      this.programView =
        this.programView || (this.isSEITAdmin ? "SEIT" : "EIO");
      await this.getAllReportAssignments();
    } else {
      await this.getReportAssignmentsForProvider();
    }
  },
  computed: {
    isSEITAdmin() {
      return this.$store.getters["securityModule/hasUserRole"]("SEITAdmin");
    },
    isEIAdmin() {
      return this.$store.getters["securityModule/hasUserRole"]("EIAdmin");
    },
    programView: {
      get() {
        return this.$store.state.reportsModule.programView;
      },
      set(value) {
        this.$store.dispatch("reportsModule/toggleProgramView", value);
      },
    },
  },
  methods: {
    async toggleView() {
      this.$store.dispatch("reportsModule/clearFilters");
      await this.getAllReportAssignments();
    },
    async getAllReportAssignments() {
      this.$store.commit("uxModule/setShowLoader", true);
      if (this.programView === "SEIT") {
        await this.$store.dispatch("reportsModule/getSEITReportAssignments");
      } else if (this.programView === "EIO") {
        await this.$store.dispatch("reportsModule/getEIReportAssignments");
      }
      this.$store.commit("uxModule/setShowLoader", false);
    },
    async getReportAssignmentsForProvider() {
      this.$store.commit("uxModule/setShowLoader", true);
      await this.$store.dispatch(
        "reportsModule/getReportAssignmentsForProvider"
      );
      this.$store.commit("uxModule/setShowLoader", false);
    },
  },
};
</script>
