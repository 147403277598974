<template>
  <validation-provider :rules="validations" v-slot="{ errors }">
    <label v-sanitize-html="field.label"></label>
    <v-row fluid class="align-center ma-3">
      <signature-pad @onSign="onChange"> </signature-pad>
      <v-card v-if="Boolean(value)" v-model="value" flat>
        <img :src="value" class="image" />
      </v-card>
    </v-row>
    <span class="error--text text-caption">{{ errors[0] }}</span>
  </validation-provider>
</template>

<script>
import SignaturePad from "../Shared/signature-pad.vue";
export default {
  components: {
    SignaturePad,
  },
  props: ["field", "value", "validations"],
  methods: {
    onChange: function (event) {
      this.$emit("fieldChange", {
        isImage: true,
        imageURI: event.signatureURI,
      });
    },
  },
};
</script>
