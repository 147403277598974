<template>
  <div>
    <stubs-table class="mb-3"></stubs-table>
    <v-card-actions class="justify-center">
      <stub-modal :newStub="true"></stub-modal>
    </v-card-actions>
  </div>
</template>
<script>
import StubsTable from "./stubs-table.vue";
import StubModal from "./stub-modal.vue";

export default {
  components: {
    StubsTable,
    StubModal,
  },
};
</script>
