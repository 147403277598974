<template>
  <validation-provider :rules="rules" v-slot="{ errors }">
    <label v-sanitize-html="field.label"></label>
    <v-textarea
      :value="value"
      :disabled="readonly"
      @input="onInput"
      :outlined="outlined"
      auto-grow
      rows="1"
      :error-messages="errors"
      background-color="#FFFFFF"
      color="#005c7b"
      class="mt-0"
    ></v-textarea>
  </validation-provider>
</template>

<script>
export default {
  props: ["field", "value", "readonly", "outlined", "validations"],
  data() {
    return {
      debounce: "",
    };
  },
  computed: {
    rules() {
      return {
        ...this.validations,
        maxLength: 4000,
      };
    },
  },
  methods: {
    onInput: function (event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        this.$emit("fieldChange", event);
      }, 300);
    },
  },
};
</script>
