import PredefinedNarrativeStub from "./PredefinedStub";
import Question from "./Question";
import Rendering from "./Rendering";

export default class NarrativeStatement {
  narrativeStatementId?: number;
  narrativeSectionId?: number | null;
  sequence?: number | null;
  renderingId: number;
  rendering?: Rendering | null;
  instructions?: string | null;
  prefix?: string | null;
  suffix?: string | null;
  questionId?: number | null;
  question?: Question | null;
  predefinedNarrativeStubId?: number | null;
  predefinedNarrativeStub?: PredefinedNarrativeStub | null;
  content?: string | null;

  public constructor(params: NarrativeStatement = {} as NarrativeStatement) {
    let {
      narrativeStatementId = 0,
      narrativeSectionId = 0,
      sequence = 0,
      renderingId = 0,
      rendering = new Rendering(),
      instructions = "",
      prefix = "",
      suffix = "",
      questionId = null,
      question = null,
      predefinedNarrativeStubId = null,
      predefinedNarrativeStub = null,
      content = "",
    } = params;

    this.narrativeStatementId = narrativeStatementId;
    this.narrativeSectionId = narrativeSectionId;
    this.sequence = sequence;
    this.renderingId = renderingId;
    this.rendering = rendering && new Rendering(rendering);
    this.instructions = instructions;
    this.prefix = prefix;
    this.suffix = suffix;
    this.questionId = questionId;
    this.question = question ? new Question(question) : null;
    this.predefinedNarrativeStubId = predefinedNarrativeStubId;
    this.predefinedNarrativeStub =
      predefinedNarrativeStub &&
      new PredefinedNarrativeStub(predefinedNarrativeStub);
    this.content = content;
  }

  get type() {
    if (Boolean(this.content)) return "Content";
    if (Boolean(this.questionId)) return "Question";
    if (Boolean(this.predefinedNarrativeStub)) return "Stub";
  }
}
