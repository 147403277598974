export default class AINarrative {
  aiNarrativeId?: number;
  templateId?: number | null;
  description?: string | null;
  instructions?: string | null;
  minWords?: number | null;
  maxWords?: number | null;

  public constructor(params: AINarrative = {} as AINarrative) {
    let {
      aiNarrativeId = 0,
      templateId = 0,
      description = "",
      instructions = "",
      minWords = 0,
      maxWords = 0,
    } = params;

    this.aiNarrativeId = aiNarrativeId;
    this.templateId = templateId;
    this.description = description;
    this.instructions = instructions;
    this.minWords = minWords;
    this.maxWords = maxWords;
  }
}
