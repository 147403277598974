import RenderingConfig from "./RenderingConfig";
import { RenderingMethods } from "./RenderingMethods";

export default class Rendering {
  renderingId: number;
  renderingMethod: string;
  renderingConfigs?: RenderingConfig[];
  conditionalOperator?: string | null;

  public constructor(params: Rendering = {} as Rendering) {
    let {
      renderingId = 0,
      renderingMethod = RenderingMethods.UNCONDITIONAL,
      renderingConfigs = [],
      conditionalOperator = null,
    } = params;

    this.renderingId = renderingId;
    this.renderingMethod = renderingMethod;
    this.renderingConfigs =
      renderingConfigs &&
      renderingConfigs.map((item) => new RenderingConfig(item));
    this.conditionalOperator = conditionalOperator;
  }
}
