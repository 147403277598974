<template>
  <v-dialog v-model="dialog" persistent width="70vw">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="newNarrative"
        color="primary"
        outlined
        rounded
        small
        v-on="on"
        @click="dialog = true"
        ><v-icon>mdi-plus</v-icon>New Narrative</v-btn
      >

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            v-if="!newNarrative"
            small
            class="mr-2"
            v-on="on"
            @click="dialog = true"
            >mdi-pencil</v-icon
          >
        </template>
        <span>Edit Narrative</span>
      </v-tooltip>
    </template>

    <v-card v-if="dialog">
      <validation-observer ref="form" v-slot="{ invalid }">
        <v-card-title> Narrative </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="px-6">
          <v-row class="mb-4 align-center">
            <v-col cols="6">
              <v-autocomplete
                v-model="selectedStub"
                :items="stubsList"
                item-text="representation"
                item-value="representation"
                placeholder="Select a stub"
                outlined
                solo
                flat
                dense
                hide-details
              ></v-autocomplete>
            </v-col>

            <v-col cols="3">
              <v-btn
                @click="insertStub"
                small
                depressed
                color="primary"
                :dark="Boolean(selectedStub)"
                :disabled="!Boolean(selectedStub)"
                >Insert stub</v-btn
              >
            </v-col>
          </v-row>
          <form-field
            :field="textEditor"
            @fieldChange="onNarrativeTextChange"
          ></form-field>

          <v-divider role="presentation" class="my-6"></v-divider>

          <span class="subheading">Rendering Configuration</span>
          <conditional-rendering-field
            :renderingItem="rendering"
            @renderingChange="onRenderingChange"
          ></conditional-rendering-field>

          <v-divider role="presentation" class="my-6"></v-divider>

          <v-checkbox
            label="Is Header (only for page 2+)"
            v-model="isHeader"
            dense
            hide-details
          />
          <v-checkbox label="Is Footer" v-model="isFooter" dense hide-details />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="clear"> Cancel </v-btn>
          <v-btn color="primary" text :disabled="invalid" @click="save">
            Save
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import ConditionalRenderingField from "./conditional-rendering-field.vue";
import Rendering from "../../objs/Rendering";
import FormField from "../../objs/Shared/FormField";
export default {
  components: {
    ConditionalRenderingField,
  },
  props: ["newNarrative", "narrative"],
  data() {
    return {
      dialog: false,
      narrativeText: this.narrative?.text || "",
      rendering: this.narrative
        ? {
            ...this.narrative?.rendering,
            renderingConfigs: [...this.narrative?.rendering.renderingConfigs],
          }
        : new Rendering(),
      selectedStub: null,
      isHeader: this.narrative?.isHeader || false,
      isFooter: this.narrative?.isFooter || false,
    };
  },
  computed: {
    sequence: function () {
      return this.narrative?.sequence || this.narratives.length + 1;
    },
    narratives: function () {
      return this.$store.state.templatesModule.narratives;
    },
    textEditor: function () {
      return new FormField({
        label: "Write your narrative text here...",
        value: this.narrativeText,
        componentName: "text-editor-with-table",
      });
    },
    templateId: function () {
      return this.$store.getters["templatesModule/getSelectedTemplateId"];
    },
    stubsList: function () {
      return this.$store.getters["templatesModule/getStubsList"];
    },
  },
  methods: {
    onRenderingChange: function (selection) {
      this.rendering = selection;
    },
    onNarrativeTextChange: function (value) {
      this.narrativeText = value;
    },
    insertStub: function () {
      this.narrativeText += `{{${this.selectedStub}}}`;
    },
    save: async function () {
      this.$store.commit("uxModule/setShowLoader", true);
      let success = false;
      if (this.newNarrative) {
        success = await this.$store.dispatch("templatesModule/addNarrative", {
          templateId: this.templateId,
          sequence: this.sequence,
          text: this.narrativeText,
          rendering: this.rendering,
          isHeader: this.isHeader,
          isFooter: this.isFooter,
        });
      } else {
        success = await this.$store.dispatch(
          "templatesModule/updateNarrative",
          {
            narrativeBlockId: this.narrative.narrativeBlockId,
            templateId: this.templateId,
            sequence: this.sequence,
            text: this.narrativeText,
            rendering: this.rendering,
            isHeader: this.isHeader,
            isFooter: this.isFooter,
          }
        );
      }
      this.$store.commit("uxModule/setShowLoader", false);

      if (!success) {
        this.$store.commit("uxModule/setSnackbarMsg", "Error saving narrative");
        this.$store.commit("uxModule/setShowSnackbar", true);
      }

      this.clear();
    },
    clear: function () {
      this.$refs.form.reset();
      this.dialog = false;
      this.narrativeText = this.narrative?.text || "";
      this.rendering = this.narrative
        ? {
            ...this.narrative?.rendering,
            renderingConfigs: [...this.narrative?.rendering.renderingConfigs],
          }
        : new Rendering();
      this.selectedStub = null;
    },
  },
  watch: {
    isHeader() {
      if (this.isHeader) this.isFooter = false;
    },
    isFooter() {
      if (this.isFooter) this.isHeader = false;
    },
  },
};
</script>
