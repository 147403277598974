<template>
  <v-data-table
    :headers="headers"
    :items="stubs"
    group-by="familyLabel"
    :custom-sort="customSort"
    :items-per-page="-1"
    hide-default-footer
    class="elevation-1 primary--text"
    :key="stubs.length"
  >
    <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
      <th :colspan="headers.length">
        <v-btn @click="toggle" small icon :ref="group">
          <v-icon v-if="isOpen">mdi-minus</v-icon>
          <v-icon v-else>mdi-plus</v-icon>
        </v-btn>
        <span class="mx-2 text-subtitle-1 font-weight-bold">{{ group }}</span>
      </th>
    </template>
    <template v-slot:[`item.questionLabel`]="{ item }">
      <span v-sanitize-html="item.questionLabel"></span>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <stub-modal :stub="item"></stub-modal>
      <v-icon small @click="deleteStub(item)">mdi-delete</v-icon>
      <confirm-modal ref="confirm"></confirm-modal>
    </template>
  </v-data-table>
</template>
<script>
import StubModal from "./stub-modal.vue";
import ConfirmModal from "../Shared/confirm-modal.vue";
export default {
  components: {
    StubModal,
    ConfirmModal,
  },
  data() {
    return {
      headers: [
        { text: "Question", value: "questionLabel" },
        { text: "Name", value: "representation" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    stubs: function () {
      return this.$store.state.templatesModule.stubs.sort(
        (a, b) =>
          a.family?.sequence - b.family?.sequence ||
          a.question?.sequence - b.question?.sequence
      );
    },
  },
  methods: {
    customSort(items) {
      return items.sort(
        (a, b) =>
          a.family?.sequence - b.family?.sequence ||
          a.question?.sequence - b.question?.sequence
      );
    },
    getFamily: function (familyId) {
      return this.$store.getters["templatesModule/getFamily"](familyId);
    },
    deleteStub: async function (stub) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete this stub?"
        )
      ) {
        await this.$store.dispatch("templatesModule/deleteStub", {
          narrativeStubId: stub.narrativeStubId,
        });
      }
    },
  },
};
</script>
