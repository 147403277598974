<template>
  <validation-provider :rules="validations" v-slot="{ errors }">
    <label v-sanitize-html="field.label"></label>
    <v-text-field
      :value="value"
      :disabled="readonly"
      @input="onInput"
      :outlined="outlined"
      :error-messages="errors"
      background-color="#FFFFFF"
      color="#005c7b"
      class="mt-0"
    ></v-text-field>
  </validation-provider>
</template>

<script>
export default {
  props: ["field", "value", "readonly", "outlined", "validations"],
  data() {
    return {
      debounce: "",
    };
  },
  methods: {
    onInput: function (event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        this.$emit("fieldChange", event);
      }, 500);
    },
  },
};
</script>
