<template>
  <v-row dense>
    <v-menu
      v-model="showPicker"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
    >
      <template v-slot:activator="{ on }">
        <validation-provider :rules="validations" v-slot="{ errors }">
          <v-text-field
            :value="formattedDateValue"
            :label="field.label"
            clearable
            @click:clear="clearDate"
            prepend-inner-icon="mdi-calendar"
            readonly
            :error-messages="errors"
            v-on="on"
            color="#005c7b"
            outlined
            dense
            hide-details
          ></v-text-field>
        </validation-provider>
      </template>
      <v-date-picker
        :value="dateFormattedForPicker"
        @input="onDateChange"
        type="month"
        multiple
        no-title
      >
      </v-date-picker>
    </v-menu>
  </v-row>
</template>
<script>
export default {
  props: ["field", "value", "validations"],
  data: () => ({
    showPicker: false,
    selectedDates: [],
  }),
  mounted() {
    this.selectedDates = this.value.map((item) => {
      if (Date.parse(item)) {
        return new Date(item);
      }
    });
  },
  computed: {
    formattedDateValue: function () {
      if (!this.dateValueEmpty) {
        let options = {
          year: "numeric",
          month: "short",
        };
        return this.selectedDates.map((item) =>
          item.toLocaleString("en-US", options)
        );
      }
      return "";
    },
    dateValueEmpty: function () {
      return this.selectedDates.length === 0;
    },
    dateFormattedForPicker: function () {
      if (!this.dateValueEmpty) {
        return this.selectedDates.map((item) =>
          item.toISOString().substr(0, 7)
        );
      }
      return [];
    },
  },
  methods: {
    onDateChange: function (event) {
      this.showDatePicker = false;
      let dates = event.map((item) => {
        const [year, month] = item.split("-");
        return new Date(year, month - 1);
      });
      this.selectedDates = dates;
      this.$emit("fieldChange", dates);
    },
    clearDate: function () {
      this.selectedDates = [];
      this.$emit("fieldChange", []);
    },
  },
};
</script>
