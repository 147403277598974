<template>
  <v-container class="mb-2">
    <v-row class="my-3">
      <label>Options:</label>

      <v-btn icon x-small @click="addOption" color="primary">
        <v-icon> mdi-plus-circle </v-icon>
      </v-btn>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="options"
      sort-by="sequence"
      v-sortable-data-table
      @sorted="saveOrder"
      item-key="questionOptionId"
      :items-per-page="-1"
      hide-default-header
      hide-default-footer
      no-data-text="No options added"
      class="primary--text"
      :key="options.length"
    >
      <template v-slot:[`item.option`]="{ item, index }">
        <form-field
          :field="simpleTextEditor(item)"
          :validations="validations"
          @fieldChange="updateOption(index, item, $event)"
          class="pa-3"
        ></form-field>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          :disabled="options.length < 2"
          icon
          small
          @click="deleteOption(item)"
          color="primary"
          ><v-icon small> mdi-delete</v-icon></v-btn
        >
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import QuestionOption from "../../objs/QuestionOption";
import { required } from "../../plugins/vee-validate/validation";
import FormField from "../../objs/Shared/FormField";
import Sortable from "sortablejs";

export default {
  props: ["question"],
  data() {
    return {
      options: [],
      validations: { required: true },
      headers: [
        { text: "Sequence", value: "sequence" },
        { text: "Option", value: "option" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  mounted() {
    if (Boolean(this.question)) {
      this.options = [
        ...this.question?.options.sort((a, b) => a.sequence - b.sequence),
      ];
    } else
      this.options = [
        new QuestionOption({
          sequence: 1,
        }),
      ];
  },
  computed: {
    lastSequence() {
      return Math.max(...this.options.map((x) => x.sequence), 0);
    },
  },
  methods: {
    simpleTextEditor: function (option) {
      return new FormField({
        value: option.option,
        componentName: "simple-text-editor",
      });
    },
    optionsChange: function () {
      this.$emit("optionsChange", this.options);
    },
    updateOption: function (index, option, newValue) {
      this.options.splice(
        index,
        1,
        new QuestionOption({ ...option, option: newValue })
      );
      this.optionsChange();
    },
    addOption: function () {
      this.options.push(
        new QuestionOption({
          questionId: this.question?.questionId,
          sequence: this.lastSequence + 1,
        })
      );
      this.optionsChange();
    },
    deleteOption: function (item) {
      this.options = this.options
        .filter((x) => x != item)
        .map((x, i) => new QuestionOption({ ...x, sequence: i + 1 }));
      this.optionsChange();
    },
    saveOrder(event) {
      const movedItem = this.options.splice(event.oldIndex, 1)[0];
      this.options.splice(event.newIndex, 0, movedItem);

      // reorder all options
      this.options = this.options
        .map((x, i) => new QuestionOption({ ...x, sequence: i + 1 }))
        .sort((a, b) => a.sequence - b.sequence);

      this.optionsChange();
    },
  },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit("sorted", event);
          },
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      },
    },
  },
};
</script>
