import PredefinedProperty from "../../objs/PredefinedProperty";
import Service from "../../objs/Service";
import Component from "../../objs/Component";
import Mandate from "../../objs/Mandate";
import Plan from "../../objs/Plan";
import ReportType from "../../objs/ReportType";
import Provider from "../../objs/Provider";
import * as api from "../Services/api.js";

export const state = {
  programs: [],
  services: [],
  components: [],
  predefinedProperties: [],
  providers: [],
  reportTypes: [],
  mandates: [],
  plans: [],
  flags: [],
  documentFormats: [],
};

export const mutations = {
  SET_PROGRAMS(state, payload) {
    state.programs = payload;
  },
  SET_SERVICES(state, payload) {
    state.services = payload.map((item) => new Service(item));
  },
  SET_COMPONENTS(state, payload) {
    state.components = payload.map((item) => new Component(item));
  },
  SET_PREDEFINED_PROPERTIES(state, payload) {
    state.predefinedProperties = payload.map((item) => new PredefinedProperty(item));
  },
  SET_PROVIDERS(state, payload) {
    state.providers = payload.map((item) => new Provider(item));
  },
  SET_REPORT_TYPES(state, payload) {
    state.reportTypes = payload.map((item) => new ReportType(item));
  },
  SET_MANDATES(state, payload) {
    state.mandates = payload.map((item) => new Mandate(item));
  },
  SET_PLANS(state, payload) {
    state.plans = payload.map((item) => new Plan(item));
  },
  SET_FLAGS(state, payload) {
    state.flags = payload;
  },
  SET_DOCUMENT_FORMATS(state, payload) {
    state.documentFormats = payload;
  },
};

export const getters = {
  component: (state) => (componentId) => {
    return state.components.find((item) => item.componentId == componentId);
  },
  reportTypes: (state) => (program) => {
    return state.reportTypes.filter((x) => {
      return x.program.code == program;
    });
  },
};

export const actions = {
  async getAllOptions({ commit }) {
    return await api.get("/options/narrativesOptions").then((response) => {
      if (response.success) {
        commit("SET_PROGRAMS", response.data?.programs);
        commit("SET_SERVICES", response.data?.services);
        commit("SET_COMPONENTS", response.data?.components);
        commit("SET_PREDEFINED_PROPERTIES", response.data?.predefinedProperties);
        commit("SET_PROVIDERS", response.data?.providers);
        commit("SET_REPORT_TYPES", response.data?.reportTypes);
        commit("SET_MANDATES", response.data?.mandates);
        commit("SET_PLANS", response.data?.plans);
        commit("SET_FLAGS", response.data?.flags);
        commit("SET_DOCUMENT_FORMATS", response.data?.documentFormats);
      }
    });
  },
  async getProvidersForClient({}, data) {
    return await api
      .getQuery("/provider/getProvidersForClient", data)
      .then((response) => {
        if (response.success) {
          return response.data.map((item) => new Provider(item));
        }
        return [];
      })
      .catch((error) => {
        return false;
      });
  },
  async getGoalsMatchingNoIEPPlanReport({}) {
    return await api
      .post("/goal/exportGoalsMatchingNoIEPPlan")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return false;
      });
  },
  async getGoalsMatchingNoEIPlanReport({}) {
    return await api
      .post("/goal/exportGoalsMatchingNoEIPlan")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return false;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
