<template>
  <v-menu>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <span v-on="{ ...tooltip }">
            <v-icon
              v-bind="attrs"
              v-on="{ ...menu }"
              class="pa-2"
              :disabled="disabled"
            >
              {{ icon }}
            </v-icon>
          </span>
        </template>
        <span>{{ buttonText }}</span>
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item v-for="option in options" :key="option.buttonText">
        <toolbar-button
          :buttonText="option.buttonText"
          :icon="option.icon"
          :isActive="option.isActive"
          @onClick="option.onClick"
        />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import ToolbarButton from "./toolbar-button.vue";
export default {
  props: ["buttonText", "icon", "options", "disabled"],
  components: { ToolbarButton },
};
</script>
