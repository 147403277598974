<template>
  <v-dialog v-model="dialog" persistent width="70vw">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="isNewNarrativeStatement"
        color="primary"
        outlined
        rounded
        small
        v-on="on"
        @click="dialog = true"
        ><v-icon>mdi-plus</v-icon>New Statement</v-btn
      >

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            v-if="!isNewNarrativeStatement"
            small
            class="mr-2"
            v-on="on"
            @click="dialog = true"
            >mdi-pencil</v-icon
          >
        </template>
        <span>Edit Statement</span>
      </v-tooltip>
    </template>

    <v-card v-if="dialog">
      <validation-observer ref="form" v-slot="{ invalid }">
        <v-card-title> Narrative Statement</v-card-title>
        <v-divider></v-divider>

        <v-card-text class="px-6">
          <validation-provider rules="required" v-slot="{ errors }">
            <v-select
              v-model="type"
              :items="types"
              label="Statement Type"
              dense
              :error-messages="errors"
            ></v-select>
          </validation-provider>

          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field
              v-if="type == 'Content'"
              v-model="content"
              :error-messages="errors"
            ></v-text-field>

            <v-autocomplete
              v-if="type == 'Question'"
              v-model="question"
              :items="questionsList"
              :item-text="(item) => `${item.sequence}. ${item.label}`"
              return-object
              :error-messages="errors"
            >
              <template v-slot:selection="{ item }">
                <span
                  v-sanitize-html="`${item.sequence}. ${item.label}`"
                ></span>
              </template>
              <template v-slot:item="{ item }">
                <span
                  v-sanitize-html="`${item.sequence}. ${item.label}`"
                ></span>
              </template>
            </v-autocomplete>

            <v-autocomplete
              v-if="type == 'Stub'"
              v-model="stub"
              :items="stubsList"
              item-text="representation"
              return-object
              :error-messages="errors"
            ></v-autocomplete>
          </validation-provider>

          <v-divider role="presentation" class="my-6"></v-divider>

          <validation-provider rules="required" v-slot="{ errors }">
            <v-textarea
              v-model="instructions"
              label="Instructions"
              outlined
              :error-messages="errors"
            ></v-textarea>
          </validation-provider>

          <v-text-field v-model="prefix" label="Prefix"></v-text-field>
          <v-text-field v-model="suffix" label="Suffix"></v-text-field>

          <v-divider role="presentation" class="my-6"></v-divider>

          <span class="subheading">Rendering Configuration</span>
          <conditional-rendering-field
            :renderingItem="rendering"
            @renderingChange="onRenderingChange"
          ></conditional-rendering-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="clear"> Cancel </v-btn>
          <v-btn color="primary" text :disabled="invalid" @click="save">
            Save
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import ConditionalRenderingField from "./conditional-rendering-field.vue";
import Rendering from "../../objs/Rendering";
export default {
  components: {
    ConditionalRenderingField,
  },
  props: ["isNewNarrativeStatement", "narrativeStatement", "narrativeSectionId"],
  data() {
    return {
      dialog: false,
      instructions: this.narrativeStatement?.instructions || "",
      prefix: this.narrativeStatement?.prefix || "",
      suffix: this.narrativeStatement?.suffix || "",
      types: ["Content", "Question", "Stub"],
      type: this.narrativeStatement?.type || "",
      content: this.narrativeStatement?.content || "",
      question: this.narrativeStatement?.question || null,
      stub: this.narrativeStatement?.stub || null,
      rendering: this.narrativeStatement
        ? {
            ...this.narrativeStatement?.rendering,
            renderingConfigs: [
              ...this.narrativeStatement?.rendering.renderingConfigs,
            ],
          }
        : new Rendering(),
    };
  },
  computed: {
    sequence: function () {
      return (
        this.narrativeStatement?.sequence || this.narrativeStatements.length + 1
      );
    },
    narrativeStatements: function () {
      return this.$store.state.templatesModule.narrativeStatements;
    },
    templateId: function () {
      return this.$store.getters["templatesModule/getSelectedTemplateId"];
    },
    questions: function () {
      return this.$store.state.templatesModule.questions.sort(
        (a, b) =>
          a.family?.sequence - b.family?.sequence || a.sequence - b.sequence
      );
    },
    questionsList: function () {
      return this.questions.reduce((group, question) => {
        let match = group.find((item) => item.familyId === question.familyId);
        if (!match) {
          group.push({ header: question.family?.label });
        }
        group.push(question);

        return group;
      }, []);
    },
    stubsList: function () {
      return this.$store.state.templatesModule.predefinedStubs;
    },
  },
  methods: {
    onRenderingChange: function (selection) {
      this.rendering = selection;
    },
    save: async function () {
      this.dialog = false;
      this.$store.commit("uxModule/setShowLoader", true);
      let success = false;
      if (this.isNewNarrativeStatement) {
        success = await this.$store.dispatch(
          "templatesModule/addNarrativeStatement",
          {
            narrativeSectionId: this.narrativeSectionId,
            sequence: this.sequence,
            instructions: this.instructions,
            prefix: this.prefix,
            suffix: this.suffix,
            content: this.type == "Content" ? this.content : "",
            questionId:
              this.type == "Question" ? this.question.questionId : null,
            predefinedNarrativeStubId:
              this.type == "Stub" ? this.stub.predefinedNarrativeStubId : null,
            rendering: this.rendering,
          }
        );
      } else {
        success = await this.$store.dispatch(
          "templatesModule/updateNarrativeStatement",
          {
            narrativeStatementId: this.narrativeStatement.narrativeStatementId,
            narrativeSectionId: this.narrativeSectionId,
            sequence: this.sequence,
            instructions: this.instructions,
            prefix: this.prefix,
            suffix: this.suffix,
            content: this.type == "Content" ? this.content : "",
            questionId:
              this.type == "Question" ? this.question.questionId : null,
            predefinedNarrativeStubId:
              this.type == "Stub" ? this.stub.predefinedNarrativeStubId : null,
            rendering: this.rendering,
          }
        );
      }
      this.$store.commit("uxModule/setShowLoader", false);

      if (!success) {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Error saving narrative statement"
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }

      this.clear();
    },
    clear: function () {
      this.dialog = false;
      this.instructions = this.narrativeStatement?.instructions || "";
      this.prefix = this.narrativeStatement?.prefix || "";
      this.suffix = this.narrativeStatement?.suffix || "";
      this.content = this.narrativeStatement?.content || "";
      this.question = this.narrativeStatement?.question || null;
      this.stub = this.narrativeStatement?.stub || null;
      this.rendering = this.narrativeStatement
        ? {
            ...this.narrativeStatement?.rendering,
            renderingConfigs: [
              ...this.narrativeStatement?.rendering.renderingConfigs,
            ],
          }
        : new Rendering();
    },
  },
};
</script>
