export const Comparators = {
  EQUALS: "Equals",
  GREATER: "Greater than",
  GREATER_OR_EQUAL: "Greater than or equals",
  LESS: "Less than",
  LESS_OR_EQUAL: "Less than or equals",
  BETWEEN: "Between (inclusive)",
  CONTAINS_ANY: "Contains any of the following options",
  MATCHES_ALL_ONLY: "Matches all, and only, these options",
  CONTAINS_ALL: "Contains at least all of the following options",
  IS_EMPTY: "Is empty",
  IS_NOT_EMPTY: "Is not empty",
};
