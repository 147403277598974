<template>
  <v-card height="84vh">
    <v-card-title style="height: 10%">Narrative</v-card-title>
    <v-card-text style="height: 80%; overflow: auto">
      <v-card-subtitle
        >Please review the report carefully and make any changes inline. When
        you are satisfied with the report, click "Submit For Review".
        <div class="font-weight-bold">
          *** Changes will not be saved if you click Back. ***
        </div></v-card-subtitle
      >
      <form-field
        :field="textEditor"
        @fieldChange="onNoteTextChange"
      ></form-field>
    </v-card-text>
    <v-card-actions style="height: 10%">
      <v-btn color="primary" text rounded small @click="back"
        ><icon icon="arrow-left" small class="mr-2"></icon>Back</v-btn
      >
      <dashboard-action></dashboard-action>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        small
        :dark="!emptyNote"
        :disabled="emptyNote"
        @click="save(() => {})"
      >
        <v-icon small class="mr-2">mdi-content-save-outline</v-icon>
        Save Changes
      </v-btn>
      <v-btn
        color="primary"
        outlined
        small
        :dark="!emptyNote"
        :disabled="emptyNote"
        @click="submit"
      >
        <v-icon small class="mr-2">mdi-arrow-top-right</v-icon>
        Submit For Review
      </v-btn>
      <review-report-action
        v-if="canReview"
        :reportAssignmentId="selectedTemplateNote.reportAssignmentId"
        :large="true"
        @save="save"
        @reviewed="generate"
      ></review-report-action>
    </v-card-actions>
  </v-card>
</template>
<script>
import FormField from "../../objs/Shared/FormField";
import DashboardAction from "./dashboard-action.vue";
import ReviewReportAction from "../ReportAssignments/review-report-action.vue";
import { ReportAssignmentStatus } from "../../objs/ReportAssignmentStatus";
export default {
  components: {
    ReviewReportAction,
    DashboardAction,
  },
  data() {
    return {
      noteText:
        this.$store.state.notesModule.selectedTemplateNote?.noteText || "",
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["securityModule/isAdmin"];
    },
    textEditor: function () {
      return new FormField({
        value: this.noteText,
        componentName: "text-editor-with-table",
      });
    },
    emptyNote: function () {
      return !this.noteText || this.noteText === "<p></p>";
    },
    selectedTemplateNote: function () {
      return this.$store.state.notesModule.selectedTemplateNote;
    },
    canReview: function () {
      return (
        this.isAdmin &&
        this.selectedTemplateNote?.status ==
          ReportAssignmentStatus.READY_FOR_REVIEW &&
        !this.emptyNote
      );
    },
  },
  methods: {
    onNoteTextChange: function (value) {
      this.noteText = value;
    },
    back: function () {
      this.$router.push({ name: "ReviewQuestions" });
    },
    // this calls a callback on success
    save: async function (done) {
      this.$store.commit("uxModule/setShowLoader", true);
      this.$store.commit("uxModule/setLoaderMsg", "Saving changes...");
      let success = await this.$store.dispatch("notesModule/saveTemplateNote", {
        ...this.selectedTemplateNote,
        noteText: this.noteText,
      });
      this.$store.commit("uxModule/setShowLoader", false);
      if (!success) {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Failed to save changes. Please try again later."
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      } else {
        done();
      }
    },
    submit: async function () {
      this.$store.commit("uxModule/setShowLoader", true);
      this.$store.commit("uxModule/setLoaderMsg", "Submitting...");
      let success = await this.$store.dispatch("notesModule/saveTemplateNote", {
        ...this.selectedTemplateNote,
        noteText: this.noteText,
      });
      if (success) {
        success = await this.$store.dispatch(
          "reportsModule/submitReportAssignment",
          { reportAssignmentId: this.selectedTemplateNote.reportAssignmentId }
        );
      }
      this.$store.commit("uxModule/setShowLoader", false);
      if (!success) {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Failed to submit. Please try again later."
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      } else {
        this.$router.push({ name: "ReportsDashboard" });
      }
    },
    generate: async function (isApproved) {
      // if approved, then auto-generate note
      if (isApproved) {
        this.$store.commit("uxModule/setShowLoader", true);
        this.$store.commit("uxModule/setLoaderMsg", "Generating report...");
        await this.$store.dispatch("notesModule/generateTemplateNote", {
          templateNoteId: this.selectedTemplateNote.templateNoteId,
        });
        this.$store.commit("uxModule/setShowLoader", false);
      }

      this.$router.push({ name: "ReportsDashboard" });
    },
  },
};
</script>
