<template>
  <validation-observer>
    <v-data-table
      :headers="headers"
      :items="sortedNarratives"
      v-sortable-data-table
      @sorted="saveOrder"
      item-key="narrativeBlockId"
      :items-per-page="-1"
      hide-default-footer
      class="elevation-1 primary--text"
      :key="narratives.length"
    >
      <template v-slot:[`item.sequence`]="{ item }">
        <validation-provider
          :rules="validations.sequence"
          v-slot="{ validate, valid, errors }"
        >
          <v-text-field
            :value="item.sequence"
            @input="validate"
            @change="
              Boolean(valid) ? updateSequence(item.sequence, $event) : null
            "
            dense
            :error-messages="errors"
          >
            <template v-slot:prepend>
              <v-btn
                icon
                text
                x-small
                :disabled="item.sequence == 1"
                @click="updateSequence(item.sequence, item.sequence - 1)"
                ><v-icon small>mdi-arrow-up-drop-circle-outline</v-icon></v-btn
              ></template
            >
            <template v-slot:append-outer>
              <v-btn
                icon
                text
                x-small
                :disabled="item.sequence >= narratives.length"
                @click="updateSequence(item.sequence, item.sequence + 1)"
                ><v-icon small
                  >mdi-arrow-down-drop-circle-outline</v-icon
                ></v-btn
              >
            </template>
          </v-text-field>
        </validation-provider>
      </template>
      <template v-slot:[`item.text`]="{ item }">
        <span v-sanitize-html="item.text"></span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <narrative-modal :narrative="item"></narrative-modal>
        <v-icon small @click="deleteNarrative(item)">mdi-delete</v-icon>
        <confirm-modal ref="confirm"></confirm-modal>
      </template>
    </v-data-table>
  </validation-observer>
</template>
<script>
import NarrativeModal from "./narrative-modal.vue";
import ConfirmModal from "../Shared/confirm-modal.vue";
import Sortable from "sortablejs";
import {
  required,
  minValue,
  maxValue,
} from "../../plugins/vee-validate/validation";
export default {
  components: {
    NarrativeModal,
    ConfirmModal,
  },
  data() {
    return {
      headers: [
        { text: "Sequence", value: "sequence", width: "130px" },
        { text: "Narrative Text", value: "text" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    narratives: function () {
      return this.$store.state.templatesModule.narratives;
    },
    sortedNarratives: function () {
      return this.narratives.sort((a, b) => a.sequence - b.sequence);
    },
    validations: function () {
      return {
        sequence: {
          required: true,
          minValue: 1,
          maxValue: this.narratives.length,
          numeric: true,
        },
      };
    },
  },
  methods: {
    deleteNarrative: async function (item) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete this narrative?"
        )
      ) {
        this.$store.commit("uxModule/setShowLoader", true);
        await this.$store.dispatch("templatesModule/deleteNarrative", {
          narrativeBlockId: item.narrativeBlockId,
        });
        this.$store.commit("uxModule/setShowLoader", false);
      }
    },
    async saveOrder(event) {
      this.$store.commit("uxModule/setShowLoader", true);
      const movedItem = this.narratives.splice(event.oldIndex, 1)[0];
      this.narratives.splice(event.newIndex, 0, movedItem);
      await this.$store.dispatch("templatesModule/saveNarratives", {
        narratives: this.narratives.map((item, index) => {
          return { ...item, sequence: index + 1 };
        }),
      });
      this.$store.commit("uxModule/setShowLoader", false);
    },
    async updateSequence(oldValue, newValue) {
      await this.saveOrder({
        oldIndex: oldValue - 1,
        newIndex: Number(newValue - 1),
      });
    },
  },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit("sorted", event);
          },
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      },
    },
  },
};
</script>
