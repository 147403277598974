export default class Component {
  componentId?: number | null;
  name?: string | null;
  jsName?: string | null;

  public constructor(params: Component = {} as Component) {
    let { componentId = 0, name = "", jsName = "" } = params;

    this.componentId = componentId;
    this.name = name;
    this.jsName = jsName;
  }
}
