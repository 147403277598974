export default class QuestionOption {
  questionOptionId?: number;
  questionId?: number | null;
  predefinedPropertyId?: number | null;
  option?: string | null;
  sequence?: number | null;

  public constructor(params: QuestionOption = {} as QuestionOption) {
    let {
      questionOptionId = 0,
      questionId = null,
      predefinedPropertyId = null,
      option = "",
      sequence = 0,
    } = params;

    this.questionOptionId = questionOptionId;
    this.questionId = questionId;
    this.predefinedPropertyId = predefinedPropertyId;
    this.option = option;
    this.sequence = sequence;
  }
}
