export default class Goal {
  longTermGoalId?: number | null;
  longTermGoalText?: string | null;
  domainGroup?: string | null;
  shortTermGoals?: string[] | null;

  public constructor(params: Goal = {} as Goal) {
    let {
      longTermGoalId = 0,
      longTermGoalText = "",
      domainGroup = "",
      shortTermGoals = [],
    } = params;

    this.longTermGoalId = longTermGoalId;
    this.longTermGoalText = longTermGoalText;
    this.domainGroup = domainGroup;
    this.shortTermGoals = shortTermGoals;
  }
}
