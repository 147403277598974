import { formatShortDate, formatTime } from "../utils/format-utils";

export default class Note {
  reportAssignmentNoteId?: number;
  reportAssignmentId?: number | null;
  noteText?: string | null;
  createdAt?: Date | null;
  userId?: number | null;
  firstName?: string | null;
  lastName?: string | null;

  public constructor(params: Note = {} as Note) {
    let {
      reportAssignmentNoteId = 0,
      reportAssignmentId = 0,
      noteText = "",
      createdAt = null,
      userId = null,
      firstName = "",
      lastName = "",
    } = params;
    this.reportAssignmentNoteId = reportAssignmentNoteId;
    this.reportAssignmentId = reportAssignmentId;
    this.noteText = noteText;
    this.createdAt = createdAt;
    this.userId = userId;
    this.firstName = firstName;
    this.lastName = lastName;
  }

  get formattedDate() {
    return formatShortDate(this.createdAt) + " " + formatTime(this.createdAt);
  }

  get name() {
    if (this.firstName && this.lastName)
      return this.firstName + " " + this.lastName;
  }
}
