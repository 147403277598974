<template>
  <form-field
    :field="formField"
    :readonly="false"
    :validations="validations"
    @fieldChange="fieldChange"
  ></form-field>
</template>

<script>
import FormField from "../../objs/Shared/FormField";
import {
  required,
  requiredTrue,
  minLength,
  maxLength,
  minValue,
  maxValue,
  alphaExtra,
  alphaNumExtra,
  numeric,
  email,
  phoneNumber,
  DistinctResponse,
} from "../../plugins/vee-validate/validation";

export default {
  props: ["groupQuestion"],
  computed: {
    validations: function () {
      if (this.groupQuestion) {
        // map question validation properties to vee-validate plugin properties
        return {
          ...(this.groupQuestion.validationRequired && { required: true }),
          ...(this.groupQuestion.validationRequiredTrue && {
            requiredTrue: true,
          }),
          ...(this.groupQuestion.validationMinLength && {
            minLength: this.groupQuestion.validationMin,
          }),
          ...(this.groupQuestion.validationMaxLength && {
            maxLength: this.groupQuestion.validationMax,
          }),
          ...(this.groupQuestion.validationMinValue && {
            minValue: this.groupQuestion.validationMin,
          }),
          ...(this.groupQuestion.validationMaxValue && {
            maxValue: this.groupQuestion.validationMax,
          }),
          ...(this.groupQuestion.validationAlpha && { alphaExtra: true }),
          ...(this.groupQuestion.validationAlphaNumeric && {
            alphaNumExtra: true,
          }),
          ...(this.groupQuestion.validationNumeric && { double: true }),
          ...(this.groupQuestion.validationEmail && { email: true }),
          ...(this.groupQuestion.validationPhoneNumber && {
            phoneNumber: true,
          }),
          ...(this.groupQuestion.validationDistinct && {
            DistinctResponse: true,
          }),
        };
      }

      return {};
    },
    value: function () {
      return this.groupQuestion.childQuestions.map((item) => ({
        questionId: item.questionId,
        label: item.label,
        selections:
          this.$store.getters["notesModule/getQuestionResponseValue"](
            item.questionId || ""
          ) || [],
      }));
    },
    formField: function () {
      return new FormField({
        label: this.groupQuestion.label,
        value: this.value,
        componentName: this.groupQuestion.componentName,
        options: this.groupQuestion.options,
        itemText: "option",
        comparatorValue: "questionOptionId",
        outlined: true,
        column: this.groupQuestion.columnLayout,
      });
    },
    templateNoteId() {
      return this.$store.state.notesModule.selectedTemplateNote?.templateNoteId;
    },
  },
  methods: {
    questionResponseId(questionId) {
      return this.$store.getters["notesModule/getQuestionResponse"](
        questionId || ""
      )?.questionResponseId;
    },
    async fieldChange(event) {
      await this.$store.dispatch("notesModule/setQuestionResponse", {
        questionResponseId: this.questionResponseId(event.questionId),
        templateNoteId: this.templateNoteId,
        questionId: event.questionId,
        selections: event.selections,
      });
    },
  },
};
</script>
