<template>
  <span>
    <v-radio-group
      v-if="showParamTypesOptions"
      v-model="selectedParamType"
      dense
      hide-details
      row
      @change="onParamTypeChange"
    >
      <v-radio
        v-for="type in paramTypes"
        :key="type.value"
        :label="type.text"
        :value="type.value"
      ></v-radio>
    </v-radio-group>
    <form-field
      :field="paramField"
      :validations="validations"
      @fieldChange="fieldChange"
      key="form-field"
      class="mx-8"
    ></form-field>
  </span>
</template>
<script>
import { Validation } from "../../objs/Validation";
import { FieldTypes } from "../../objs/Shared/FieldTypes";
import FormField from "../../objs/Shared/FormField";
import { required } from "../../plugins/vee-validate/validation";
export default {
  props: ["rule", "minNum", "maxNum"],
  data() {
    return {
      paramTypes: [
        { text: "Numeric", value: FieldTypes.NUMERIC_FIELD },
        { text: "Date", value: FieldTypes.DATE_PICKER },
      ],
      selectedParamType: FieldTypes.NUMERIC_FIELD,
      value: "",
      validations: { required: true },
    };
  },
  mounted() {
    if ([Validation.MinValue, Validation.MinLength].includes(this.rule)) {
      this.value = this.minNum;
    } else if ([Validation.MaxValue, Validation.MaxLength].includes(this.rule)) {
      this.value = this.maxNum;
    }
  },
  computed: {
    showParamTypesOptions: function () {
      return [Validation.MinValue, Validation.MaxValue].includes(this.rule);
    },
    paramField: function () {
      return new FormField({
        label: "Enter Value",
        value: this.value,
        componentName: this.selectedParamType,
        outlined: true,
      });
    },
  },
  methods: {
    onParamTypeChange: function () {
      this.value = "";
    },
    fieldChange: function (event) {
      this.value = event;
      this.$emit("paramChange", this.value);
    },
  },
};
</script>