<template>
  <v-container>
    <div class="text-h6 primary--text">
      Sorry, you do not have permission to access this page.
    </div>
    <div class="text-subtitle-2 py-4">
      <span v-if="Boolean(roles)"
        >Details: The {{ pageName }} requires user role: {{ roles }}.</span
      >
      For help, please contact support@omniuserportal.com.
    </div>
    <a @click="$router.back()" class="text-decoration-underline">Go Back</a>
    <a :href="dashboard" class="text-decoration-underline mx-3">Dashboard</a>
  </v-container>
</template>
<script>
import { formatListOr } from "../../utils/format-utils";
export default {
  computed: {
    pageName: function () {
      return this.$route.query.page && this.$route.query.page.concat(" page");
    },
    roles: function () {
      return this.$route.query.roles && formatListOr(this.$route.query.roles);
    },
    dashboard: function () {
      return process.env.VUE_APP_DASHBOARD;
    },
  },
};
</script>
