<template>
  <div>
    <families-table class="mb-3"></families-table>
    <v-card-actions class="justify-center">
      <family-modal :newFamily="true"></family-modal>
      <clone-modal></clone-modal>
    </v-card-actions>
  </div>
</template>
<script>
import FamiliesTable from "./families-table";
import FamilyModal from "./family-modal.vue";
import CloneModal from "./clone-modal.vue";
export default {
  components: {
    FamiliesTable,
    FamilyModal,
    CloneModal,
  },
};
</script>
