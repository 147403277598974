<template>
  <v-data-table
    :headers="headers"
    :items="assignments"
    dense
    hide-default-footer
    multi-sort
    :sort-by="['service', 'startDate']"
    :sort-desc="[false, true]"
  >
  </v-data-table>
</template>
<script>
export default {
  props: ["assignments"],
  data() {
    return {
      headers: [
        { text: "Mandate", value: "mandate" },
        { text: "Provider", value: "providerName" },
        { text: "Start Date", value: "formattedStartDate" },
        { text: "End Date", value: "formattedEndDate" },
      ],
    };
  },
};
</script>
