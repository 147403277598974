<template>
  <v-dialog v-model="dialog" width="400">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="large"
        color="primary"
        outlined
        small
        v-on="on"
        class="mx-2"
        @click="dialog = true"
      >
        <v-icon small class="mr-2">mdi-check</v-icon>
        Review Note
      </v-btn>
      <v-tooltip v-else bottom v-on="on">
        <template v-slot:activator="{ on }">
          <v-btn x-small text v-on="on" @click="dialog = true">
            <v-icon small>mdi-check</v-icon>
          </v-btn>
        </template>
        <span> Review</span>
      </v-tooltip>
    </template>

    <v-card class="primary--text">
      <v-card-title
        >Review Report
        <v-spacer></v-spacer>
        <v-btn icon @click="clear">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle
        >This action will overwrite any existing review.</v-card-subtitle
      >
      <v-card-text>
        <label>Comments:</label>
        <v-textarea v-model="comments" auto-grow rows="2" outlined></v-textarea>
        <v-checkbox
          v-model="sendEmail"
          label="Send email to provider"
          dense
          hide-details
        ></v-checkbox>
        <v-checkbox
          v-model="ccReviewer"
          label="Send myself copy"
          :disabled="!sendEmail"
          dense
          hide-details
        ></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          :dark="!loading && Boolean(comments)"
          text
          :disabled="loading || !Boolean(comments)"
          @click="submitReview(false)"
          >Reject</v-btn
        >
        <v-btn
          color="primary"
          :dark="!loading"
          text
          :disabled="loading"
          @click="submitReview(true)"
          >Approve</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["reportAssignmentId", "large"],
  data() {
    return {
      dialog: false,
      comments: "",
      sendEmail: true,
      ccReviewer: true,
      loading: false,
    };
  },
  methods: {
    submitReview: async function (isApproved) {
      this.loading = true;
      // emits 'save' event with callback param
      this.$emit("save", async () => {
        this.$store.commit("uxModule/setShowLoader", true);
        let message = await this.$store.dispatch(
          "reportsModule/reviewReportAssignment",
          {
            reportAssignmentId: this.reportAssignmentId,
            isApproved: isApproved,
            comments: this.comments,
            sendEmail: this.sendEmail,
            ccReviewer: this.ccReviewer,
          }
        );

        this.loading = false;
        this.clear();
        this.$store.commit("uxModule/setShowLoader", false);

        if (message) {
          // error
          this.$store.commit("uxModule/setSnackbarMsg", message);
          this.$store.commit("uxModule/setShowSnackbar", true);
        } else {
          // success
          this.$emit("reviewed", isApproved);
        }
      });
    },
    clear() {
      this.dialog = false;
      this.comments = "";
      this.sendEmail = true;
      this.ccReviewer = true;
    },
  },
  watch: {
    sendEmail() {
      if (!this.sendEmail) this.ccReviewer = false;
    },
  },
};
</script>
