<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <v-btn
          v-show="!hidden"
          text
          x-small
          :disabled="!fileId"
          v-on="on"
          @click="downloadFile"
        >
          <v-icon small>mdi-download</v-icon>
        </v-btn>
      </span>
    </template>
    <span>Download</span>
  </v-tooltip>
</template>

<script>
import { downloadFileUrl } from "../../utils/download-utils";
export default {
  props: ["fileId", "filename", "hidden"],
  methods: {
    downloadFile: async function () {
      let message =
        "This is read-only document. Any changes will not be saved to this system.";
      this.$store.commit("uxModule/setSnackbarMsg", message);
      this.$store.commit("uxModule/setShowSnackbar", true);

      this.$store.commit("uxModule/setShowLoader", true);
      var fileUrl = await this.$store.dispatch("filesModule/getFileUrl", {
        fileId: this.fileId,
      });
      this.$store.commit("uxModule/setShowLoader", false);

      if (fileUrl) {
        downloadFileUrl(fileUrl, this.filename);
      } else {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Oops! Something went wrong. Please try again later."
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
    },
  },
};
</script>
