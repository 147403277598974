import Rendering from "./Rendering";

export default class Family {
  familyId?: number;
  templateId?: number | null;
  sequence?: number | null;
  label?: string | null;
  renderingId: number;
  rendering?: Rendering | null;

  public constructor(params: Family = {} as Family) {
    let {
      familyId = 0,
      templateId = 0,
      sequence = 0,
      label = "",
      renderingId = 0,
      rendering = new Rendering(),
    } = params;

    this.familyId = familyId;
    this.templateId = templateId;
    this.sequence = sequence;
    this.label = label;
    this.renderingId = renderingId;
    this.rendering = rendering && new Rendering(rendering);
  }
}
