<template>
  <v-container>
    <v-switch
      v-model="activate"
      :label="label"
      color="primary"
      hide-details
      @change="onActivateChange"
      class="mb-6"
    ></v-switch>
    <h5>Click back to make any changes.</h5>
  </v-container>
</template>
<script>
export default {
  data() {
    return { activate: true };
  },
  computed: {
    label: function () {
      return `Activate ${this.templateName} template`;
    },
    templateName: function () {
      return this.$store.state.templatesModule.selectedTemplate?.name;
    },
  },
  methods: {
    onActivateChange: function () {
      this.$emit("activateChange", this.activate);
    },
  },
};
</script>