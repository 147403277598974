import { formatOsis, formatShortDate } from "../utils/format-utils";
import { ReportAssignmentStatus } from "./ReportAssignmentStatus";

export default class ReportAssignmentDetails {
  reportAssignmentId?: number;
  legacyReportAssignmentId?: number | null;
  clientId?: number | null;
  clientName?: string | null;
  clientOsis?: string | null;
  clientNyeisId?: string | null;
  clientDateOfBirth?: Date | null;
  districtId?: number | null;
  schoolId?: number | null;
  schoolName?: string | null;
  teamId?: number | null;
  teamName?: string | null;
  eiStaffingTeam?: string | null;
  planDate?: Date | null;
  endDate?: Date | null;
  service?: string | null;
  isTwelveMonth?: boolean | null;
  providerId?: number | null;
  providerName?: string | null;
  reportTypeId?: number | null;
  reportTypeName?: string | null;
  dueDate?: Date | null;
  nyeisDueDate?: Date | null;
  flag?: string | null;
  status: string;
  templateName?: string | null;
  templateNoteId?: number | null;
  reviewedAt?: Date | null;
  reviewerName?: string | null;
  reviewComments?: string | null;
  hasFile?: boolean | null;
  isStartReportDisabled?: boolean | null;

  public constructor(
    params: ReportAssignmentDetails = {} as ReportAssignmentDetails
  ) {
    let {
      reportAssignmentId = 0,
      legacyReportAssignmentId = null,
      clientId = null,
      clientName = "",
      clientOsis = "",
      clientNyeisId = "",
      clientDateOfBirth = null,
      districtId = null,
      schoolId = null,
      schoolName = "",
      teamId = null,
      teamName = "",
      eiStaffingTeam = "",
      planDate = null,
      endDate = null,
      service = "",
      isTwelveMonth = null,
      providerId = null,
      providerName = "",
      reportTypeId = null,
      reportTypeName = "",
      dueDate = null,
      nyeisDueDate = null,
      flag = "",
      status = "",
      templateName = "",
      templateNoteId = null,
      reviewedAt = null,
      reviewerName = "",
      reviewComments = "",
      hasFile = null,
      isStartReportDisabled = null,
    } = params;

    this.reportAssignmentId = reportAssignmentId;
    this.legacyReportAssignmentId = legacyReportAssignmentId;
    this.clientId = clientId;
    this.clientName = clientName;
    this.clientOsis = clientOsis && formatOsis(clientOsis);
    this.clientNyeisId = clientNyeisId;
    this.clientDateOfBirth = clientDateOfBirth;
    this.districtId = districtId;
    this.schoolId = schoolId;
    this.schoolName = schoolName;
    this.teamId = teamId;
    this.teamName = teamName;
    this.eiStaffingTeam = eiStaffingTeam;
    this.planDate = planDate;
    this.endDate = endDate;
    this.service = service;
    this.isTwelveMonth = isTwelveMonth;
    this.providerId = providerId;
    this.providerName = providerName;
    this.reportTypeId = reportTypeId;
    this.reportTypeName = reportTypeName;
    this.dueDate = dueDate;
    this.nyeisDueDate = nyeisDueDate;
    this.status = status;
    this.flag = flag;
    this.templateName = templateName;
    this.templateNoteId = templateNoteId;
    this.reviewedAt = reviewedAt;
    this.reviewerName = reviewerName;
    this.reviewComments = reviewComments;
    this.hasFile = hasFile;
    this.isStartReportDisabled = isStartReportDisabled;
  }

  get formattedClientDob() {
    return formatShortDate(this.clientDateOfBirth);
  }

  get formattedPlanDate() {
    return formatShortDate(this.planDate);
  }

  get formattedDueDate() {
    return formatShortDate(this.dueDate);
  }

  get formattedNyeisDueDate() {
    return formatShortDate(this.nyeisDueDate);
  }

  get isCurrentPlan() {
    return (
      this.planDate != null &&
      new Date(this.planDate) <= new Date() &&
      (this.endDate == null || new Date(this.endDate) >= new Date())
    );
  }

  get tenOrTwelveMonth() {
    return this.isTwelveMonth ? "12" : "10";
  }

  get hasTemplateAvailable() {
    return Boolean(this.templateName);
  }

  get isTemplateNoteStarted() {
    return Boolean(this.templateNoteId);
  }

  get isTemplateNoteInProgress() {
    return (
      this.isTemplateNoteStarted &&
      [
        ReportAssignmentStatus.IN_PROGRESS,
        ReportAssignmentStatus.DRAFT,
        ReportAssignmentStatus.REJECTED,
      ].includes(this.status)
    );
  }

  get isTemplateNoteReviewable() {
    return (
      this.isTemplateNoteStarted &&
      [ReportAssignmentStatus.READY_FOR_REVIEW].includes(this.status)
    );
  }

  get isCompleted() {
    return [
      ReportAssignmentStatus.ACCEPTED,
      ReportAssignmentStatus.SUBMITTED_TO_BOE,
    ].includes(this.status);
  }

  get isCancelled() {
    return this.status === ReportAssignmentStatus.CANCELLED;
  }

  get isPending() {
    return !this.isCancelled && !this.isCompleted;
  }

  get isReviewed() {
    return (
      [
        ReportAssignmentStatus.ACCEPTED,
        ReportAssignmentStatus.REJECTED,
        ReportAssignmentStatus.SUBMITTED_TO_BOE,
      ].includes(this.status) && Boolean(this.reviewerName)
    );
  }

  get formattedReviewedAt() {
    return formatShortDate(this.reviewedAt);
  }
}
