import QuestionOption from "./QuestionOption";

export default class QuestionResponse {
  questionResponseId: number;
  templateNoteId: number;
  questionId?: number;
  response?: string | null;
  selections?: QuestionOption[];
  imageResponse?: string | null;

  public constructor(params: QuestionResponse = {} as QuestionResponse) {
    let {
      questionResponseId = 0,
      templateNoteId = 0,
      questionId = 0,
      response = "",
      selections = [],
      imageResponse = "",
    } = params;
    this.questionResponseId = questionResponseId;
    this.templateNoteId = templateNoteId;
    this.questionId = questionId;
    this.response = response;
    this.selections =
      selections && selections.map((s) => new QuestionOption(s));
    this.imageResponse = imageResponse;
  }
}
