<template>
  <v-chip
    x-small
    :color="statusDisplay.color"
    label
    text-color="white"
  >
    <v-icon left small>{{ statusDisplay.icon }}</v-icon>
    {{ reportAssignment.status }}
  </v-chip>
</template>
<script>
export default {
  props: ["reportAssignment"],
  computed: {
    statusDisplay() {
      return this.$store.getters["reportsModule/getStatusDisplay"](
        this.reportAssignment.status
      );
    },
  },
};
</script>
