<template>
  <v-list-item @click="exportRoster">
    <v-icon class="mr-2">mdi-download</v-icon>
    Export Roster
  </v-list-item>
</template>

<script>
import { downloadFile } from "../../utils/download-utils";
import { formatDateForFilename } from "../../utils/format-utils";
export default {
  methods: {
    exportRoster: async function () {
      this.$store.commit("uxModule/setShowLoader", true);
      var response = await this.$store.dispatch(
        "reportsModule/getReportAssignmentRoster"
      );
      if (response) {
        await downloadFile(
          response,
          `ReportAssignmentRoster_${formatDateForFilename(new Date())}.csv`
        );
      } else {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Oops! Something went wrong. Please try again later."
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
      this.$store.commit("uxModule/setShowLoader", false);
    },
  },
};
</script>
