<template>
  <div>
    <narratives-table class="mb-3"></narratives-table>
    <v-card-actions class="justify-center">
      <narrative-modal :newNarrative="true"></narrative-modal>
      <clone-modal></clone-modal>
    </v-card-actions>
  </div>
</template>
<script>
import NarrativesTable from "./narratives-table";
import NarrativeModal from "./narrative-modal.vue";
import CloneModal from "./clone-modal.vue";
export default {
  components: {
    NarrativesTable,
    NarrativeModal,
    CloneModal,
  },
  async mounted() {
    await this.$store.dispatch("templatesModule/getPredefinedStubs");
  },
};
</script>
