<template>
  <tiptap-vuetify
    :value="value"
    :placeholder="field.label"
    @input="onChange"
    :extensions="extensions"
    color="#005c7b"
  ></tiptap-vuetify>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  Blockquote,
  HardBreak,
  History,
} from "tiptap-vuetify";
export default {
  components: {
    TiptapVuetify,
  },
  props: ["field", "value"],
  data() {
    return {
      extensions: [
        Bold,
        Italic,
        Underline,
        ListItem,
        BulletList,
        OrderedList,
        HardBreak,
        Blockquote,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        History,
      ],
    };
  },
  methods: {
    onChange: function (event) {
      this.$emit("fieldChange", event);
    },
  },
};
</script>