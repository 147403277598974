<template>
  <validation-provider :rules="validations" v-slot="{ errors }">
    <label v-sanitize-html="field.label"></label>
    <v-text-field
      :value="value"
      :disabled="readonly"
      @change="onChange"
      :error-messages="errors"
      type="number"
      :outlined="outlined"
      background-color="#FFFFFF"
      color="#005c7b"
      class="mt-0"
    ></v-text-field>
  </validation-provider>
</template>

<script>
export default {
  props: ["field", "value", "readonly", "outlined", "validations"],
  methods: {
    onChange(event) {
      this.$emit("fieldChange", Number(event));
    },
  },
};
</script>
