import Question from "./Question";
import store from "../store/index";

export default class NarrativeStub {
  narrativeStubId?: number;
  templateId?: number | null;
  representation?: string | null;
  questionId?: number | null;
  question?: Question | null;

  public constructor(params: NarrativeStub = {} as NarrativeStub) {
    let {
      narrativeStubId = 0,
      templateId = 0,
      representation = "",
      questionId = 0,
      question = null,
    } = params;

    this.narrativeStubId = narrativeStubId;
    this.templateId = templateId;
    this.representation = representation;
    this.questionId = questionId;
    this.question = question ? new Question(question) : null;
  }

  get questionLabel() {
    return this.question?.label;
  }

  get family() {
    return store.getters["templatesModule/getFamilyByQuestion"](
      this.questionId
    );
  }

  get familyId() {
    return this.family?.familyId;
  }
  
  get familyLabel() {
    return this.family?.label;
  }
}
