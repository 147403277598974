<template>
  <div>
    <v-sheet class="sticky">
      <v-toolbar flat color="white">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-card outlined v-if="isAdmin">
          <refresh-assignments-button></refresh-assignments-button>
          <generate-report-assignments-button></generate-report-assignments-button>
          <report-assignment-form></report-assignment-form>
          <more-actions-dropdown></more-actions-dropdown>
        </v-card>
      </v-toolbar>
      <v-toolbar flat color="white" v-if="isAdmin">
        <v-subheader>Filter By</v-subheader>
        <v-col cols="12" sm="2" class="px-0">
          <v-autocomplete
            v-model="clientFilter"
            :items="clients"
            clearable
            label="Client"
            hide-details
            dense
            outlined
            class="mx-1"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="2" class="px-0">
          <v-autocomplete
            v-model="providerFilter"
            :items="providers"
            clearable
            label="Provider"
            hide-details
            dense
            outlined
            class="mx-1"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="2" class="px-0" v-if="programView === 'SEIT'">
          <v-autocomplete
            v-model="teamFilter"
            :items="teams"
            multiple
            clearable
            label="Team"
            hide-details
            dense
            outlined
            class="mx-1"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="1" class="px-0" v-if="programView === 'EIO'">
          <v-select
            v-model="eiStaffingTeamFilter"
            :items="eiStaffingTeams"
            clearable
            label="EI Staffing Team"
            hide-details
            dense
            outlined
            class="mx-1"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="1" class="px-0" v-if="programView === 'EIO'">
          <v-select
            v-model="serviceFilter"
            :items="services"
            multiple
            clearable
            label="Service"
            hide-details
            dense
            outlined
            class="mx-1"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="1" class="px-0">
          <v-autocomplete
            v-model="reportTypeFilter"
            :items="reportTypes"
            clearable
            label="Report Type"
            hide-details
            dense
            outlined
            class="mx-1"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="1" class="px-0">
          <form-field
            :field="monthPicker"
            @fieldChange="dueDateFilter = $event"
            class="mx-1"
          ></form-field>
        </v-col>
        <v-col cols="12" sm="1" class="px-0">
          <v-select
            v-model="statusFilter"
            :items="statuses"
            multiple
            clearable
            label="Status"
            hide-details
            dense
            outlined
            class="mx-1"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="1" class="px-0">
          <v-select
            v-model="flagFilter"
            :items="flags"
            clearable
            label="Flag"
            hide-details
            dense
            outlined
            class="mx-1"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="1" class="px-0">
          <v-switch
            v-model="currentPlansFilter"
            x-small
            hide-details
            class="mx-1"
            :disabled="noCurrentPlan"
          >
            <template v-slot:label>
              <span class="text-body-2">Current Plans only</span></template
            >
          </v-switch>
        </v-col>
        <v-btn text x-small @click="clearFilters">Clear Filters</v-btn>
      </v-toolbar>
    </v-sheet>
    <v-spacer class="py-3"></v-spacer>
    <report-assignments-table
      :reports="filteredReports"
      :isAdmin="isAdmin"
    ></report-assignments-table>
  </div>
</template>

<script>
import GenerateReportAssignmentsButton from "./generate-report-assignments-button.vue";
import RefreshAssignmentsButton from "./refresh-assignments-button.vue";
import ReportAssignmentForm from "./report-assignment-form.vue";
import ReportAssignmentsTable from "./report-assignments-table.vue";
import { formatShortDate } from "../../utils/format-utils";
import { ReportAssignmentStatus } from "../../objs/ReportAssignmentStatus";
import FormField from "../../objs/Shared/FormField";
import MoreActionsDropdown from "./more-actions-dropdown.vue";
export default {
  components: {
    GenerateReportAssignmentsButton,
    RefreshAssignmentsButton,
    ReportAssignmentForm,
    ReportAssignmentsTable,
    MoreActionsDropdown,
  },
  data() {
    return {
      statuses: Object.values(ReportAssignmentStatus),
    };
  },
  computed: {
    programView() {
      return this.$store.state.reportsModule.programView;
    },
    noCurrentPlan() {
      return (
        this.filteredReports.filter((item) => item.isCurrentPlan).length == 0
      );
    },
    isAdmin() {
      return this.$store.getters["securityModule/isAdmin"];
    },
    title() {
      return this.isAdmin ? "All Report Assignments" : "My Report Assignments";
    },
    clients() {
      return this.reportAssignments
        .map((item) => {
          if (
            !this.currentPlansFilter ||
            (this.currentPlansFilter && item.isCurrentPlan)
          ) {
            return {
              text: `${item.clientName} (${formatShortDate(
                item.clientDateOfBirth
              )})`,
              value: item.clientId,
            };
          }
        })
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    providers() {
      return this.reportAssignments
        .map((item) => {
          return {
            text: item.providerName,
            value: item.providerId,
          };
        })
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    teams() {
      var teams = this.reportAssignments
        .filter((item) => item.teamName != null)
        .map((item) => {
          return {
            text: item.teamName,
            value: item.teamId,
          };
        })
        .sort((a, b) => a.text.localeCompare(b.text));

      teams.push({ text: "No Team", value: "none" });
      return teams;
    },
    eiStaffingTeams() {
      return this.reportAssignments.map((item) => item.eiStaffingTeam);
    },
    services() {
      return this.reportAssignments
        .map((item) => item.service)
        .sort((a, b) => a.localeCompare(b));
    },
    reportTypes() {
      return this.$store.getters["optionsModule/reportTypes"](this.programView)
        .map((item) => {
          return {
            text: item.name,
            value: item.reportTypeId,
          };
        })
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    dueDates() {
      return this.reportAssignments
        .sort((a, b) => b.dueDate.localeCompare(a.dueDate))
        .map((item) => item.formattedDueDate);
    },
    flags() {
      return this.$store.state.optionsModule.flags;
    },
    monthPicker: function () {
      return new FormField({
        label: "Due Date(s)",
        value: this.dueDateFilter,
        componentName: "month-picker",
      });
    },
    clientFilter: {
      get() {
        return this.$store.state.reportsModule.filters.clientFilter || "";
      },
      set(value) {
        this.$store.dispatch("reportsModule/setFilters", {
          filterName: "clientFilter",
          filterValue: value,
        });
      },
    },
    providerFilter: {
      get() {
        return this.$store.state.reportsModule.filters.providerFilter || "";
      },
      set(value) {
        this.$store.dispatch("reportsModule/setFilters", {
          filterName: "providerFilter",
          filterValue: value,
        });
      },
    },
    teamFilter: {
      get() {
        return this.$store.state.reportsModule.filters.teamFilter || [];
      },
      set(value) {
        this.$store.dispatch("reportsModule/setFilters", {
          filterName: "teamFilter",
          filterValue: value,
        });
      },
    },
    eiStaffingTeamFilter: {
      get() {
        return (
          this.$store.state.reportsModule.filters.eiStaffingTeamFilter || ""
        );
      },
      set(value) {
        this.$store.dispatch("reportsModule/setFilters", {
          filterName: "eiStaffingTeamFilter",
          filterValue: value,
        });
      },
    },
    serviceFilter: {
      get() {
        return this.$store.state.reportsModule.filters.serviceFilter || [];
      },
      set(value) {
        this.$store.dispatch("reportsModule/setFilters", {
          filterName: "serviceFilter",
          filterValue: value,
        });
      },
    },
    reportTypeFilter: {
      get() {
        return this.$store.state.reportsModule.filters.reportTypeFilter || "";
      },
      set(value) {
        this.$store.dispatch("reportsModule/setFilters", {
          filterName: "reportTypeFilter",
          filterValue: value,
        });
      },
    },
    statusFilter: {
      get() {
        return this.$store.state.reportsModule.filters.statusFilter || [];
      },
      set(value) {
        this.$store.dispatch("reportsModule/setFilters", {
          filterName: "statusFilter",
          filterValue: value,
        });
      },
    },
    dueDateFilter: {
      get() {
        return this.$store.state.reportsModule.filters.dueDateFilter || [];
      },
      set(value) {
        this.$store.dispatch("reportsModule/setFilters", {
          filterName: "dueDateFilter",
          filterValue: value,
        });
      },
    },
    flagFilter: {
      get() {
        return this.$store.state.reportsModule.filters.flagFilter || "";
      },
      set(value) {
        this.$store.dispatch("reportsModule/setFilters", {
          filterName: "flagFilter",
          filterValue: value,
        });
      },
    },
    currentPlansFilter: {
      get() {
        return this.$store.state.reportsModule.filters.currentPlansFilter;
      },
      set(value) {
        this.$store.dispatch("reportsModule/setFilters", {
          filterName: "currentPlansFilter",
          filterValue: value,
        });
      },
    },
    reportAssignments() {
      return this.$store.state.reportsModule.reportAssignments || [];
    },
    filteredReports() {
      let reports = this.reportAssignments;
      if (!this.isAdmin) {
        return reports;
      }
      if (this.providerFilter) {
        reports = reports.filter((t) => t.providerId == this.providerFilter);
      }
      if (this.clientFilter) {
        reports = reports.filter((t) => t.clientId == this.clientFilter);
      }
      if (this.teamFilter.length > 0) {
        reports = reports.filter(
          (t) => this.teamFilter.includes(t.teamId) || t.teamId == null
        );
      }
      if (this.eiStaffingTeamFilter) {
        reports = reports.filter(
          (t) => t.eiStaffingTeam == this.eiStaffingTeamFilter
        );
      }
      if (this.serviceFilter.length > 0) {
        reports = reports.filter((t) => this.serviceFilter.includes(t.service));
      }
      if (this.reportTypeFilter) {
        reports = reports.filter(
          (t) => t.reportTypeId == this.reportTypeFilter
        );
      }
      if (this.statusFilter.length > 0) {
        reports = reports.filter((t) => this.statusFilter.includes(t.status));
      }
      // by month and year
      if (this.dueDateFilter.length) {
        reports = reports.filter((t) =>
          this.dueDateFilter.some((item) => {
            return (
              new Date(t.dueDate).getMonth() == item.getMonth() &&
              new Date(t.dueDate).getFullYear() == item.getFullYear()
            );
          })
        );
      }
      if (this.flagFilter) {
        reports = reports.filter((t) => t.flag == this.flagFilter);
      }
      if (this.currentPlansFilter === true) {
        reports = reports.filter((t) => t.isCurrentPlan);
      }
      if (this.currentPlansFilter === false) {
      }
      return reports;
    },
  },
  methods: {
    formatShortDate,
    clearFilters() {
      this.$store.dispatch("reportsModule/clearFilters");
    },
  },
};
</script>
<style scoped>
.sticky {
  background-color: white;
  position: sticky;
  top: 60px;
  z-index: 1;
}
</style>
