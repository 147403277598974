export class Validation {
  static Required = new Validation("Required", "required");
  static RequiredTrue = new Validation("Required True", "requiredTrue");
  static AlphaExtra = new Validation("Alphabet with Punctuation", "alphaExtra");
  static AlphaNumExtra = new Validation(
    "Alpha Numeric with Punctuation",
    "alphaNumExtra"
  );
  static Email = new Validation("Email", "email");
  static PhoneNumber = new Validation("Phone Number", "phoneNumber");
  static Numeric = new Validation("Numeric", "numeric");
  static Distinct = new Validation("Distinct", "DistinctResponse");
  static MinLength = new Validation("Min Length", "minLength");
  static MaxLength = new Validation("Max Length", "maxLength");
  static MinValue = new Validation("Min Value", "minValue");
  static MaxValue = new Validation("Max Value", "maxValue");

  constructor(name, value) {
    this.name = name;
    this.value = value;
  }
}
