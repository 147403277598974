<template>
  <v-container>
    <v-row no-gutters>
      <v-col v-for="(rule, i) in rules" :key="i" cols="6">
        <v-checkbox
          v-model="selected"
          :value="rule"
          @change="onSelectionChange"
          :label="rule.name"
          hide-details
          dense
          :ripple="false"
          color="primary"
        ></v-checkbox>
        <validations-params-field
          v-if="selected.includes(rule) && paramRules.includes(rule)"
          :rule="rule"
          :minNum="minNum"
          :maxNum="maxNum"
          @paramChange="onParamChange(rule, $event)"
        ></validations-params-field>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { Validation } from "../../objs/Validation";
import ValidationsParamsField from "./validations-params-field";
import Question from "../../objs/Question";
export default {
  components: {
    ValidationsParamsField,
  },
  props: ["question"],
  data() {
    return {
      rules: Object.values(Validation),
      paramRules: [
        Validation.MinLength,
        Validation.MaxLength,
        Validation.MinValue,
        Validation.MaxValue,
      ],
      selected: [],
      minNum: "",
      maxNum: "",
    };
  },
  mounted() {
    this.selected = this.question?.validations || [];
    this.minNum = this.question?.validationMin || "";
    this.maxNum = this.question?.validationMax || "";
  },
  computed: {
    mappedValidations: function () {
      return Question.mapValidations(this.selected, this.minNum, this.maxNum);
    },
  },
  methods: {
    onSelectionChange: function () {
      this.$emit("validationChange", this.mappedValidations);
    },
    onParamChange: function (rule, value) {
      if (rule === Validation.MinLength || rule === Validation.MinValue) {
        this.minNum = value;
      }
      if (rule === Validation.MaxLength || rule === Validation.MaxValue) {
        this.maxNum = value;
      }
      this.onSelectionChange();
    },
  },
};
</script>
