import AINarrative from "./AINarrative";
import NarrativeStatement from "./NarrativeStatement";

export default class NarrativeSection {
  narrativeSectionId?: number;
  aiNarrativeId?: number | null;
  aiNarrative?: AINarrative | null;
  sequence?: number | null;
  name?: string | null;
  description?: string | null;
  instructions?: string | null;
  minWords?: number | null;
  maxWords?: number | null;
  narrativeStatements?: NarrativeStatement[];

  public constructor(params: NarrativeSection = {} as NarrativeSection) {
    let {
      narrativeSectionId = 0,
      aiNarrativeId = 0,
      aiNarrative = null,
      sequence = 0,
      name = "",
      description = "",
      instructions = "",
      minWords = 0,
      maxWords = 0,
      narrativeStatements = new Array<NarrativeStatement>(),
    } = params;

    this.narrativeSectionId = narrativeSectionId;
    this.aiNarrativeId = aiNarrativeId;
    this.aiNarrative = aiNarrative;
    this.sequence = sequence;
    this.name = name;
    this.description = description;
    this.instructions = instructions;
    this.minWords = minWords;
    this.maxWords = maxWords;
    this.narrativeStatements =
      narrativeStatements &&
      narrativeStatements.map((o) => new NarrativeStatement(o));
  }
}
