export const formatShortDate = (value) => {
  if (value) {
    return new Date(value).toLocaleDateString("en-US");
  }
  return "";
};

export const formatLongDate = (value) => {
  if (value) {
    return new Date(value).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }
  return "";
};

export const formatDateForFilename = (value) => {
  return formatShortDate(value).replace(/\//g, "-");
};

export const formatTime = (value) => {
  if (value) {
    return new Date(value).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
  }
  return "";
};

export const getDateFromDisplayTime = (value) => {
  if (value) {
    let [hours, minutes] = value.split(":");
    let dateValue = new Date();
    dateValue.setHours(hours);
    dateValue.setMinutes(minutes);
    return dateValue;
  }
  return "";
};

export const formatMandateResponse = (value) => {
  if (value) {
    return (
      "Service: " +
      value.service +
      " Frequency: " +
      value.frequency +
      " Duration: " +
      value.duration
    );
  }
  return "";
};

export const formatListOr = (value) => {
  const formatter = new Intl.ListFormat("en-US", {
    style: "short",
    type: "disjunction",
  });

  return value && formatter.format(value);
};

export const formatPhone = (value) => {
  if (value && value.length == 10) {
    return value.replace(/(\d{3})(\d{3})(\d{4})/, "($1)-$2-$3");
  }
  return value;
};

export const formatOsis = (value) => {
  return value.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3");
};
