<template>
  <v-card>
    <v-toolbar dense flat class="grey lighten-4" height="auto">
      <div>
        <toolbar-button
          v-for="toolbarButton in toolbarButtons"
          :key="toolbarButton.buttonText"
          :buttonText="toolbarButton.buttonText"
          :icon="toolbarButton.icon"
          :isActive="toolbarButton.isActive"
          @onClick="toolbarButton.onClick"
        />
        <options-toolbar-button
          buttonText="Table Options"
          icon="mdi-table-edit"
          :options="tableOptions"
          :disabled="cannotEditTable"
        >
        </options-toolbar-button>
        <options-toolbar-button
          buttonText="Alignment Options"
          icon="mdi-format-align-justify"
          :options="alignmentOptions"
        >
        </options-toolbar-button>
      </div>
    </v-toolbar>
    <editor-content :editor="editor" />
  </v-card>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Checkbox from "./checkbox-node";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import FontFamily from "@tiptap/extension-font-family";
import Image from "@tiptap/extension-image";
import ToolbarButton from "./toolbar-button";
import OptionsToolbarButton from "./options-toolbar-button.vue";

// add HTML border and width attributes to the Table extension for when it is rendered onto the doc/pdf
const CustomTable = Table.extend({
  addAttributes() {
    return {
      border: {
        default: 1,
      },
      width: {
        default: "100%",
      },
      cellspacing: {
        default: "0",
      },
    };
  },
});

export default {
  components: {
    EditorContent,
    ToolbarButton,
    OptionsToolbarButton,
  },
  props: ["field", "value"],
  data() {
    return {
      contentChangedInEditor: false,
      editor: new Editor({
        extensions: [
          StarterKit.configure({
            heading: {
              HTMLAttributes: {
                class: "heading",
              },
            },
          }),
          Checkbox,
          CustomTable.configure({
            resizable: false,
          }),
          TableRow,
          TableHeader,
          TableCell,
          Underline,
          TextAlign.configure({
            alignments: ["left", "right", "center"],
            defaultAlignment: "left",
            types: ["paragraph", "heading"],
          }),
          TextStyle,
          FontFamily.configure({
            types: ["textStyle"],
          }),
          Image.configure({
            inline: true,
            allowBase64: true,
            HTMLAttributes: {
              style: "height:50px;",
              class: "image",
            },
          }),
        ],
        content: this.value,
        onUpdate: ({ editor }) => {
          // content updated from within editor
          this.contentChangedInEditor = true;
          this.$emit("fieldChange", editor.getHTML());
        },
      }),
    };
  },
  computed: {
    toolbarButtons() {
      return [
        {
          buttonText: "Bold",
          icon: "mdi-format-bold",
          isActive: this.editor.isActive("bold"),
          onClick: () => {
            this.editor.chain().focus().toggleBold().run();
          },
        },
        {
          buttonText: "Italic",
          icon: "mdi-format-italic",
          isActive: this.editor.isActive("italic"),
          onClick: () => {
            this.editor.chain().focus().toggleItalic().run();
          },
        },
        {
          buttonText: "Underline",
          icon: "mdi-format-underline",
          isActive: this.editor.isActive("underline"),
          onClick: () => {
            this.editor.chain().focus().toggleUnderline().run();
          },
        },
        {
          buttonText: "1 level header",
          icon: "mdi-format-header-1",
          isActive: this.editor.isActive("heading", { level: 1 }),
          onClick: () => {
            this.editor.chain().focus().toggleHeading({ level: 1 }).run();
          },
        },
        {
          buttonText: "2 level header",
          icon: "mdi-format-header-2",
          isActive: this.editor.isActive("heading", { level: 2 }),
          onClick: () => {
            this.editor.chain().focus().toggleHeading({ level: 2 }).run();
          },
        },
        {
          buttonText: "3 level header",
          icon: "mdi-format-header-3",
          isActive: this.editor.isActive("heading", { level: 3 }),
          onClick: () => {
            this.editor.chain().focus().toggleHeading({ level: 3 }).run();
          },
        },
        {
          buttonText: "Bulleted List",
          icon: "mdi-format-list-bulleted",
          isActive: this.editor.isActive("bulletList"),
          onClick: () => {
            this.editor.chain().focus().toggleBulletList().run();
          },
        },
        {
          buttonText: "Ordered List",
          icon: "mdi-format-list-numbered",
          isActive: this.editor.isActive("orderedList"),
          onClick: () => {
            this.editor.chain().focus().toggleOrderedList().run();
          },
        },
        {
          buttonText: "Horizontal Rule",
          icon: "mdi-minus",
          isActive: false,
          onClick: () => {
            this.editor.chain().focus().setHorizontalRule().run();
          },
        },
        {
          buttonText: "Checkbox",
          icon: "mdi-checkbox-marked",
          isActive: false,
          onClick: () => {
            this.editor.chain().focus().insertCheckbox().run();
          },
        },
        {
          buttonText: "Undo",
          icon: "mdi-undo",
          isActive: false,
          onClick: () => {
            this.editor.chain().focus().undo().run();
          },
        },
        {
          buttonText: "Redo",
          icon: "mdi-redo",
          isActive: false,
          onClick: () => {
            this.editor.chain().focus().redo().run();
          },
        },
        {
          buttonText: "Insert Table",
          icon: "mdi-table",
          isActive: false,
          onClick: () => {
            this.editor
              .chain()
              .focus()
              .insertTable({ rows: 3, cols: 3, withHeaderRow: false })
              .run();
          },
        },
      ];
    },
    alignmentOptions() {
      return [
        {
          buttonText: "Left Align",
          icon: "mdi-format-align-left",
          isActive: this.editor.isActive({ textAlign: "left" }),
          onClick: () => this.editor.chain().focus().setTextAlign("left").run(),
        },
        {
          buttonText: "Center Align",
          icon: "mdi-format-align-center",
          isActive: this.editor.isActive({ textAlign: "center" }),
          onClick: () =>
            this.editor.chain().focus().setTextAlign("center").run(),
        },
        {
          buttonText: "Right Align",
          icon: "mdi-format-align-right",
          isActive: this.editor.isActive({ textAlign: "right" }),
          onClick: () =>
            this.editor.chain().focus().setTextAlign("right").run(),
        },
      ];
    },
    tableOptions() {
      return [
        {
          buttonText: "Add Column Before",
          icon: "mdi-table-column-plus-before",
          isActive: false,
          onClick: () => this.editor.chain().focus().addColumnBefore().run(),
        },
        {
          buttonText: "Add Column After",
          icon: "mdi-table-column-plus-after",
          isActive: false,
          onClick: () => this.editor.chain().focus().addColumnAfter().run(),
        },
        {
          buttonText: "Add Row Before",
          icon: "mdi-table-row-plus-before",
          isActive: false,
          onClick: () => this.editor.chain().focus().addRowBefore().run(),
        },
        {
          buttonText: "Add Row After",
          icon: "mdi-table-row-plus-after",
          isActive: false,
          onClick: () => this.editor.chain().focus().addRowAfter().run(),
        },
        {
          buttonText: "Delete Column",
          icon: "mdi-table-column-remove",
          isActive: false,
          onClick: () => this.editor.chain().focus().deleteColumn().run(),
        },
        {
          buttonText: "Delete Row",
          icon: "mdi-table-row-remove",
          isActive: false,
          onClick: () => this.editor.chain().focus().deleteRow().run(),
        },
        {
          buttonText: "Delete Table",
          icon: "mdi-table-remove",
          isActive: false,
          onClick: () => this.editor.chain().focus().deleteTable().run(),
        },
        {
          buttonText: "Toggle Borders",
          icon: "mdi-table-border",
          isActive: false,
          onClick: () =>
            this.editor
              .chain()
              .focus()
              .command(({ editor, commands }) => {
                var hasBorder = editor.getAttributes("table").border;
                var newBorder = hasBorder ? 0 : 1;
                return commands.updateAttributes("table", {
                  border: newBorder,
                });
              })
              .run(),
        },
      ];
    },
    cannotEditTable: function () {
      return !this.editor.can().addColumnBefore();
    },
  },
  watch: {
    value() {
      // only invoked when updated outside editor
      if (this.editor && !this.contentChangedInEditor) {
        this.editor.commands.setContent(this.value);
      }
      this.contentChangedInEditor = false;
    },
  },

  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
.tiptap {
  table {
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
  }
  th,
  td {
    padding: 0.25rem;
  }
  td[border="1"] {
    border: 1px solid black;
  }
  td[border="0"] {
    border: 0px;
  }
}
.tiptap {
  padding: 1rem;
}
.ProseMirror {
  min-height: 100px;
}
.heading {
  margin-top: 10px;
  margin-bottom: 20px;
}
.image {
  height: 40px;
}
</style>
