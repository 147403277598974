<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        v-on="on"
        @click="$emit('onClick')"
        class="pa-2"
        :class="{ 'grey lighten-2 rounded-pill': isActive }"
      >
        {{ icon }}
      </v-icon>
    </template>
    <span>{{ buttonText }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: ["buttonText", "icon", "isActive"],
};
</script>
<style>