<template>
  <v-dialog v-model="dialog" persistent width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom v-on="on" :attrs="attrs">
        <template v-slot:activator="{ on }">
          <v-icon x-small v-on="on" @click="dialog = true" class="ml-2"
            >mdi-content-copy</v-icon
          >
        </template>
        <span>Clone Question</span>
      </v-tooltip>
    </template>

    <v-card v-if="dialog">
      <validation-observer ref="form" v-slot="{ invalid }">
        <v-card-title> Clone Question </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-6">
          <validation-provider rules="required" v-slot="{ errors }">
            <v-autocomplete
              v-model="selectedFamily"
              label="Select Family"
              :items="families"
              item-text="label"
              item-value="familyId"
              :error-messages="errors"
            ></v-autocomplete>
          </validation-provider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loading"
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="invalid || loading"
            @click="clone"
          >
            Clone
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["questionId", "familyId"],
  data() {
    return {
      dialog: false,
      loading: false,
      selectedFamily: this.familyId,
    };
  },
  computed: {
    families: function () {
      return this.$store.state.templatesModule.families;
    },
  },
  methods: {
    async clone() {
      this.loading = true;

      let success = await this.$store.dispatch(
        "templatesModule/cloneQuestionToFamily",
        {
          questionId: this.questionId,
          familyId: this.selectedFamily,
        }
      );
      this.loading = false;
      if (!success) {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Error cloning question."
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }

      this.dialog = false;
    },
  },
};
</script>
