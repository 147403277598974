<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn x-small text @click="dialog = true"
              ><v-icon small>mdi-redo-variant</v-icon></v-btn
            >
          </span>
        </template>
        <span> Restore Assignment </span>
      </v-tooltip>
    </template>

    <v-card class="primary--text">
      <v-card-title> Restore Assignment? </v-card-title>
      <v-card-text>
        <label>Reason / Comments:</label>
        <v-textarea v-model="comments" auto-grow rows="2" outlined></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark text @click="dialog = false">Cancel</v-btn>
        <v-btn color="primary" dark outlined @click="restoreAssignment"
          >OK</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["reportAssignmentId"],
  data() {
    return {
      dialog: false,
      comments: "",
    };
  },
  methods: {
    async restoreAssignment() {
      this.loading = true;
      let success = await this.$store.dispatch(
        "reportsModule/cancelReportAssignment",
        {
          reportAssignmentId: this.reportAssignmentId,
          isCancelled: false,
          comments: this.comments,
        }
      );

      this.loading = false;

      if (!success) {
        this.$store.commit("uxModule/setSnackbarMsg", "Error saving changes.");
        this.$store.commit("uxModule/setShowSnackbar", true);
      }

      this.dialog = false;
    },
  },
};
</script>
