<template>
  <v-card color="#F0F0F0" elevation="1" class="pa-3 my-3">
    <v-row v-if="conditionalOnQuestion">
      <v-col cols="12" sm="3">
        <validation-provider rules="required" v-slot="{ errors }">
          <v-autocomplete
            v-model="parentFamily"
            @change="onChange"
            :items="families"
            item-text="label"
            return-object
            :error-messages="errors"
            outlined
            background-color="#FFFFFF"
          ></v-autocomplete>
        </validation-provider>
      </v-col>
      <v-col cols="12" sm="3">
        <validation-provider
          v-if="parentFamily"
          rules="required"
          v-slot="{ errors }"
        >
          <v-autocomplete
            v-model="parentQuestion"
            @change="onChange"
            :items="questions"
            item-text="label"
            return-object
            :error-messages="errors"
            outlined
            background-color="#FFFFFF"
          >
            <template v-slot:selection="{ item }">
              <span v-sanitize-html="item.label"></span>
            </template>
            <template v-slot:item="{ item }">
              <span v-sanitize-html="item.label"></span>
            </template>
          </v-autocomplete>
        </validation-provider>
      </v-col>
      <v-col cols="12" sm="2">
        <comparator-field
          v-if="parentQuestion"
          :parentComponentName="parentQuestion.componentName"
          :initialComparator="comparator"
          @comparatorChange="onComparatorChange"
        ></comparator-field>
      </v-col>
      <v-col cols="12" sm="3">
        <condition-field
          v-if="parentQuestion && comparator"
          :parentComponentName="parentQuestion.componentName"
          :parentOptions="parentQuestion.options"
          :comparator="comparator"
          :initialConditions="initialConditions"
          @conditionChange="onConditionChange"
        >
        </condition-field>
      </v-col>
    </v-row>
    <v-row v-if="conditionalOnProperty">
      <v-col cols="12" sm="4">
        <validation-provider rules="required" v-slot="{ errors }">
          <v-autocomplete
            v-model="predefinedProperty"
            @change="onChange"
            :items="predefinedProperties"
            item-text="name"
            return-object
            :error-messages="errors"
            outlined
            background-color="#FFFFFF"
          ></v-autocomplete>
        </validation-provider>
      </v-col>
      <v-col cols="12" sm="3">
        <comparator-field
          v-if="predefinedProperty"
          :parentComponentName="predefinedProperty.componentName"
          :initialComparator="comparator"
          @comparatorChange="onComparatorChange"
        ></comparator-field>
      </v-col>
      <v-col cols="12" sm="4">
        <condition-field
          v-if="predefinedProperty && comparator"
          :parentComponentName="predefinedProperty.componentName"
          :parentOptions="predefinedProperty.options"
          :comparator="comparator"
          :initialConditions="initialConditions"
          @conditionChange="onConditionChange"
        >
        </condition-field>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import ComparatorField from "./comparator-field.vue";
import ConditionField from "./condition-field.vue";
import { isNullOrEmpty } from "../../utils/string-utils";
export default {
  props: ["renderingConfig", "conditionalOnQuestion", "conditionalOnProperty"],
  components: {
    ComparatorField,
    ConditionField,
  },
  data() {
    return {
      parentFamily: this.renderingConfig?.parentFamily || null,
      parentQuestion: this.renderingConfig?.parentQuestion || null,
      comparator: this.renderingConfig?.comparator || null,
      renderValue: this.renderingConfig?.renderValue,
      renderValueMin: this.renderingConfig?.renderValueMin,
      renderValueMax: this.renderingConfig?.renderValueMax,
      renderValueOptions: this.renderingConfig?.renderValueOptions || [],
      predefinedProperty: this.renderingConfig?.predefinedProperty || null,
    };
  },
  mounted() {
    if (this.renderingConfig) {
      // this ensures a value of 0 is retained (otherwise considered falsey -> null)
      this.renderValue = isNullOrEmpty(this.renderingConfig?.renderValue)
        ? null
        : this.renderingConfig.renderValue;
      this.renderValueMin = isNullOrEmpty(this.renderingConfig?.renderValueMin)
        ? null
        : this.renderingConfig.renderValueMin;
      this.renderValueMax = isNullOrEmpty(this.renderingConfig?.renderValueMax)
        ? null
        : this.renderingConfig.renderValueMax;
    }
  },
  computed: {
    families: function () {
      return this.$store.state.templatesModule.families;
    },
    questions: function () {
      if (this.parentFamily) {
        return this.$store.getters["templatesModule/getQuestionsByFamily"](
          this.parentFamily.familyId
        );
      }
      return [];
    },
    predefinedProperties: function () {
      return this.$store.state.optionsModule.predefinedProperties;
    },
    initialConditions: function () {
      return {
        renderValue: this.renderValue,
        renderValueMin: this.renderValueMin,
        renderValueMax: this.renderValueMax,
        renderValueOptions: this.renderValueOptions,
      };
    },
  },
  methods: {
    onChange: function () {
      this.$emit("updateRule", {
        renderingConfigId: this.renderingConfig?.renderingConfigId,
        comparator: this.comparator,
        renderValue: this.renderValue,
        renderValueMin: this.renderValueMin,
        renderValueMax: this.renderValueMax,
        renderValueOptions: this.renderValueOptions,
        ...(this.conditionalOnQuestion && {
          parentFamily: this.parentFamily,
          parentQuestion: this.parentQuestion,
          parentQuestionId: this.parentQuestion?.questionId,
        }),
        ...(this.conditionalOnProperty && {
          predefinedProperty: this.predefinedProperty,
          predefinedPropertyId: this.predefinedProperty?.predefinedPropertyId,
        }),
      });
    },
    onComparatorChange: function (value) {
      this.comparator = value;
      this.onChange();
    },
    onConditionChange: function (conditions) {
      this.renderValue = conditions.renderValue;
      this.renderValueMin = conditions.renderValueMin;
      this.renderValueMax = conditions.renderValueMax;
      this.renderValueOptions = conditions.renderValueOptions;
      this.onChange();
    },
  },
  watch: {
    parentFamily: function () {
      this.parentQuestion = null;
      this.comparator = null;
      this.renderValue = null;
      this.renderValueMin = null;
      this.renderValueMax = null;
      this.renderValueOptions = [];
    },
    parentQuestion: function () {
      this.comparator = null;
      this.renderValue = null;
      this.renderValueMin = null;
      this.renderValueMax = null;
      this.renderValueOptions = [];
    },
    predefinedProperty: function () {
      this.comparator = null;
      this.renderValue = null;
      this.renderValueMin = null;
      this.renderValueMax = null;
      this.renderValueOptions = [];
    },
    comparator: function () {
      this.renderValue = null;
      this.renderValueMin = null;
      this.renderValueMax = null;
      this.renderValueOptions = [];
    },
  },
};
</script>
