<template>
  <v-dialog v-model="dialog" width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn icon x-small v-on="on" @click="dialog = true">
              <v-icon small>mdi-dots-vertical</v-icon>
            </v-btn>
          </span>
        </template>
        <span> View Details</span>
      </v-tooltip>
    </template>

    <v-card class="primary--text">
      <v-card-title>
        {{ item.status }}
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="my-1">Reviewed by: {{ item.reviewerName }}</div>
        <div class="my-1">Reviewed on: {{ item.formattedReviewedAt }}</div>
        <div class="my-1">Comments:</div>
        <v-textarea
          v-model="item.reviewComments"
          auto-grow
          rows="2"
          outlined
          readonly
        ></v-textarea>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["item"],
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
