<template>
  <validation-provider :rules="validations" v-slot="{ errors }">
    <tiptap-vuetify
      :value="value"
      :placeholder="field.label"
      @input="onChange"
      :extensions="extensions"
    ></tiptap-vuetify>
    <span class="error--text text-caption">{{ errors[0] }}</span>
  </validation-provider>
</template>

<script>
import { TiptapVuetify, Bold, Italic, Underline } from "tiptap-vuetify";
export default {
  components: {
    TiptapVuetify,
  },
  props: ["field", "value", "validations"],
  data() {
    return {
      extensions: [Bold, Italic, Underline],
    };
  },
  methods: {
    onChange: function (event) {
      this.$emit("fieldChange", event);
    },
  },
};
</script>
<style>
.ProseMirror {
  min-height: 20px;
}
</style>
