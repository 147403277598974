import * as api from "../Services/api.js";
import Template from "../../objs/Template";
import Family from "../../objs/Family";
import Question from "../../objs/Question";
import TemplateNote from "../../objs/TemplateNote";
import QuestionResponse from "../../objs/QuestionResponse";
import { renderItem } from "../../utils/render-utils";
import NarrativeBlock from "../../objs/NarrativeBlock";
import NarrativeStatement from "../../objs/NarrativeStatement";
import Goal from "../../objs/Goal";
import { FieldTypes } from "../../objs/Shared/FieldTypes.js";

export const state = {
  selectedTemplateNote: null,
  template: [],
  families: [],
  questions: [],
  narrativeBlocks: [],
  narrativeStatements: [],
};

export const mutations = {
  SET_SELECTED_TEMPLATE_NOTE(state, payload) {
    state.selectedTemplateNote = new TemplateNote(payload);
  },
  SET_TEMPLATE(state, payload) {
    state.template = new Template(payload);
  },
  SET_FAMILIES(state, payload) {
    state.families = payload.map((item) => new Family(item));
  },
  SET_QUESTIONS(state, payload) {
    state.questions = payload.map((item) => new Question(item));
  },
  SET_NARRATIVE_BLOCKS(state, payload) {
    state.narrativeBlocks =
      payload && payload.map((item) => new NarrativeBlock(item));
  },
  SET_NARRATIVE_STATEMENTS(state, payload) {
    state.narrativeStatements =
      payload && payload.map((item) => new NarrativeStatement(item));
  },
  SET_QUESTION_RESPONSE(state, payload) {
    const index = state.selectedTemplateNote?.questionResponses.findIndex(
      (item) => item.questionId === payload.questionId
    );
    if (index > -1)
      state.selectedTemplateNote?.questionResponses.splice(
        index,
        1,
        new QuestionResponse(payload)
      );
    else
      state.selectedTemplateNote?.questionResponses.push(
        new QuestionResponse(payload)
      );
  },
};

export const getters = {
  getIsRequired: (state) => (questionId) => {
    let question = state.questions.find(
      (item) => item.questionId === questionId
    );
    return Boolean(question.validationRequired);
  },
  getProgress(state, getters) {
    if (state.selectedTemplateNote?.noteText) {
      return 99;
    }

    // based on num rendered questions with a response
    return Math.round(
      (getters.getRenderedQuestions.filter(
        (item) => getters.getQuestionResponseValue(item.questionId) != null
      ).length /
        (getters.getRenderedQuestions.length + 1)) *
        99
    );
  },
  getFamily: (state) => (familyId) => {
    return state.families.find((item) => item.familyId === familyId);
  },
  getQuestion: (state) => (questionId) => {
    return state.questions.find((item) => item.questionId === questionId);
  },
  getQuestionResponse: (state) => (questionId) => {
    return state.selectedTemplateNote?.questionResponses.find(
      (item) => item.questionId === questionId
    );
  },
  getQuestionsByFamily: (state) => (familyId) => {
    return state.questions.filter((item) => item.familyId === familyId);
  },
  getQuestionResponseValue: (state, getters) => (questionId) => {
    let questionResponse = getters.getQuestionResponse(questionId);

    // boolean
    if (questionResponse?.response === false) return questionResponse?.response;
    // primitive
    else if (questionResponse?.response != null)
      return questionResponse?.response;
    // array
    else if (questionResponse?.selections.length > 0)
      return questionResponse?.selections;
    // image
    else if (questionResponse?.imageResponse != null)
      return questionResponse?.imageResponse;
  },
  getRenderedItems: (state, getters) => (items) => {
    return items
      .sort((a, b) => a.sequence - b.sequence)
      .filter((item) => {
        // this function handles all conditional rendering logic
        item.isRendered = renderItem(
          item.rendering,
          state.selectedTemplateNote
        );
        return item.isRendered;
      });
  },
  getRenderedQuestionsByFamily: (state, getters) => (familyId) => {
    let questions = getters.getQuestionsByFamily(familyId);

    return getters.getRenderedItems(questions);
  },
  getRenderedQuestions(state, getters) {
    return getters.getRenderedItems(state.questions);
  },
  getRenderedFamilies(state, getters) {
    return getters.getRenderedItems(state.families);
  },
  getRenderedNarrativeBlocks(state, getters) {
    return getters.getRenderedItems(state.narrativeBlocks);
  },
  getRenderedNarrativeStatements(state, getters) {
    return getters.getRenderedItems(state.narrativeStatements);
  },
  isQuestionMissingResponse: (state, getters) => (questionId) => {
    return (
      getters.getIsRequired(questionId) &&
      (getters.getQuestionResponseValue(questionId) == null ||
        getters.getQuestionResponseValue(questionId) == "" ||
        getters.getQuestionResponseValue(questionId).length == 0)
    );
  },
  getQuestionsMissingResponses(state, getters) {
    return (
      getters.getRenderedFamilies.flatMap((f) => {
        return getters.getRenderedQuestionsByFamily(f.familyId).filter((q) => {
          return getters.isQuestionMissingResponse(q.questionId);
        });
      }) || []
    );
  },
  isMissingResponses(state, getters) {
    return getters.getQuestionsMissingResponses?.length > 0;
  },
  getOtherGoalsRenderedResponses:
    (state, getters) => (questionText, componentName) => {
      return getters.getRenderedQuestions
        .filter(
          (q) =>
            q.label !== questionText &&
            q.componentName === componentName &&
            q.validationDistinct === true
        )
        .map((q) => {
          return getters.getQuestionResponseValue(q.questionId);
        });
    },
  findLongTermGoalInFamily: (state, getters) => (questionText) => {
    // find this question's family
    let familyId = getters.getRenderedQuestions.find(
      (q) =>
        q.label === questionText && q.componentName === FieldTypes.GOALS_SELECT
    )?.familyId;
    // find long-term-goal question within family
    let ltgComponentQuestionId = getters.getRenderedQuestions.find(
      (q) =>
        q.familyId === familyId &&
        q.componentName === FieldTypes.LONG_TERM_GOALS_SELECT
    )?.questionId;
    // return response
    return Boolean(ltgComponentQuestionId)
      ? getters.getQuestionResponseValue(ltgComponentQuestionId)
      : "";
  },
};

export const actions = {
  setFullSelectedNote({ commit }, data) {
    commit("SET_SELECTED_TEMPLATE_NOTE", data);
    commit("SET_TEMPLATE", data?.template);
    commit("SET_FAMILIES", data?.template?.families);
    commit(
      "SET_QUESTIONS",
      data?.template?.families.map((family) => family.questions).flat()
    );
    commit("SET_NARRATIVE_BLOCKS", data?.template?.narrativeBlocks);
    commit(
      "SET_NARRATIVE_STATEMENTS",
      data?.template?.aiNarrative?.narrativeSections
        .map((section) => section.narrativeStatements)
        .flat()
    );
  },
  async loadTemplateNote({ dispatch }, data) {
    return await api
      .getQuery("/templates/loadFullTemplateNote", {
        templateNoteId: data.templateNoteId,
      })
      .then((response) => {
        if (response.success) {
          dispatch("setFullSelectedNote", response.data);
        }
      });
  },
  async createTemplateNote({ dispatch }, data) {
    return await api
      .post("/templates/createTemplateNote", data)
      .then((response) => {
        if (response.success) {
          dispatch("setFullSelectedNote", response.data);
          return "";
        }

        return response.message;
      })
      .catch((error) => {
        return false;
      });
  },
  async deleteTemplateNote({ dispatch }, data) {
    return await api
      .postQuery("/templates/deleteTemplateNote", {
        templateNoteId: data.templateNoteId,
      })
      .then((response) => {
        if (response.success) {
          dispatch("reportsModule/getSEITReportAssignments", null, {
            root: true,
          });
        }
        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async setQuestionResponse({ commit, state }, data) {
    // set response for immediate client-side rendering
    commit("SET_QUESTION_RESPONSE", data);
    return await api
      .post("/templates/saveQuestionResponse", data)
      .then((response) => {
        if (response.success) {
          commit("SET_QUESTION_RESPONSE", response.data);
        }
        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async composeNarrative({ state, commit, getters }) {
    // AI
    if (state.selectedTemplateNote?.template.isAI) {
      let narrativeStatementsToRender = getters.getRenderedNarrativeStatements.map(
        (item) => item.narrativeStatementId
      );
      return await api
        .postQuery("/templates/composeAINarrative", {
          templateNoteId: state.selectedTemplateNote?.templateNoteId,
          narrativeStatementIds: narrativeStatementsToRender.join(","),
        })
        .then((response) => {
          if (response.success) {
            commit("SET_SELECTED_TEMPLATE_NOTE", response.data);
          }
          return response.success;
        })
        .catch((error) => {
          return false;
        });
    }
    // non-AI
    else {
      let narrativeBlocksToRender = getters.getRenderedNarrativeBlocks.map(
        (item) => item.narrativeBlockId
      );
      return await api
        .postQuery("/templates/composeNarrative", {
          templateNoteId: state.selectedTemplateNote?.templateNoteId,
          narrativeBlockIds: narrativeBlocksToRender.join(","),
        })
        .then((response) => {
          if (response.success) {
            commit("SET_SELECTED_TEMPLATE_NOTE", response.data);
          }
          return response.success;
        })
        .catch((error) => {
          return false;
        });
    }
  },
  async saveTemplateNote({ commit }, data) {
    return await api
      .post("/templates/saveTemplateNote", data)
      .then((response) => {
        if (response.success) {
          commit("SET_SELECTED_TEMPLATE_NOTE", response.data);
        }
        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async generateTemplateNote({ commit }, data) {
    return await api
      .postQuery("/templates/generateTemplateNote", data)
      .then((response) => {
        if (response.success) {
          commit("SET_SELECTED_TEMPLATE_NOTE", response.data);
        }
        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async getClientGoals({ state }) {
    let clientId = state.selectedTemplateNote.clientId;
    return await api
      .getQuery("/goal/getGoalsForClient", { clientId })
      .then((response) => {
        if (response.success) {
          return response.data.map((item) => new Goal(item));
        }

        return [];
      })
      .catch((error) => {
        return false;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
