<template>
  <validation-provider :rules="validations" v-slot="{ errors }">
    <label v-sanitize-html="field.label"></label>
    <v-radio-group
      :value="val"
      :disabled="readonly"
      @change="onChange"
      :error-messages="errors"
      color="#005c7b"
      class="mt-0"
      :row="hasTwoOptions"
    >
      <v-radio
        v-for="(item, index) in field.options"
        :key="index"
        :value="item"
      >
        <template v-slot:label>
          <span v-sanitize-html="item[field.itemText]"></span>
        </template>
      </v-radio>
    </v-radio-group>
  </validation-provider>
</template>

<script>
export default {
  props: ["field", "value", "readonly", "validations"],
  data() {
    return {
      debounce: "",
    };
  },
  computed: {
    val: {
      get: function () {
        return this.value && this.value[0];
      },
      set: function (newVal) {},
    },
    hasTwoOptions() {
      return this.field.options?.length == 2;
    },
  },
  methods: {
    onChange: function (event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        this.$emit("fieldChange", [event]);
      }, 300);
    },
  },
};
</script>
