<template>
  <div>
    <v-card v-if="!Boolean(narrative)" class="d-flex justify-center" flat>
      <ai-narrative-modal :isNewAINarrative="true"></ai-narrative-modal>
    </v-card>
    <template v-else>
      <v-toolbar flat>
        {{ narrative?.description || "[Add description]" }}
        <ai-narrative-modal
          :aiNarrative="narrative"
        ></ai-narrative-modal>
      </v-toolbar>
      <narrative-sections-table class="mb-3"></narrative-sections-table>
      <v-card-actions class="justify-center">
        <narrative-section-modal
          :isNewSection="true"
          :aiNarrativeId="narrative.aiNarrativeId"
        ></narrative-section-modal>
      </v-card-actions>
    </template>
  </div>
</template>
<script>
import AiNarrativeModal from "./ai-narrative-modal.vue";
import NarrativeSectionsTable from "./narrative-sections-table.vue";
import NarrativeSectionModal from "./narrative-section-modal.vue";
export default {
  components: {
    AiNarrativeModal,
    NarrativeSectionsTable,
    NarrativeSectionModal,
  },
  async mounted() {
    await this.$store.dispatch("templatesModule/getPredefinedStubs");
  },
  computed: {
    narrative: function () {
      return this.$store.state.templatesModule.aiNarrative;
    },
  },
};
</script>
