export default class File {
  fileId?: number;
  fileName?: string | null;

  public constructor(params: File = {} as File) {
    let { fileId = 0, fileName = "" } = params;

    this.fileId = fileId;
    this.fileName = fileName;
  }
}
