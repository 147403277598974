import { formatShortDate } from "../utils/format-utils";
import Client from "./Client";

export default class Plan {
  planId?: number;
  legacyPlanId?: number | null;
  clientId?: number | null;
  client?: Client | null;
  planDate?: Date | null;
  planTypeId?: number | null;
  planType?: string | null;
  closureDate?: Date | null;

  public constructor(params: Plan = {} as Plan) {
    let {
      planId = 0,
      legacyPlanId = null,
      clientId = null,
      client = null,
      planDate = null,
      planTypeId = null,
      planType = null,
      closureDate = null,
    } = params;
    this.planId = planId;
    this.legacyPlanId = legacyPlanId;
    this.clientId = clientId;
    this.client = client && new Client(client);
    this.planDate = planDate;
    this.planTypeId = planTypeId;
    this.planType = planType;
    this.closureDate = closureDate;
  }

  get formattedPlanDate() {
    return formatShortDate(this.planDate);
  }
}
