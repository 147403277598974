export const FieldTypes = {
  TEXT_FIELD: "text-field",
  TEXT_AREA: "text-area",
  PHONE_NUMBER_FIELD: "phone-number-field",
  ZIP_CODE_FIELD: "zip-code-field",
  NUMERIC_FIELD: "numeric-field",
  DATE_PICKER: "date-picker",
  TIME_INPUT: "time-input",
  RADIO_GROUP: "radio-group",
  CHECKBOX: "checkbox",
  STANDARD_SELECT: "standard-select",
  MULTIPLE_SELECT: "multiple-select",
  AUTOCOMPLETE: "autocomplete",
  GOALS_SELECT: "goals-select",
  LONG_TERM_GOALS_SELECT: "long-term-goals-select",
  COGNITIVE_GOALS_SELECT: "cognitive-goals-select",
  SIGNATURE: "signature",
  CHIP_SELECT: "chip-select",
  MULTIPLE_CHIP_SELECT: "multiple-chip-select",
  WORD_BANK: "word-bank",
};
