<template>
  <v-dialog v-model="dialog" persistent width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip v-if="reportAssignmentId" bottom v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn x-small text @click="openDialog"
              ><v-icon small>mdi-pencil</v-icon></v-btn
            >
          </span>
        </template>
        <span> Edit Assignment </span>
      </v-tooltip>
      <v-tooltip top v-else>
        <template v-slot:activator="{ on }">
          <v-btn color="primary" small text v-on="on" @click="openDialog"
            ><v-icon small>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>New</span>
      </v-tooltip>
    </template>

    <v-card flat class="mx-auto" v-if="dialog">
      <v-card-title class="pt-0">
        <v-toolbar flat>
          <span> Assignment Details </span>
          <notes-modal></notes-modal>
          <span v-if="isCancelled" class="error--text text-subtitle-2"
            >Cancelled on {{ reportAssignment.formattedCancelledAt }}. See Notes
            for more details.</span
          >
        </v-toolbar>
        <v-spacer></v-spacer>
        <v-btn icon @click="clearForm">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card outlined elevation="1" class="mx-3">
        <validation-observer v-slot="{ invalid }">
          <v-card-text>
            <template v-if="programView === 'SEIT'">
              <validation-provider rules="required" v-slot="{ errors }">
                <v-autocomplete
                  v-model="selectedMandate"
                  :items="mandates"
                  label="IEP Plan"
                  :item-text="(item) => getMandateText(item)"
                  item-value="mandateId"
                  return-object
                  :error-messages="errors"
                  :disabled="Boolean(reportAssignment)"
                >
                </v-autocomplete>
              </validation-provider>
            </template>
            <template v-if="programView === 'EIO'">
              <validation-provider rules="required" v-slot="{ errors }">
                <v-autocomplete
                  v-model="selectedPlan"
                  :items="plans"
                  label="Plan"
                  :item-text="(item) => getPlanText(item)"
                  item-value="planId"
                  return-object
                  :error-messages="errors"
                  :disabled="Boolean(reportAssignment)"
                >
                </v-autocomplete>
              </validation-provider>
              <validation-provider rules="required" v-slot="{ errors }">
                <v-select
                  label="Service"
                  v-model="form.serviceId"
                  :items="services"
                  :item-text="(item) => `${item.code} - ${item.name}`"
                  item-value="serviceId"
                  :error-messages="errors"
                ></v-select>
              </validation-provider>
            </template>
            <validation-provider rules="required" v-slot="{ errors }">
              <v-autocomplete
                v-model="form.providerId"
                :items="providers"
                :item-text="
                  (item) =>
                    `${item.firstName} ${item.lastName} - ${item.legacyProviderId}`
                "
                item-value="providerId"
                label="Provider"
                :error-messages="errors"
              >
              </v-autocomplete>
            </validation-provider>
            <validation-provider rules="required" v-slot="{ errors }">
              <v-select
                v-model="form.reportTypeId"
                :items="reportTypes"
                label="Type"
                item-text="name"
                item-value="reportTypeId"
                :error-messages="errors"
              ></v-select>
            </validation-provider>
            <v-label>Due Date</v-label>
            <date-picker
              :value="form.dueDate"
              :field="{}"
              :validations="{ required: true }"
              @fieldChange="form.dueDate = $event"
            >
            </date-picker>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :disabled="invalid || loading"
              @click="saveForm"
              >Save
            </v-btn>
          </v-card-actions>
        </validation-observer>
      </v-card>
      <v-card outlined elevation="1" class="ma-3">
        <v-card-text>
          <v-stepper flat>
            <v-stepper-header>
              <v-stepper-step step="1" :complete="isAssigned">
                <span>Assigned</span>
                <small>
                  <v-edit-dialog large persistent @save="save(() => {})">
                    <span v-if="isAssigned">{{
                      form.formattedAssignedAt
                    }}</span>
                    <v-btn v-else x-small text
                      ><v-icon small>mdi-pencil</v-icon></v-btn
                    >
                    <template v-slot:input>
                      <date-picker
                        :value="form.assignedAt"
                        :field="{}"
                        @fieldChange="form.assignedAt = $event"
                      ></date-picker>
                    </template>
                  </v-edit-dialog>
                </small>
              </v-stepper-step>
              <v-stepper-step step="2" :complete="isSubmitted">
                <span>Submitted</span>
                <small v-if="hasTemplateNote">
                  <span v-if="isSubmitted"
                    >{{ form.formattedSubmittedAt }}
                  </span>
                </small>
                <small v-else>
                  <v-edit-dialog
                    v-if="
                      (isAssigned || isSubmitted) && Boolean(reportAssignment)
                    "
                    large
                    persistent
                    @save="save(() => {})"
                  >
                    <span v-if="isSubmitted">{{
                      form.formattedSubmittedAt
                    }}</span>
                    <v-btn v-else x-small text
                      ><v-icon small>mdi-pencil</v-icon></v-btn
                    >
                    <template v-slot:input>
                      <date-picker
                        :value="form.submittedAt"
                        :field="{}"
                        @fieldChange="form.submittedAt = $event"
                      ></date-picker>
                    </template>
                  </v-edit-dialog>
                </small>
              </v-stepper-step>
              <v-stepper-step step="3" :complete="isReviewed">
                <span>Reviewed</span>
                <small>
                  <span>
                    <span v-if="isReviewed"
                      >{{ form.reviewText }}
                      {{ form.formattedReviewedAt }}</span
                    >
                    <review-report-action
                      v-if="isSubmitted && !hasTemplateNote"
                      :reportAssignmentId="form.reportAssignmentId"
                      @save="save"
                      @reviewed="openDialog"
                    ></review-report-action>
                  </span>
                </small>
              </v-stepper-step>
              <v-stepper-step step="4" :complete="isSubmittedToDOE">
                <span>Submitted To DOE</span>
                <small>
                  <v-edit-dialog
                    v-if="isReviewed || isSubmittedToDOE"
                    large
                    persistent
                    @save="save(() => {})"
                  >
                    <span v-if="isSubmittedToDOE"
                      >{{ form.formattedSubmittedToDOEAt }}
                    </span>
                    <v-btn v-else x-small text
                      ><v-icon small>mdi-pencil</v-icon></v-btn
                    >
                    <template v-slot:input>
                      <date-picker
                        :value="form.submittedToDOEAt"
                        :field="{}"
                        @fieldChange="form.submittedToDOEAt = $event"
                      ></date-picker>
                    </template>
                  </v-edit-dialog>
                </small>
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card-text>
      </v-card>
      <v-card-text>
        <v-subheader v-if="form.lastNotificationSentAt"
          >Last Notification ("{{ form.notificationType }}") sent
          {{ form.formattedLastNotificationSentAt }}</v-subheader
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ReportAssignment from "../../objs/ReportAssignment";
import NotesModal from "./notes-modal.vue";
import ReviewReportAction from "./review-report-action.vue";
export default {
  components: {
    NotesModal,
    ReviewReportAction,
  },
  props: ["reportAssignmentId"],
  data() {
    return {
      dialog: false,
      form: new ReportAssignment(),
      loading: false,
      providers: [],
      selectedMandate: null,
      selectedPlan: null,
    };
  },
  computed: {
    programView() {
      return this.$store.state.reportsModule.programView;
    },
    reportAssignment() {
      return this.$store.state.reportsModule.selectedReportAssignment;
    },
    allProviders() {
      return this.$store.state.optionsModule.providers;
    },
    reportTypes() {
      return this.$store.getters["optionsModule/reportTypes"](this.programView);
    },
    mandates() {
      return this.$store.state.optionsModule.mandates;
    },
    plans() {
      return this.$store.state.optionsModule.plans;
    },
    services() {
      return this.$store.state.optionsModule.services.filter(
        (x) => !x.isEvaluation
      );
    },
    isAssigned() {
      return Boolean(this.form.assignedAt);
    },
    isSubmitted() {
      return this.isAssigned && Boolean(this.form.submittedAt);
    },
    isReviewed() {
      return this.isSubmitted && Boolean(this.form.reviewedAt);
    },
    isSubmittedToDOE() {
      return this.isReviewed && Boolean(this.form.submittedToDOEAt);
    },
    isCancelled() {
      return Boolean(this.reportAssignment?.cancelledAt);
    },
    hasTemplateNote() {
      return Boolean(this.form.reportType?.templateId);
    },
  },
  methods: {
    async openDialog() {
      this.$store.commit("uxModule/setShowLoader", true);
      this.providers = this.allProviders;
      if (this.reportAssignmentId) {
        await this.$store.dispatch("reportsModule/loadReportAssignment", {
          reportAssignmentId: this.reportAssignmentId,
        });
      } else {
        await this.$store.dispatch("reportsModule/clearReportAssignment");
      }
      this.$store.commit("uxModule/setShowLoader", false);

      this.dialog = true;
      if (Boolean(this.reportAssignment)) {
        this.syncForm();
      }
    },
    syncForm() {
      this.form = new ReportAssignment({
        ...this.reportAssignment,
        dueDate: this.reportAssignment.dueDate
          ? new Date(this.reportAssignment.dueDate)
          : null,
        assignedAt: this.reportAssignment.assignedAt
          ? new Date(this.reportAssignment.assignedAt)
          : null,
        submittedAt: this.reportAssignment.submittedAt
          ? new Date(this.reportAssignment.submittedAt)
          : null,
        reviewedAt: this.reportAssignment.reviewedAt
          ? new Date(this.reportAssignment.reviewedAt)
          : null,
        submittedToDOEAt: this.reportAssignment.submittedToDOEAt
          ? new Date(this.reportAssignment.submittedToDOEAt)
          : null,
        cancelledAt: this.reportAssignment.cancelledAt
          ? new Date(this.reportAssignment.cancelledAt)
          : null,
      });
      this.selectedMandate = this.form?.mandate;
      this.selectedPlan = this.form?.plan;
    },
    getMandateText(item) {
      return `${item.client?.lastName}, ${item.client?.firstName} - ${item.client?.osis} (${item.client?.formattedDobShort}): ${item.serviceName} ${item.formattedIEPPlanDate}`;
    },
    getPlanText(item) {
      return `${item.client?.lastName}, ${item.client?.firstName} - ${item.client?.nyeisId} (${item.client?.formattedDobShort}): ${item.planType} ${item.formattedPlanDate}`;
    },
    async saveForm() {
      this.loading = true;

      await this.save(() => {});

      this.loading = false;
      this.clearForm();
    },
    // this calls a callback on success
    async save(done) {
      let message;
      if (Boolean(this.reportAssignment)) {
        message = await this.$store.dispatch(
          "reportsModule/updateReportAssignment",
          this.form
        );
      } else {
        message = await this.$store.dispatch(
          "reportsModule/createReportAssignment",
          this.form
        );
      }

      if (message) {
        this.$store.commit("uxModule/setSnackbarMsg", message);
        this.$store.commit("uxModule/setShowSnackbar", true);
      } else {
        done();
      }
    },
    clearForm() {
      this.dialog = false;
      this.form = new ReportAssignment();
      this.selectedMandate = null;
      this.selectedPlan = null;
    },
    async loadProviders() {
      if (this.selectedMandate) {
        this.providers = await this.$store.dispatch(
          "optionsModule/getProvidersForClient",
          { clientId: this.selectedMandate.clientId }
        );
      } else if (this.selectedPlan) {
        this.providers = await this.$store.dispatch(
          "optionsModule/getProvidersForClient",
          { clientId: this.selectedPlan.clientId }
        );
      }
      if (this.providers.length == 0) {
        this.providers = this.allProviders;
      }
    },
  },
  watch: {
    selectedMandate() {
      this.form.mandateId = this.selectedMandate?.mandateId;
      this.loadProviders();
    },
    selectedPlan() {
      this.form.planId = this.selectedPlan?.planId;
      this.loadProviders();
    },
  },
};
</script>
