<template>
  <v-dialog v-model="dialog" persistent width="70vw">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="newFamily"
        color="primary"
        outlined
        rounded
        small
        v-on="on"
        @click="dialog = true"
        ><v-icon>mdi-plus</v-icon>New Family</v-btn
      >

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            v-if="!newFamily"
            small
            class="mr-2"
            v-on="on"
            @click="dialog = true"
            >mdi-pencil</v-icon
          >
        </template>
        <span>Edit Family Details</span>
      </v-tooltip>
    </template>

    <v-card v-if="dialog">
      <validation-observer ref="form" v-slot="{ invalid }">
        <v-card-title> Family Details </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="px-6">
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field
              v-model="familyTitle"
              label="Family Title"
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>

          <span class="subheading">Rendering Configuration</span>
          <conditional-rendering-field
            :renderingItem="rendering"
            @renderingChange="onRenderingChange"
          ></conditional-rendering-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="clear"> Cancel </v-btn>
          <v-btn color="primary" text :disabled="invalid" @click="save">
            Save
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import Rendering from "../../objs/Rendering";
import ConditionalRenderingField from "./conditional-rendering-field.vue";

export default {
  components: {
    ConditionalRenderingField,
  },
  props: ["newFamily", "family"],
  data() {
    return {
      dialog: false,
      familyTitle: this.family?.label || "",
      rendering: this.family
        ? {
            ...this.family?.rendering,
            renderingConfigs: [...this.family?.rendering.renderingConfigs],
          }
        : new Rendering(),
    };
  },
  computed: {
    templateId: function () {
      return this.$store.getters["templatesModule/getSelectedTemplateId"];
    },
    sequence: function () {
      return this.family?.sequence || this.families.length + 1;
    },
    families: function () {
      return this.$store.state.templatesModule.families;
    },
  },
  methods: {
    onRenderingChange: function (selection) {
      this.rendering = selection;
    },
    save: async function () {
      this.$store.commit("uxModule/setShowLoader", true);
      let success = false;
      if (this.newFamily) {
        success = await this.$store.dispatch("templatesModule/addFamily", {
          templateId: this.templateId,
          sequence: this.sequence,
          label: this.familyTitle,
          rendering: this.rendering,
        });
      } else {
        success = await this.$store.dispatch("templatesModule/updateFamily", {
          familyId: this.family.familyId,
          templateId: this.templateId,
          sequence: this.sequence,
          label: this.familyTitle,
          rendering: this.rendering,
        });
      }
      this.$store.commit("uxModule/setShowLoader", false);

      if (!success) {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Error saving family details"
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }

      this.clear();
    },
    clear: function () {
      this.$refs.form.reset();
      this.dialog = false;
      this.familyTitle = this.family?.label || "";
      this.rendering = this.family
        ? {
            ...this.family?.rendering,
            renderingConfigs: [...this.family?.rendering.renderingConfigs],
          }
        : new Rendering();
    },
  },
};
</script>
