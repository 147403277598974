import {
  formatLongDate,
  formatOsis,
  formatShortDate,
} from "../utils/format-utils";
import School from "./School";

export default class Client {
  clientId?: number;
  legacyClientId?: number;
  osis?: string | null;
  nyeisId?: string | null;
  gender?: string | null;
  dateOfBirth?: Date | null;
  firstName?: string | null;
  middleInitial?: string | null;
  lastName?: string | null;
  currentSchool?: School | null;
  age?: string | null;
  districtId?: number | null;

  public constructor(params: Client = {} as Client) {
    let {
      clientId = 0,
      legacyClientId = 0,
      osis = "",
      nyeisId = "",
      gender = "",
      dateOfBirth = null,
      firstName = "",
      middleInitial = "",
      lastName = "",
      currentSchool = null,
      age = null,
      districtId = null,
    } = params;

    this.clientId = clientId;
    this.legacyClientId = legacyClientId;
    this.osis = osis && formatOsis(osis);
    this.nyeisId = nyeisId;
    this.gender = gender;
    this.dateOfBirth = dateOfBirth;
    this.firstName = firstName;
    this.middleInitial = middleInitial;
    this.lastName = lastName;
    this.currentSchool = currentSchool && new School(currentSchool);
    this.age = age;
    this.districtId = districtId;
  }

  get fullName() {
    if (this.middleInitial) {
      return this.firstName + " " + this.middleInitial + " " + this.lastName;
    }

    return this.firstName + " " + this.lastName;
  }

  get formattedDobShort() {
    if (this.dateOfBirth) {
      return formatShortDate(this.dateOfBirth);
    }
    return "";
  }

  get formattedDobLong() {
    if (this.dateOfBirth) {
      return formatLongDate(this.dateOfBirth);
    }
    return "";
  }
}
