<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-btn color="primary" small text v-on="on" @click="refresh"
        ><v-icon small>mdi-refresh</v-icon>
      </v-btn>
    </template>
    <span>Refresh</span>
  </v-tooltip>
</template>
<script>
export default {
  methods: {
    async refresh() {
      this.$store.commit("uxModule/setShowLoader", true);
      var success = await this.$store.dispatch(
        "reportsModule/refreshReportAssignments"
      );
      if (!success) {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Error refreshing report assignments"
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
      this.$store.commit("uxModule/setShowLoader", false);
    },
  },
};
</script>
