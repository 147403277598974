<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <template v-for="(step, index) in _steps">
        <v-stepper-step
          :step="index + 1"
          :complete="e1 > index + 1"
          :key="'A' + step.header"
          >{{ step.header }}</v-stepper-step
        >
        <v-divider
          v-if="index + 1 < steps.length - 1"
          :key="'B' + step.header"
        ></v-divider>
      </template>
    </v-stepper-header>

    <v-stepper-items>
      <template v-for="(step, index) in _steps">
        <v-stepper-content :step="index + 1" :key="'C' + step.header">
          <validation-observer v-slot="{ invalid }">
            <v-card flat color="white" class="mb-6" min-height="250">
              <component
                :is="step.component"
                v-bind="{ ...step.props }"
                v-on="step.on"
              >
              </component>
            </v-card>
            <v-btn
              color="primary"
              :dark="!invalid"
              :disabled="invalid"
              @click="next"
              >{{ e1 === _steps.length ? "Save" : "Continue" }}</v-btn
            >
            <v-btn text @click="back">{{ e1 === 1 ? "Cancel" : "Back" }}</v-btn>
          </validation-observer>
        </v-stepper-content>
      </template>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import TemplateNameStep from "./template-name-step";
import QuestionsStep from "./questions-step";
import StubsStep from "./stubs-step";
import NarrativeStep from "./narrative-step";
import AiNarrativeStep from "./ai-narrative-step.vue";
import ActivateStep from "./activate-step";
export default {
  components: {
    TemplateNameStep,
    QuestionsStep,
    StubsStep,
    NarrativeStep,
    AiNarrativeStep,
    ActivateStep,
  },
  data() {
    return {
      e1: 1,
      isNewTemplate: true,
      selectedTemplate: {},
      activate: true,
    };
  },
  computed: {
    steps() {
      return [
        {
          header: "Choose Template",
          component: "template-name-step",
          on: {
            setSelectedTemplate: (e) => {
              this.onSelectedTemplate(e);
            },
          },
        },
        { header: "Create Questions", component: "questions-step" },
        { header: "Define Stubs", component: "stubs-step", hide: this.isAI },
        {
          header: "Compose Narrative",
          component: "narrative-step",
          hide: this.isAI,
        },
        {
          header: "Compose AI Narrative",
          component: "ai-narrative-step",
          hide: !this.isAI,
        },
        {
          header: "Review and Activate",
          component: "activate-step",
          on: {
            activateChange: (e) => {
              this.onActivateChange(e);
            },
          },
        },
      ];
    },
    _steps() {
      return this.steps.filter((x) => !x.hide);
    },
    selectedTemplateId() {
      return this.$store.state.templatesModule.selectedTemplate?.templateId;
    },
    isAI() {
      return this.selectedTemplate?.isAI;
    },
  },
  methods: {
    async next() {
      // save and load template
      if (this.e1 == 1) {
        await this.saveAndLoadSelectedTemplate();
      }

      // continue
      if (this.e1 == this._steps.length) {
        this.save();
      } else {
        this.e1++;
      }
    },
    back() {
      if (this.e1 == 1) {
        this.$router.push({ name: "Home" });
      } else {
        this.e1--;
      }
    },
    onSelectedTemplate: function (value) {
      this.isNewTemplate = value.isNewTemplate;
      this.selectedTemplate = value.template;
    },
    onActivateChange: function (value) {
      this.activate = value;
    },
    saveAndLoadSelectedTemplate: async function () {
      var message = "";
      if (this.isNewTemplate) {
        message = await this.$store.dispatch("templatesModule/addTemplate", {
          ...this.selectedTemplate,
        });
      } else {
        message = await this.$store.dispatch("templatesModule/updateTemplate", {
          ...this.selectedTemplate,
        });
      }

      if (message) {
        this.$store.commit("uxModule/setSnackbarMsg", message);
        this.$store.commit("uxModule/setShowSnackbar", true);
        return;
      }

      this.$store.commit("uxModule/setShowLoader", true);
      await this.$store.dispatch("templatesModule/loadTemplate", {
        templateId: this.selectedTemplateId,
      });
      this.$store.commit("uxModule/setShowLoader", false);
    },
    save: async function () {
      this.$store.commit("uxModule/setShowLoader", true);
      var message = await this.$store.dispatch(
        "templatesModule/updateTemplate",
        {
          ...this.selectedTemplate,
          ...{ isActive: this.activate },
        }
      );
      this.$store.commit("uxModule/setShowLoader", false);

      if (message) {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Error changing template activation status"
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
        return;
      }

      message = this.activate ? "Template Activated!" : "Template Deactivated";

      this.$store.commit("uxModule/setSnackbarMsg", message);
      this.$store.commit("uxModule/setShowSnackbar", true);

      this.$router.push({ name: "Home" });
    },
  },
};
</script>
