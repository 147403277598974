<template>
  <v-list-item @click="exportReport">
    <v-icon class="mr-2">mdi-file-alert-outline</v-icon>
    Export {{ type }} Goals Exception Report
  </v-list-item>
</template>

<script>
import { downloadFile } from "../../utils/download-utils";
export default {
  props: ["type"],
  methods: {
    exportReport: async function () {
      this.$store.commit("uxModule/setShowLoader", true);
      var response;
      if (this.type === "SEIT") {
        response = await this.$store.dispatch(
          "optionsModule/getGoalsMatchingNoIEPPlanReport"
        );
      } else {
        // type = "EIO"
        response = await this.$store.dispatch(
          "optionsModule/getGoalsMatchingNoEIPlanReport"
        );
      }

      if (response) {
        await downloadFile(response, `GoalsMatchingNoPlan.csv`);
      } else {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Oops! Something went wrong. Please try again later."
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
      this.$store.commit("uxModule/setShowLoader", false);
    },
  },
};
</script>
