<template>
  <div>
    <v-col cols="12" sm="3" class="px-0 pb-0">
      <validation-provider rules="required" v-slot="{ errors }">
        <v-autocomplete
          v-model="selectedProgram"
          @change="clear"
          label="Select Program"
          :items="programs"
          item-text="name"
          return-object
          :loading="loading"
          :error-messages="errors"
        ></v-autocomplete>
      </validation-provider>
    </v-col>

    <v-col cols="12" sm="3" class="px-0">
      <v-btn-toggle
        v-model="templateType"
        tile
        mandatory
        color="primary"
        @change="clear"
      >
        <v-btn value="new"> New Template</v-btn>
        <v-btn value="edit"> Edit Template</v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col cols="12" sm="3" class="pa-0">
      <validation-provider rules="required" v-slot="{ errors }" slim>
        <v-text-field
          v-if="isNewTemplate"
          v-model="templateName"
          @change="setSelectedTemplate"
          label="Enter New Template Name"
          :error-messages="errors"
          outlined
          solo
          color="#005c7b"
        ></v-text-field>
        <v-autocomplete
          v-if="!isNewTemplate"
          v-model="selectedTemplate"
          @change="setSelectedTemplate"
          label="Choose Template To Edit"
          :items="availableTemplates"
          item-text="name"
          return-object
          :no-data-text="
            selectedProgram ? 'No Data Available' : 'No Program Selected'
          "
          :loading="loading"
          :error-messages="errors"
          outlined
          solo
          color="#005c7b"
        ></v-autocomplete>
      </validation-provider>
    </v-col>

    <v-col cols="12" sm="3" class="px-0 pt-0">
      <v-autocomplete
        v-model="selectedTemplate.reportTypes"
        @change="setSelectedTemplate"
        label="Report Type(s)"
        :items="reportTypes"
        item-text="name"
        return-object
        multiple
        small-chips
        prepend-icon="mdi-file-chart-outline"
        :no-data-text="
          selectedProgram ? 'No Data Available' : 'No Program Selected'
        "
        :loading="loading"
        hide-details
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0">
            <span>{{ item.name }}</span>
          </v-chip>
          <span v-if="index === 1" class="grey--text text-caption">
            (+{{ selectedTemplate.reportTypes.length - 1 }} others)
          </span>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" sm="3" class="px-0">
      <validation-provider rules="required" v-slot="{ errors }" slim>
        <v-select
          v-model="selectedTemplate.documentFormat"
          @change="setSelectedTemplate"
          label="Document Format"
          :items="documentFormats"
          prepend-icon="mdi-file-edit-outline"
          :loading="loading"
          :error-messages="errors"
        ></v-select>
      </validation-provider>
    </v-col>
    <v-col cols="12" sm="3" class="pa-0">
        <v-checkbox
          v-model="selectedTemplate.includeLogo"
          @change="setSelectedTemplate"
          label="Include Logo"
          dense
        ></v-checkbox>
    </v-col>
    <v-col cols="12" sm="3" class="pa-0">
        <v-checkbox
          v-model="selectedTemplate.isAI"
          @change="setSelectedTemplate"
          label="Use AI (new!)"
          dense
        ></v-checkbox>
    </v-col>
  </div>
</template>
<script>
import { required } from "../../plugins/vee-validate/validation";
export default {
  data() {
    return {
      selectedProgram: "",
      templateType: "edit",
      templateName: "",
      selectedTemplate: {},
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("templatesModule/getAllTemplates");
    this.loading = false;
  },
  computed: {
    programs: function () {
      return this.$store.state.optionsModule.programs;
    },
    reportTypes: function () {
      return this.$store.getters["optionsModule/reportTypes"](
        this.selectedProgram?.code
      );
    },
    documentFormats: function () {
      return this.$store.state.optionsModule.documentFormats;
    },
    availableTemplates: function () {
      if (this.selectedProgram) {
        return this.$store.getters["templatesModule/getTemplatesByProgram"](
          this.selectedProgram.programId
        );
      }
      return [];
    },
    isNewTemplate: function () {
      return this.templateType === "new";
    },
  },
  methods: {
    setSelectedTemplate: function () {
      let template = this.isNewTemplate
        ? {
            name: this.templateName,
            programId: this.selectedProgram.programId,
            reportTypes: this.selectedTemplate.reportTypes,
            documentFormat: this.selectedTemplate.documentFormat,
            includeLogo: this.selectedTemplate.includeLogo,
            isAI: this.selectedTemplate.isAI,
          }
        : this.selectedTemplate;

      this.$emit("setSelectedTemplate", {
        isNewTemplate: this.isNewTemplate,
        template,
      });
    },
    clear: function () {
      this.templateName = "";
      this.selectedTemplate = {};
    },
  },
};
</script>
