<template>
  <validation-provider :rules="validations" v-slot="{ errors }">
    <label v-sanitize-html="field.label"></label>
    <v-text-field
      :value="value"
      outlined
      type="time"
      required
      @input="onChange"
      :error-messages="errors"
      background-color="#FFFFFF"
      color="#005c7b"
      class="mt-0"
    >
    </v-text-field>
  </validation-provider>
</template>

<script>
export default {
  props: ["field", "value", "validations"],
  methods: {
    onChange: function (event) {
      if (event) {
        this.$emit("fieldChange", event);
        return;
      } else {
        this.$emit("fieldChange", "");
      }
    },
  },
};
</script>
