<template>
  <validation-provider :rules="validations" v-slot="{ errors }">
    <label v-sanitize-html="field.label"></label>
    <v-autocomplete
      :value="val"
      :disabled="readonly"
      :items="field.options"
      :item-text="field.itemText"
      return-object
      @change="onChange"
      :error-messages="errors"
      color="#005c7b"
      class="mt-0"
      clearable
      :outlined="outlined"
    >
      <template v-slot:item="{ item }">
        <div v-sanitize-html="item[field.itemText]"></div>
      </template>
      <template v-slot:selection="{ item }">
        <div v-sanitize-html="item[field.itemText]"></div>
      </template>
    </v-autocomplete>
  </validation-provider>
</template>

<script>
export default {
  props: ["field", "value", "readonly", "outlined", "validations"],
  computed: {
    val: {
      get: function () {
        return this.value && this.value[0];
      },
      set: function (newVal) {},
    },
  },
  methods: {
    onChange(event) {
      if (event) this.$emit("fieldChange", [event]);
      else this.$emit("fieldChange", []);
    },
  },
};
</script>
