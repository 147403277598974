<template>
  <v-card height="84vh">
    <v-carousel v-model="current" :show-arrows="false" hide-delimiters height="100%">
      <v-carousel-item
        v-for="(family, i) in renderedFamilies"
        :key="i"
      >
        <question-family
          :family="family"
          @goBack="goBack"
          @goNext="goNext"
        ></question-family>
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>
<script>
import QuestionFamily from "./question-family.vue";
export default {
  components: {
    QuestionFamily,
  },
  computed: {
    current: function () {
      return Number(this.$route.query.pos) || 0;
    },
    renderedFamilies: function () {
      return this.$store.getters["notesModule/getRenderedFamilies"] || [];
    },
    selectedTemplateNote() {
      return this.$store.state.notesModule.selectedTemplateNote;
    },
  },
  methods: {
    goBack: function () {
      if (this.current) {
        this.$router.push({
          name: "TemplateNote",
          query: { pos: this.current - 1 },
        });
      } else {
        this.$router.push({ name: "ReportsDashboard" });
      }
    },
    goNext: async function () {
      if (this.current === this.renderedFamilies.length - 1) {
        this.$store.commit("uxModule/setShowLoader", true);
        await this.$store.dispatch("notesModule/loadTemplateNote", {
          templateNoteId: this.selectedTemplateNote?.templateNoteId,
        });
        this.$store.commit("uxModule/setShowLoader", false);
        this.$router.push({ name: "ReviewQuestions" });
        return;
      }

      this.$router.push({
        name: "TemplateNote",
        query: { pos: this.current + 1 },
      });
    },
  },
};
</script>
