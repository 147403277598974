<template>
  <v-dialog v-model="dialog" persistent width="70vw">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom small v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn color="primary" icon v-on="on" @click="dialog = true"
              ><v-icon small>mdi-content-copy</v-icon></v-btn
            >
          </span>
        </template>
        <span class="text-caption"> Clone From Another Template</span>
      </v-tooltip>
    </template>

    <v-card v-if="dialog">
      <validation-observer ref="form" v-slot="{ invalid }">
        <v-card-title> Clone From Another Template </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-6">
          <validation-provider rules="required" v-slot="{ errors }">
            <v-autocomplete
              v-model="selectedTemplate"
              label="Select Template"
              :items="templates"
              item-text="name"
              item-value="templateId"
              :error-messages="errors"
            ></v-autocomplete>
          </validation-provider>

          <v-radio-group v-model="cloneType" row mandatory dense hide-details>
            <v-radio
              value="family"
              label="Family (including questions and stubs)"
            >
            </v-radio>
            <v-radio value="narratives" label="Narrative(s)"></v-radio>
          </v-radio-group>

          <span v-if="cloneType == 'family'">
            <validation-provider rules="required" v-slot="{ errors }">
              <v-autocomplete
                v-model="selectedFamily"
                label="Select Family"
                :items="families"
                item-text="label"
                item-value="familyId"
                :error-messages="errors"
              ></v-autocomplete>
            </validation-provider>
          </span>
          <span v-if="cloneType == 'narratives'">
            <v-data-table
              v-model="selectedNarratives"
              :headers="headers"
              :items="narratives"
              item-key="narrativeBlockId"
              show-select
              hide-default-header
              :items-per-page="-1"
              hide-default-footer
              class="ma-4"
              sort-by="sequence"
            >
              <template v-slot:[`item.text`]="{ item }">
                <span v-sanitize-html="item && item.text"></span>
              </template>
            </v-data-table>
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="clear"> Cancel </v-btn>
          <v-btn color="primary" text :disabled="invalid" @click="save">
            Clone
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      selectedTemplate: null,
      cloneType: "family",
      selectedFamily: null,
      selectedNarratives: [],
      headers: [{ text: "Narrative Text", value: "text" }],
    };
  },
  computed: {
    templateId: function () {
      return this.$store.getters["templatesModule/getSelectedTemplateId"];
    },
    templates: function () {
      return this.$store.state.templatesModule.templates;
    },
    families: function () {
      return this.templates
        .map((item) => {
          if (item.templateId === this.selectedTemplate) return item.families;
        })
        .flat();
    },
    narratives: function () {
      return this.templates
        .map((item) => {
          if (item.templateId === this.selectedTemplate)
            return item.narrativeBlocks;
        })
        .flat();
    },
  },
  methods: {
    save: async function () {
      this.dialog = false;
      this.$store.commit("uxModule/setShowLoader", true);

      let message = "";
      if (this.cloneType == "family") {
        message = await this.$store.dispatch(
          "templatesModule/cloneFamilyAndStubsToTemplate",
          {
            familyId: this.selectedFamily,
            templateId: this.templateId,
          }
        );
      } else if (this.cloneType == "narratives") {
        message = await this.$store.dispatch(
          "templatesModule/cloneNarrativesToTemplate",
          {
            narrativeIds: this.selectedNarratives
              .sort((a, b) => a.sequence - b.sequence)
              .map((item) => item.narrativeBlockId),
            templateId: this.templateId,
          }
        );
      }

      this.$store.commit("uxModule/setShowLoader", false);
      this.$store.commit("uxModule/setSnackbarMsg", message);
      this.$store.commit("uxModule/setShowSnackbar", true);
      this.clear();
    },
    clear: function () {
      this.$refs.form.reset();
      this.dialog = false;
      this.cloneType = "family";
      this.selectedTemplate = null;
      this.selectedFamily = null;
      this.selectedNarratives = [];
    },
  },
};
</script>
