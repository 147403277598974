<template>
  <validation-provider :rules="validations" v-slot="{ errors }">
    <label v-sanitize-html="field.label"></label>
    <v-sheet :class="{ column: field.column }">
      <v-chip-group
        :value="value"
        active-class="info--text"
        column
        multiple
        :disabled="readonly"
        @change="onChange"
        :error-messages="errors"
        class="mt-0"
      >
        <v-chip
          v-for="(item, index) in field.options"
          :key="index"
          :value="item"
          v-sanitize-html="item[field.itemText]"
          small
          class="mb-0"
        >
        </v-chip>
      </v-chip-group>
      <span class="error--text text-caption">{{ errors[0] }}</span>
    </v-sheet>
  </validation-provider>
</template>

<script>
export default {
  props: ["field", "value", "readonly", "validations"],
  data() {
    return {
      debounce: "",
    };
  },
  methods: {
    onChange: function (event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        this.$emit("fieldChange", event);
      }, 300);
    },
  },
};
</script>
<style>
.column .v-chip-group--column .v-slide-group__content {
  display: block;
}

.column .v-chip-group--column .v-chip {
  float: left;
  clear: both;
}
</style>
